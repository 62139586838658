import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Icon } from '@blueprintjs/core';
import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { changeSurveyDeploymentIdOnNeedsPage, showItemLevelInfoAndBookmarksDialog } from '../../../../../../../store/needs/actions';


import './BookmarksModal.scss';
import useDomainLevelBookmarkCounter from '../../../../../../../helpers/hooks/bookmarks/useDomainLevelBookmarkCounter';
import ItemLevelInfoAndBookmarksDialog from '../../../../../../team-plan/needs-and-goals/item-level-info-and-bookmarks-dialog/ItemLevelInfoAndBookmarksDialog';

const BookmarksModal = (props: any) => {
    const intl = useIntl();

    const dispatch = useDispatch()

    const { data } = props;

    useEffect(() => {
        dispatch(
            changeSurveyDeploymentIdOnNeedsPage(
            data?.domain_priority?.survey_deployment
            )
        );
        }, [data]);

    const { marksCountByDemographic } = useDomainLevelBookmarkCounter({
        domainId: data.domain_priority.domain.id,
    });

    const marksCount = useMemo(() => marksCountByDemographic[data.domain_priority?.demographic] ?? {}, [
        marksCountByDemographic,
        data.domain_priority?.demographic,
    ]);

    const SummaryofBookmarkClickOpen = () => {
        dispatch(
            showItemLevelInfoAndBookmarksDialog({
                domain: data.domain_priority.domain,
                demographic: data.domain_priority?.demographic,
                meanScore: data.default_score,
                marksCount: marksCount,
            })
        );
    };

    return (
        <>
            <a onClick={SummaryofBookmarkClickOpen}>
                {intl.formatMessage({ id: "app.title.item-level-information-and-bookmarks" })}
            </a>
        </>)
}

export default BookmarksModal;
