import React, { useEffect } from "react";
import SchoolSelector from "../../common/selectors/SchoolSelector";
import { useSelector } from "react-redux";
import SurveyDeploymentOpenTimePeriodTable from "../table/SurveyDeploymentOpenTimePeriodTable";
import { OpenTimePeriod, School, SurveyDeploymentPassword } from "../../../types";
import _ from "lodash";
import { useIntl } from "react-intl";
import useSchoolPlanDemographics from "../../../helpers/hooks/useSchoolPlanDemographics";

type OwnProps = {
  openTimePeriods: OpenTimePeriod[];
  setOpenTimePeriods: React.Dispatch<React.SetStateAction<OpenTimePeriod[]>>;
  isTeamPlanDialog?: boolean;
  districtIds?: number[];
  onDistrictChange: (selectedDistricts?: number[]) => void;
  existingOpenTimePeriods?: OpenTimePeriod[];

  schoolsIds: number[];
  setSchoolsIds: React.Dispatch<React.SetStateAction<number[]>>;
  schoolsDictionary: {
    [school: number]: School;
  };
  schoolsOptions: School[];
  creating: boolean;
  passwords: Omit<SurveyDeploymentPassword, "created_at" | "updated_at">[];
  setPasswords: React.Dispatch<
    React.SetStateAction<
      Omit<SurveyDeploymentPassword, "created_at" | "updated_at">[]
    >
  >;
};

type Props = OwnProps;

const SurveyDeploymentOpenTimePeriods: React.FC<Props> = (props: Props) => {
  const {
    openTimePeriods,
    setOpenTimePeriods,
    isTeamPlanDialog,
    districtIds,
    existingOpenTimePeriods,
    onDistrictChange,

    schoolsIds,
    setSchoolsIds,
    schoolsDictionary,
    schoolsOptions,
    creating,
    passwords,
    setPasswords
  } = props;

  const intl = useIntl();

  const handleSchoolChange = (schoolIds?: number[]) => {
    const newIds = schoolIds ?? [];
    setSchoolsIds(newIds);

    const schoolsDistrictsIds: number[] = newIds
      .map((id) => schoolsDictionary[id]?.district)
      .filter((id) => id != null) as number[];

    onDistrictChange(_.uniq([...(districtIds ?? []), ...schoolsDistrictsIds]));
  };

  const schoolsLoading = useSelector(
    (s) => s.schools.loading.getSchoolsByDistricts
  );

  const activePlanSchool = useSelector((s) => s.plans.activePlan?.school);
  const { demographics } = useSchoolPlanDemographics({
    grades: isTeamPlanDialog ? activePlanSchool?.grades : undefined,
  });

  useEffect(() => {
    if (isTeamPlanDialog && !creating) {
      setOpenTimePeriods(existingOpenTimePeriods ?? []);
    } else {
      const defaultValues: OpenTimePeriod[] = schoolsIds.flatMap((schoolId) =>
        demographics.map((demographic) => {
          const existing = existingOpenTimePeriods?.find(
            (oTp) => oTp.school === schoolId && oTp.demographic === demographic
          );

          return (
            existing ?? {
              id: +_.uniqueId() * -1, // local
              school: schoolId,
              demographic: demographic,
              start_date: undefined,
              end_date: undefined,
              created_at: undefined
            }
          );
        })
      );
      setOpenTimePeriods(defaultValues);
    }
  }, [existingOpenTimePeriods, schoolsIds, demographics, creating, isTeamPlanDialog, setOpenTimePeriods]);

  return (
    <div className="space-y-2 mb-2">
      {!isTeamPlanDialog && (
        <SchoolSelector<true>
          schoolId={schoolsIds}
          schools={schoolsOptions}
          onSchoolChange={handleSchoolChange}
          loading={schoolsLoading}
        />
      )}
      <div>
        {/* {intl.formatMessage({
          id: "app.survey-deployment.open-time-periods.info",
        },
          {
            b: (chunk) => <b>{chunk}</b>,
            br: <br />,
          }

        )} */}

      </div>

      <SurveyDeploymentOpenTimePeriodTable
        isTeamPlanDialog={isTeamPlanDialog}
        openTimePeriods={openTimePeriods}
        setOpenTimesPeriods={setOpenTimePeriods}
        schoolsDictionary={schoolsDictionary}
        creating={creating}
        passwords={passwords}
        setPasswords={setPasswords}
      />
    </div>
  );
};

export default SurveyDeploymentOpenTimePeriods;
