import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  OpenTimePeriod,
  School,
  SurveyDemographic,
  SurveyDeploymentPassword,
  YEARS_OFFSET,
} from "../../../types";
import MomentLocaleUtils from "react-day-picker/moment";
import moment from "moment";
import { DateRange, DateRangeInput } from "@blueprintjs/datetime";
import { CellProps } from "react-table";
import EntityTable from "../../common/EntityTable";
import { Checkbox, EditableText, NonIdealState } from "@blueprintjs/core";
import _ from "lodash";
import CreateNjsciSurveyModal from "../../team-plan/data/CreateNjsciSurveyModal";
import { TableCell, TextField } from "@material-ui/core";

type OwnProps = {
  isTeamPlanDialog?: boolean;
  openTimePeriods: OpenTimePeriod[];
  setOpenTimesPeriods: React.Dispatch<React.SetStateAction<OpenTimePeriod[]>>;
  schoolsDictionary?: {
    [school: number]: School;
  };
  creating: boolean;
  passwords: Omit<SurveyDeploymentPassword, "created_at" | "updated_at">[];
  setPasswords: React.Dispatch<
    React.SetStateAction<
      Omit<SurveyDeploymentPassword, "created_at" | "updated_at">[]
    >
  >;
};

type Props = OwnProps;

const MAX_SURVEY_DURATION_MONTHS = 3;
// const MIN_DATE_DEFAULT = moment().subtract(YEARS_OFFSET, "years").toDate();
// const MAX_DATE_DEFAULT = moment().add(YEARS_OFFSET, "years").toDate();

const getCurrentSession = () => {
  const SURVEY_MONTH: number = 6;
  const date = moment(new Date());

  if (parseInt(moment(date).format("M")) > SURVEY_MONTH) {
    return `${moment(date).format("YYYY")}-${moment(date)
      .add(1, "years")
      .format("YYYY")}`;
  } else {
    return `${moment(date).subtract(1, "year").format("YYYY")}-${moment(
      date
    ).format("YYYY")}`;
  }
};

const SESSION_YEAR = getCurrentSession().split('-');
const MIN_YEAR = SESSION_YEAR[0];
const MAX_YEAR = SESSION_YEAR[1];

const MIN_DATE_DEFAULT = moment(`${MIN_YEAR}-07-01`).toDate();
const MAX_DATE_DEFAULT = moment(`${MAX_YEAR}-06-30`).toDate();

const SurveyDeploymentOpenTimePeriodTable: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const [isPasswordEditing,setIsPasswordEditing]=useState<any>("");
  const {
    openTimePeriods,
    setOpenTimesPeriods,
    schoolsDictionary,
    isTeamPlanDialog,
    creating,
    passwords,
    setPasswords
  } = props;

  const handleOpenTimePeriodChange = (
    id: number,
    demographic?: SurveyDemographic,
    startDate?: string,
    endDate?: string
  ) => {
    setOpenTimesPeriods((items) =>
      items.map((item) =>
        item.id === id
          ? { ...item, start_date: startDate, end_date: endDate }
          : item
      )
    );
  };

  const handleDateRangeChange = (
    id: number,
    date: DateRange,
    demographic?: SurveyDemographic
  ) => {
    const startDate = date[0]
      ? moment(date[0]).format("YYYY-MM-DD")
      : undefined;
    const endDate = date[1] ? moment(date[1]).format("YYYY-MM-DD") : undefined;
    handleOpenTimePeriodChange(id, demographic, startDate, endDate);
  };
  const handleConfirmSurveyPasswordChange = (surveyPasswordId: number) => (
    value: string
  ) => {
    setPasswords((passwords) =>
      passwords.map((p) =>
        p.id === surveyPasswordId ? { ...p, password: value } : p
      )
    );
  };
  const columns = useMemo(() => {
    const columns = [
      {
        Header: intl.formatMessage({
          id: "app.titles.school",
        }),
        accessor: "school",
        Cell: ({ row: { original } }: CellProps<OpenTimePeriod>) => {
          return (
            (schoolsDictionary && schoolsDictionary[original.school]?.name) ??
            original.school
          );
        },
        hidden: isTeamPlanDialog,
      },
      {
        Header: intl.formatMessage({
          id: "app.titles.stakeholder-group",
        }),
        accessor: "demographic",
        Cell: ({ row: { original } }: CellProps<OpenTimePeriod>) => {
          return intl.formatMessage({
            id: `app.surveys.survey-demographic.${original.demographic}`,
          });
        },
      },
      {
        Header: intl.formatMessage({
          id: "app.survey-deployment.open-time-period-table.open-time-period",
        }),
        accessor:"time-period",
        width: "100%",
        Cell: ({ row: { original } }: CellProps<OpenTimePeriod>) => {
          let minDate = original.end_date
            ? moment(original.end_date)
              .subtract(MAX_SURVEY_DURATION_MONTHS, "months")
              .toDate()
            : MIN_DATE_DEFAULT;

          minDate = minDate < MIN_DATE_DEFAULT ? MIN_DATE_DEFAULT : minDate;

          let maxDate = original.start_date
            ? moment(original.start_date)
              .add(MAX_SURVEY_DURATION_MONTHS, "months")
              .toDate()
            : MAX_DATE_DEFAULT;

          maxDate = maxDate > MAX_DATE_DEFAULT ? MAX_DATE_DEFAULT : maxDate;

          return (
            <DateRangeInput
              disabled={original.disabled}
              formatDate={(date) => date.toLocaleDateString()}
              locale={intl.locale}
              localeUtils={MomentLocaleUtils}
              allowSingleDayRange={true}
              minDate={minDate}
              maxDate={maxDate}
              shortcuts={false}
              onChange={(range: DateRange) =>
                handleDateRangeChange(original.id!, range, original.demographic)
              }
              parseDate={(str) => new Date(str)}
              startInputProps={{
                large: true,
                placeholder: intl.formatMessage({
                  id: "app.titles.from-date",
                }),
                fill: true,
                className:"custom-start-date-input"
              }}
              endInputProps={{
                large: true,
                placeholder: intl.formatMessage({
                  id: "app.titles.to-date",
                }),
                fill: true,
                className:"custom-end-date-input"
              }}
              className="date-range-input-full"
              value={
                original.start_date || original.end_date
                  ? [
                    original.start_date
                      ? moment(original.start_date).toDate()
                      : null,
                    original.end_date
                      ? moment(original.end_date).toDate()
                      : null,
                  ]
                  : undefined
              }
              popoverProps={{ boundary: "viewport" }}
            />
          );
        },
      },
      {
        Header:intl.formatMessage({id:"app.titles.password"})+"s",
        accessor:"password",
        Cell:({ row: { original } }: CellProps<OpenTimePeriod>) => {
          return  passwords.map((pass,ind) => 
          
  
              {return original.demographic === pass.demographic && 
                  <TextField
                  variant="outlined"
                  // className="w-48 h-12 ml-2 text-lg"
                  defaultValue={pass?.password}
                  disabled
                  // onChange={()=>handleConfirmSurveyPasswordChange(pass.id)}
                  // maxLength={25}
                  
                />
           
             
                 }
            
        )
        }
      }
    ];

    return columns.filter((c) => !c.hidden);
  }, [intl, isTeamPlanDialog, schoolsDictionary, handleDateRangeChange]);
  

  return (
    <>
    <CreateNjsciSurveyModal columns={columns} data={openTimePeriods} />
    
    </>
  
  );
};

export default SurveyDeploymentOpenTimePeriodTable;
