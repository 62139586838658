import React, { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { useIntl } from "react-intl";
import { Button, Icon, Spinner } from "@blueprintjs/core";
import moment from "moment";
import { PlanStrategy, SurveyDemographic } from "../../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyDeploymentsBySchool } from "../../../../../store/surveys-deployment/actions";
import SurveyRespondentGroupGraph from "./table-view/SurveyRespondentGroupGraph";
import StrategyRespondentGroupGraph from "./table-view/StrategyRespondentGroupGraph";
import {
  clearRespondentGraphDomainsItemsArray,
  getRespondentGraphDomainData,
  getRespondentGraphItemData,
  getSurveyComparison,
} from "../../../../../store/survey-reports/actions";
import {
  LocalStorageKeys,
  getBooleanFromLocalStorage,
} from "../../../../../constants";
import _, { isEmpty } from "lodash";
import "./RespondentGroupGraph.scss";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";


//
type OwnProps = {
  selectedRespondentGroup?: string;
  selectedGoal?: any;
  selectedDateRange?: any;
  selectedMultiDomains?: any;
  selectedAlignedStrategy?: any;
  selectedMultiSurveyItem?: any;
  saveFilter?: any;
  alignStrategies?: PlanStrategy[];
};
type Props = OwnProps;

const STRATEGIES_THRESHOLD: number=30;

const RespondentGroupGraph: React.FC<Props> = (props) => {
  const {
    selectedRespondentGroup,
    selectedGoal,
    selectedDateRange,
    selectedMultiDomains,
    selectedAlignedStrategy,
    selectedMultiSurveyItem,
    saveFilter,
    alignStrategies,
  } = props;

  const dispatch = useDispatch();

  ///// UseSelectors///////
  const plan = useSelector((s) => s.plans.activePlan);
  const { goals } = useSelector((s) => s.goals);
  const RespondentGraphDomainsData = _.flatMapDeep(useSelector(
    (s) => s.surveyReports.surveyRespondentDomainsData
  ));
  const graphItemsData = useSelector(
   (s) => s.surveyReports.surveyRespondentItemsData
 );
  const RespondentGraphItemsData = graphItemsData[0];
  const RespondentGraphDomainsDataLoading = useSelector(
    (s) => s.surveyReports.loading.getRespondentGraphDomainData
  );
  const RespondentGraphItemsDataLoading = useSelector(
    (s) => s.surveyReports.loading.getRespondentGraphItemData
  );
  const surveyDeployments = useSelector(
    (s) => s.surveysDeployment.surveysDeploymentBySchool
  );
  ///////////


  const selectedDomainIdsFiltered = selectedGoal.selected_domains.map(
    (sd: any) => sd.id
  ); // selected domains ids
  const filteredSurveyDeployments= surveyDeployments.filter((surveyDeployment:any)=>surveyDeployment.is_deleted===false && surveyDeployment.state==="ready");
  
  const surveyDeploymentIds = filteredSurveyDeployments.map((s: any) => s.id); // survey deployment ids

  const intl = useIntl();
  ////STATES
  const [dataSetLegend, setDataSetLegend] = useState([]);
  const [ImplementationDataSetLegend, setImplementationDataSetLegend] = useState([]);
  const [isChartSurvey, setIsChartSurvey] = useState<boolean>(true);
  const [isChartStratery, setIsChartStrategy] = useState<boolean>(true);
  const [SurveyIdIndex, setSurveyIdIndex] = useState<number>(0);
  const [ItemsIndex, setItemsIndex] = useState<number>(0);
  const [idsArray, setIdsArray] = useState<any>([]);
  const [HexColorArray,setHexColorArray]=useState<any>([]);
  const [hoverLabel,setHoverLabel]=useState<string|null>(null);

  const [refresh,setRefresh]=useState({surveyChart:true,strategyChart:true});

  const [zoomSurveyChartLevel,setZoomSurveyChartLevel]=useState<any>(0);
  const [zoomStrategyChartLevel,setZoomStrategyChartLevel]=useState<any>(0);

  const [strategyDownloadLoader,setStrategyDownloadLoader]=useState<boolean>(false);
  const [njsciDownloadLoader,setNjsciDownloadLoader]=useState<boolean>(false);

  const [refreshGraph,setRefreshGraph]=useState<boolean>(false);


  useEffect(()=>{
    if(RespondentGraphDomainsDataLoading === false && RespondentGraphItemsDataLoading === false){
      setRefreshGraph(!refreshGraph)
    }
  },[RespondentGraphDomainsDataLoading,RespondentGraphItemsDataLoading])

  useEffect(() => {
    if (SurveyIdIndex === 0) {
      dispatch(clearRespondentGraphDomainsItemsArray([]));
    }
      dispatch(
        getRespondentGraphDomainData.request({
          survey_deployment_id: surveyDeploymentIds,
          school_id: [selectedGoal?.plans?.school?.id],
          // hide_sensitive_reporting_data:localFlagToHideSensitiveData
        })
      );
    }, [saveFilter]);

  useEffect(() => {
    idsArray.length = 0;
    surveyDeploymentIds.forEach((value: any, index: number) => {
      selectedDomainIdsFiltered.forEach((domain_id: any, ind: number) => {
        idsArray.push({ surveyDeploymentID: value, domain: domain_id });
      });
    });
  }, [saveFilter,selectedDomainIdsFiltered]);

  useEffect(() => {
      dispatch(
        getRespondentGraphItemData.request({
          survey_deployment_id: idsArray,
          school_id: [selectedGoal?.plans?.school?.id],
          // domain_id: [idsArray[ItemsIndex]?.domain_id],
        })
      );
    }, [saveFilter]);


 useEffect(()=>{
  const minBrightness = 100; 

  function getBrightness(hex:any) {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  }

  function getRandomHexColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";

    do {
      color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
    } while ((getBrightness(color)) > minBrightness);
  
    return color;
  }
  

    for (let i = 0; i<=STRATEGIES_THRESHOLD;i++){
      const newColor = getRandomHexColor();
      let isDistinct = true;

      // Check the brightness difference with existing colors
      for (const existingColor of HexColorArray) {
        const newColorBirghtness = getBrightness(newColor);
        const existingColorBrightness =  getBrightness(existingColor)


          const brightnessDifference = Math.abs( newColorBirghtness - existingColorBrightness);
          if (brightnessDifference < 40) {
              isDistinct = false;
              break;
          }
      }

      // Add the color to the array if it's distinct
      if (isDistinct) {
        setHexColorArray((prevState:any)=> [...prevState,getRandomHexColor()])
      }
    }
  },[surveyDeployments])

const dates = selectedDateRange;

const isDateExist = useMemo(() => {
    return selectedDateRange.find(
      (sdr: any) =>
        sdr ===
        moment(selectedGoal?.survey_deployment?.end_date).format("MM/DD/YY")
    )
      ? true
      : false;
  }, [selectedDateRange]);
  
  const data: any = useMemo(() => {
  const DataSet: any = []; //graph dataset Array
  const targetScore: any = [];
  let target_date: string = "";

  if (
    (selectedMultiDomains.length === 1 ||
      selectedMultiSurveyItem.length === 1) &&
    isDateExist
  ) {


    if (selectedMultiDomains && selectedMultiSurveyItem.length === 0) {
      const endD =   moment(selectedGoal?.survey_deployment?.end_date).format("MM/DD/YY");
      const endDate =new Date(endD);

                 /////////////////////////////////
                 selectedGoal.targets.forEach((target: any) => {
                  if( target?.domain_priority?.demographic ===
                    selectedMultiDomains[0].demographic &&
                  target?.domain_priority?.domain?.name === selectedMultiDomains[0].domain ) {
                    const AchieveD = moment(target.target_date).format("MM/DD/YY");
                    const AchieveDate = new Date(AchieveD);
                    selectedDateRange.forEach(
                      (date: any, index: number) =>
                        {
                         /////////////////////////////////
                          const endDateIndex = selectedDateRange.findIndex((sdr: string) =>sdr === endD)

                 // check for end-date present in selected Dates Array or not and less or greater than Achieve Date 
                const endDateIndexCondition = endDateIndex >= 0 && (endDate > AchieveDate || endDate < AchieveDate)   ? endDateIndex : (endDateIndex < 0 && endDate < AchieveDate?0:(endDateIndex < 0 && endDate > AchieveDate) && selectedDateRange.length  )   ; 
                
                 ///////////////////////////////
                const AchieveDateIndex = (selectedDateRange.findIndex((sdr:string)=>sdr === AchieveD))

                // check for AchieveDate present in selected Dates Array or not and less or greater than End Date 
                const AchieveByIndexCondition = AchieveDateIndex >= 0 && (endDate > AchieveDate || endDate < AchieveDate)   ? AchieveDateIndex : (AchieveDateIndex < 0 &&  AchieveDate < endDate?0:(AchieveDateIndex < 0 &&  AchieveDate > endDate) && selectedDateRange.length  )  ;

              // for target Score Line visible till AchieveBy Date if Achieved by date is greater than end Date
              (AchieveByIndexCondition >= endDateIndexCondition ) ? 
              targetScore.push(( index >= endDateIndexCondition && index <= AchieveByIndexCondition )? target.target_value : null) 

              :

               ( AchieveByIndexCondition < endDateIndexCondition) && targetScore.push((  index >= AchieveByIndexCondition && index <= endDateIndexCondition )? target.target_value : null)
                
                
                  
               target_date = AchieveD;
              }
          )
        }
      });
    }

    if (
      selectedMultiSurveyItem &&
      selectedMultiDomains.length === 0 &&
      isDateExist
    ) {
      const endD     =  moment(selectedGoal?.survey_deployment?.end_date).format("MM/DD/YY");
      const endDate  = new Date(endD);
      selectedGoal.targets.map((target: any) => {
        if( target?.item_priority?.demographic ===
          selectedMultiSurveyItem[0].demographic &&
        target?.item_priority?.item?.text.includes(
          selectedMultiSurveyItem[0].text
        ) ) {
          const AchieveD = moment(target.target_date).format("MM/DD/YY");
          const AchieveDate= new Date(AchieveD)
          selectedDateRange?.map(
            (date: any, index: number) =>
              {
                 
                 /////////////////////////////////
                 const endDateIndex = selectedDateRange?.findIndex((sdr: string) =>sdr === endD)
                  
                  // check for end-date present in selected Dates Array or not and less or greater than Achieve Date 
                  const endDateIndexCondition = endDateIndex >= 0 && (endDate > AchieveDate || endDate < AchieveDate)   ? endDateIndex : (endDateIndex < 0 && endDate < AchieveDate?0:(endDateIndex < 0 && endDate > AchieveDate) && selectedDateRange.length  )   ; 
                  
                  
                  ///////////////////////////////
                  const AchieveDateIndex = (selectedDateRange?.findIndex((sdr:string)=>sdr === AchieveD))
                
                  // check for AchieveDate present in selected Dates Array or not and less or greater than End Date 
                  const AchieveByIndexCondition = AchieveDateIndex >= 0 && (endDate > AchieveDate || endDate < AchieveDate)   ? AchieveDateIndex : (AchieveDateIndex < 0 &&  AchieveDate < endDate?0:(AchieveDateIndex < 0 &&  AchieveDate > endDate) && selectedDateRange.length  )  ;
             
                // for target Score Line visible till AchieveBy Date if Achieved by date is greater than end Date
                (AchieveByIndexCondition >= endDateIndexCondition ) ? 
                targetScore.push(( index >= endDateIndexCondition && index <= AchieveByIndexCondition )? target.target_value : null) 
              
                :
          
                 ( AchieveByIndexCondition < endDateIndexCondition) && targetScore.push((  index >= AchieveByIndexCondition && index <= endDateIndexCondition )? target.target_value : null)
                
                
                  
             
                target_date = AchieveD;
                

              }
          )
        }
      });
    }

    DataSet.push({
      label: target_date,
      pointBorderWidth: 1,
      pointRadius: 0.2,
      pointHoverRadius: 1,
      data: targetScore,
      fill: false,
      tension: 0.1,
      hoverBorderColor: "orange",
      backgroundColor: "orange",
      pointStyle: "circle",
      borderDash: [3, 3],
      borderWidth:3,
      borderColor: "orange",
    });
  }

  if (selectedMultiDomains) {
    const dataPointsDomain: any = [];
    selectedMultiDomains.forEach((selectedDomain:any, i:number) => {
      // Create a new empty array in each iteration
      var newArray: any = [];

      // Push the new empty array into the array that stores them
      dataPointsDomain.push(newArray);

      const domainName = selectedDomain.domain;
      const demographic = selectedDomain.demographic;
      const bgColor = RespondentGraphDomainsData.find(
        (fsc: any) => fsc?.domain?.name === selectedMultiDomains[i].domain
      )?.domain?.color_hex;

      // data points for domain graph
      RespondentGraphDomainsData?.forEach((fsc: any) => {
        const fscDate = moment(fsc?.survey_deployment[0]?.end_date).format("MM/DD/YY");
  
        if (
          fsc?.domain?.name === domainName &&
          fsc?.respondents[demographic] &&
          selectedDateRange.includes(fscDate)
        ) {
          const index = selectedDateRange.findIndex((item: any) => item === fscDate);
  
          if (!dataPointsDomain[i]) {
            dataPointsDomain[i] = Array(selectedDateRange?.length).fill(null);
          }
  
          dataPointsDomain[i][index] = fsc?.respondents[demographic]?.mean_score?.toFixed(2);
        } else {
          if (!dataPointsDomain[i]) {
            dataPointsDomain[i] = Array(selectedDateRange?.length).fill(null);
          }
  
          dataPointsDomain[i].push(null);
        }
      });

      const getPointStyle = (demographic: string) => {
        return demographic === SurveyDemographic.SchoolStaff
          ? "triangle"
          : demographic === SurveyDemographic.Parents
          ? "rectRot"
          : demographic === SurveyDemographic.Students
          ? "rect"
          : "circle";
      };
      // pushing Domain Data in the Graph DataSet Array
      DataSet.push({
        label: `${selectedMultiDomains[i].domain} (${intl.formatMessage({
          id: `app.filters.respondent-type.${selectedMultiDomains[i].demographic}`,
        })})`,
        pointBorderWidth: 0,
        pointRadius: 6,
        pointHoverRadius: 6,
        pointHitRadius: 6,
        data: dataPointsDomain[i],
        fill: false,
        tension: 0.1,
        hoverBorderColor: bgColor,
        backgroundColor: bgColor,
        pointStyle: getPointStyle(selectedMultiDomains[i].demographic),
        borderDash: [0, 0],
        borderWidth:2,
        borderColor: bgColor,
        pivot: "middle",
        pivotColor: "#20275A",
      });
    });
  }

  if (selectedMultiSurveyItem) {
    const dataPointsItem: any = [];
    selectedMultiSurveyItem.forEach((surveyItem:any, i:number) => {
      const newArray: any = [];

      // Push the new empty array into the array that stores them
      dataPointsItem.push(newArray);

      const bgColor = RespondentGraphItemsData?.find((fsc: any) =>
        fsc?.responses?.find((response: any) =>
          response?.respondents?.[`${surveyItem.demographic}`]?.question_text?.includes(surveyItem.text)
        )
      )?.domain?.color_hex;
      RespondentGraphItemsData?.forEach((fsc: any) => {
          fsc?.responses?.forEach((response: any) => {
            const responseDate = moment(response?.survey_deployment[0]?.end_date).format("MM/DD/YY");
            if (
              response.respondents[`${surveyItem.demographic}`] &&
              response.respondents[`${surveyItem.demographic}`]?.question_text?.includes(surveyItem.text) &&
              selectedDateRange.includes(responseDate)
            ) {
              const index = selectedDateRange.findIndex((item: any) => item === responseDate);
    
              if (!dataPointsItem[i]) {
                dataPointsItem[i] = Array(selectedDateRange?.length).fill(null);
              }
    
              dataPointsItem[i][index]= response?.respondents[`${surveyItem.demographic}`]?.mean_score?.toFixed(2);
            } else {
              if (!dataPointsItem[i]) {
                dataPointsItem[i] = Array(selectedDateRange?.length).fill(null);
              }
    
              dataPointsItem[i].push(null);
            }
      });
    });

    const getPointStyle = (demographic: string) => {
      return demographic === SurveyDemographic.SchoolStaff
        ? "triangle"
        : demographic === SurveyDemographic.Parents
        ? "rectRot"
        : demographic === SurveyDemographic.Students
        ? "rect"
        : "circle";
    };
    DataSet.push({
      label: `${surveyItem.leadIn ?? ""} ${surveyItem.text} (${surveyItem.domain}) (${intl.formatMessage({
        id: `app.filters.respondent-type.${surveyItem.demographic}`,
      })})`,
      pointBorderWidth: 0,
      pointRadius: 6,
      pointHoverRadius: 6,
      pointHitRadius: 6,
      data: dataPointsItem[i],
      fill: false,
      tension: 0.1,
      hoverBorderColor: bgColor,
      backgroundColor: bgColor,
      pointStyle: getPointStyle(surveyItem.demographic),
      borderDash: [5, 5],
      borderWidth: 2,
      borderColor: bgColor,
      pivot: "middle",
      pivotColor: "#20275A",
    });
  })
  }
  setDataSetLegend(DataSet);
  return {
    labels: dates, // dates
    datasets: DataSet,
  };
}, [
  saveFilter,
  refreshGraph,
  zoomSurveyChartLevel,
]);

const XAxisOptions:any  = useMemo(
  () => ({
    maintainAspectRatio: false,

    legend: { display: false, position: "right" },
    spanGaps: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: "black",
            maxRotation: 70,
            minRotation: 70,
            maxTicksLimit:selectedDateRange.length,
            callback: (value: any, index: number) =>{                  
            return value.includes("07/01") || value.includes("10/01") || value.includes("01/01")|| value.includes("04/01") ? moment(value).format("MMM YYYY")+"    "      : ""
            }
          },
          gridLines: {
            zeroLineColor: "white",
            color: selectedDateRange.map((date:string,index:number,arr:string[])=> (index === 0 ||date.includes("07/01") )?"black": null),
            drawOnChartArea: false,
          },
        },
      ],

      yAxes: [
        {
          id: "score",
          type: "linear",
          ticks: {
            min: 1,
            max: 1,
            stepSize:1,
            beginAtZero: true,
            fontColor: "white",
            callback: (value: any) => ([1,2,3,4,1.5,2.5,3.5].includes(value) ? value : ""),
          },
          gridLines: {
            zeroLineColor: "white",
            color:"white",
            // (Array.from({ length: 40 }, (_, index) => index )).map((item:number)=>item===30?"white": item===0 ||item===10 || item=== 20 ?'rgba(0, 0, 0, 0.1)':"white"),// for custom color to each grid line 
            drawTicks: { font: { color: "white" } },
          },
        },
      ],
    },
  }),
  [
    saveFilter,
    refreshGraph,
  ]
);

  const AdminLabelPoints: any = {};
  if (selectedMultiDomains.length) {
    for (var i = 0; i < selectedMultiDomains.length; i++) {
      selectedDateRange.map((sdr: any) => {
        if(RespondentGraphItemsData!==undefined){
        RespondentGraphDomainsData.map((fsc: any) =>
          fsc?.domain?.name === selectedMultiDomains[i].domain &&
          sdr === moment(fsc?.survey_deployment[0]?.end_date).format("MM/DD/YY")
            ? (AdminLabelPoints[
                moment(fsc?.survey_deployment[0]?.end_date).format("MM/DD/YY")
              ] = fsc?.survey_deployment[0]?.deployment_name)
            : ""
        )
      }
    }
      );
    }
  } else {
    for (var i = 0; i < selectedMultiSurveyItem?.length; i++) {
      selectedDateRange?.map((sdr: any) =>
        RespondentGraphItemsData?.map((fsc: any) =>
          fsc?.responses?.forEach((response: any) => {
            if (
              response?.respondents[
                `${selectedMultiSurveyItem[i].demographic}`
              ]?.question_text?.includes(selectedMultiSurveyItem[i].text) &&
              sdr ===
                moment(response?.survey_deployment[0]?.end_date).format(
                  "MM/DD/YY"
                )
            ) {
              AdminLabelPoints[
                moment(response?.survey_deployment[0]?.end_date).format(
                  "MM/DD/YY"
                )
              ] = response?.survey_deployment[0]?.deployment_name;
            }
          })
        )
      );
    }
  }

  
  const options: any = useMemo(
    () => ({
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function (tooltipItem: any, data: any) {
           
            let label =
              "  " + data.datasets[tooltipItem.datasetIndex].label || "";
            const stringArray = label.split('_ ');
            var joinedString = stringArray.length > 1 ? stringArray[1] : stringArray[0];
            tooltipItem && setHoverLabel( data.datasets[tooltipItem.datasetIndex].label);          
            const lableArray = data?.datasets?.map((ds:any)=>ds.label) // new lables Array from data


          const  renderLables = [
              data.datasets[tooltipItem.datasetIndex].borderDash.includes(0)
                ? `   ${intl.formatMessage({
                    id: "app.titles.domain-mean"
                  })}: ${
                    tooltipItem.yLabel?.toString()?.split(".")[1]?.length === 1
                      ? tooltipItem.yLabel + "0"
                      : tooltipItem.yLabel?.toString()?.split(".")[1]
                          ?.length === undefined
                      ? tooltipItem.yLabel + ".00"
                      : tooltipItem.yLabel
                  }`
                : data.datasets[tooltipItem.datasetIndex].borderDash.includes(3)
                ? `${intl.formatMessage({
                    id: "app.goals.target-indicator.achieved-by",
                  })} ` 
                  + data.datasets[tooltipItem.datasetIndex].label
                : [
                    `   ${intl.formatMessage({
                      id: "app.survey-report.dialogs.item"
                    })} ${intl.formatMessage({
                      id: "app.survey-report.dialogs.mean"
                    })}: ${
                      tooltipItem.yLabel?.toString()?.split(".")[1]?.length ===
                      1
                        ? tooltipItem.yLabel + "0"
                        : tooltipItem.yLabel?.toString()?.split(".")[1]
                            ?.length === undefined
                        ? tooltipItem.yLabel + ".00"
                        : tooltipItem.yLabel
                    }`
                  ]
            ]

            return data.datasets[
              tooltipItem.datasetIndex
            ].borderDash.includes(3)
              ? `${intl.formatMessage({
                  id: "app.goals.target-indicator.target-score",
                })}: ` + tooltipItem.yLabel.toFixed(2)
              :" "+renderLables;
        },
        title: function (tooltipItem: any, data: any) {

          // Return an array of title lines
          const showAdminLabel = AdminLabelPoints[tooltipItem[0].label];

          if (showAdminLabel && AdminLabelPoints[tooltipItem[0].label].includes("a"|| "e" || "i" || "o" || "u") ) {
              return [
              "",AdminLabelPoints[tooltipItem[0].label]?.slice(0, 10) +
                AdminLabelPoints[tooltipItem[0].label]?.slice(10, 16) +
                " " +AdminLabelPoints[tooltipItem[0].label]?.slice(26),""
            ]
          }
          },
        },
       
      },

      onHover: function(event:any, elements:any) {        
        if (elements.length > 0) {

        } else {
         setHoverLabel(null)

        }
      },

      legend: { display: false, position: "right" },
      spanGaps: true,
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: "white",
              maxRotation: 70,
              minRotation: 70,
              maxTicksLimit:
                zoomSurveyChartLevel === 0
                  ? selectedDateRange.length
                  : (selectedDateRange.length / zoomSurveyChartLevel) * 40,
                  callback: (value: any, index: number) =>{                  
                    return value.includes("07/01") || value.includes("10/01") || value.includes("01/01")|| value.includes("04/01") ?"    "      : ""
                    }
            },
            gridLines: {
              zeroLineColor: "white",
              color: selectedDateRange.map((date:string,index:number,arr:string[])=> (index === 0 ||date.includes("07/01") )?"white": null),
              drawOnChartArea: false,
            },
          },
        ],

        yAxes: [
          {
            id: "score",
            type: "linear",
            ticks: {
              min: 1,
              max: 4,
              stepSize:0.1,
              beginAtZero: true,
              fontColor: "black",
              callback: (value: any) => (zoomSurveyChartLevel>0.2 && [1,2,3,4,1.5,2.5,3.5].includes(value) ? value: zoomSurveyChartLevel <=0.2 && [1,2,3,4].includes(value)  ? value : ""),
            },
            gridLines: {
              zeroLineColor: "black",
              color:(Array.from({ length: 40 }, (_, index) => index )).map((item:number)=>item===30?"black": item===0 ||item===10 || item=== 20 ?'rgba(0, 0, 0, 0.1)':"white"),// for custom color to each grid line 
              drawTicks: { font: { color: "white" } },
            },
          },
        ],
      },
    }),
    [
      saveFilter,
      refreshGraph,
      zoomSurveyChartLevel
    ]
  );

  const data2: any = useMemo(() => {
    const DataSet: any = [];
    const StrategyDataPoints = (sas: any) => {
      const alignedStrategyImplementationData: any = [];

      selectedDateRange?.map((sdr: any) =>    
      alignStrategies?.map((item:any) =>
        item.strategy.name === sas
          && item.implementations.map((impl: any) =>
                sdr === moment(impl.date).format("MM/DD/YY")
                  ? alignedStrategyImplementationData.splice(
                      selectedDateRange.findIndex(
                        (dateRange: any) =>
                          dateRange ===
                          moment(impl.date).format("MM/DD/YY")
                      ),
                      0,
                      Math.round((impl.score || 0) * 100)
                    )
                  : alignedStrategyImplementationData.push(null)
              )
            )
      );

      return alignedStrategyImplementationData;
    };


    if (selectedAlignedStrategy) {
      
      selectedAlignedStrategy?.map((sas: string,index:number) =>

        DataSet.push({
          label: sas,
          data: StrategyDataPoints(sas),
          fill: false,
          tension: 0.1,
          pointBorderWidth: 1,
          pointRadius: 5,
          pointHoverRadius: 10,
          backgroundColor: HexColorArray[index],
          borderColor: HexColorArray[index],
        })
      );
    }

    setImplementationDataSetLegend(DataSet)
    return {
      labels: dates,
      datasets: DataSet,
    };
  }, [saveFilter, alignStrategies,refreshGraph, selectedAlignedStrategy,HexColorArray]);
  const options2: any = useMemo(
    () => ({
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function (tooltipItem:any, data:any) {
     let label = "  "+data.datasets[tooltipItem.datasetIndex].label || '';
     
     return [label];
   },
   title: function (tooltipItem:any,data:any) {
     // Return an array of title lines
     return [""];
   },
   afterLabel: function (tooltipItem:any,data:any) {
     // Additional labels if needed
     return ["  "+tooltipItem.label,"  Score: " + tooltipItem.yLabel +"%"];
   
 },
       },
      },

      legend: {
        display: false,
        position: "right",
        labels: {
          fontColor: "black", // Change the color of the legend labels
          usePointStyle: true, // Use custom point style
          pointRadius: 5,
          boxWidth: 7,
          pointStyle: "circle",
        },
      },
      spanGaps: true,
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: "white",
              maxRotation: 70,
              minRotation: 70,
              maxTicksLimit:selectedDateRange?.length,
              callback: (value: any, index: number) =>{ 
                return value.includes("07/01") || value.includes("10/01") || value.includes("01/01")|| value.includes("04/01") ? moment(value).format("MMM YYYY")+"    "      : ""
  
                }
            },
            gridLines: {
              zeroLineColor: "white",
              color: selectedDateRange.map((date:string,index:number,arr:string[])=> (index === 0 ||date.includes("07/01") )?"white": null),
              drawOnChartArea: false,  
            },
          },
        ],
        yAxes: [
          {
            id: "score",
            type: "linear",
            ticks: {
              min: 0,
              max: 100,
              stepSize: 20,
              beginAtZero: true,
              fontColor: "black",
              callback: (value:any) => (value !== 0 ? `${value}%` : '')
            },
            gridLines: {
              zeroLineColor: "white",
            },
          },
        ],
      },
    }),
    [saveFilter,refreshGraph]
  );


  const isLoading=useMemo(()=>{
    if(ItemsIndex < idsArray.length && SurveyIdIndex < surveyDeploymentIds.length){
   
   return  true
   
   }else{
     return false;
   }
   },[ItemsIndex,SurveyIdIndex])


useEffect(()=>{

  setRefresh({...refresh,surveyChart:false})


setTimeout(() => {
  setRefresh({...refresh,surveyChart:true})

}, 600);

},[saveFilter])


useEffect(()=>{

  setRefresh({...refresh,strategyChart:false})


setTimeout(() => {
  setRefresh({...refresh,strategyChart:true})

}, 600);

},[saveFilter])


const downloadNjsciGraphPdf =()=>{
  const pdf = new jsPDF('l', 'pt', "a4");
  const currentZoom = zoomSurveyChartLevel;

const njsciGraph:any= document.getElementById("njsciGraph");

if (njsciGraph) {
  const originalWidth = njsciGraph.style.width;
  const originalHeight = njsciGraph.style.height;
  njsciGraph.style.width = '1300px'; 
  njsciGraph.style.height = '794px'; 

  html2canvas(njsciGraph, { scale: 3 }).then((canvas) => {
    // Restore the original size
    njsciGraph.style.width = originalWidth;
    njsciGraph.style.height = originalHeight;
      const base64image = canvas.toDataURL('image/png');
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = pdf.internal.pageSize.getHeight()-50;

      // Add the image to the PDF
      pdf.text('NJ SCI Survey Data', pdf.internal.pageSize.getWidth() / 2, 20, { align: 'center' });
      pdf.addImage(base64image, 'PNG', 10, 100, imgWidth, imgHeight);

      // Save the PDF
      pdf.save('NJ SCI Survey Data Chart.pdf');

      setNjsciDownloadLoader(false)
      setZoomSurveyChartLevel(currentZoom)
      
  }).catch(error => {
      setNjsciDownloadLoader(false)
      console.error('Error capturing element:', error);
  });
}

}


const downloadStrategyChart=()=>{
  const pdf = new jsPDF('l', 'pt', "a4");
  const currentZoom = zoomStrategyChartLevel;
  const strategyGraph:any= document.getElementById("strategyImplementationChart");

  if (strategyGraph) {
    const originalWidth = strategyGraph.style.width;
    const originalHeight = strategyGraph.style.height;
    strategyGraph.style.width = '1300px';
    strategyGraph.style.height = '794px'; 

    html2canvas(strategyGraph, { scale: 3 }).then((canvas) => {
      // Restore the original size
      strategyGraph.style.width = originalWidth;
      strategyGraph.style.height = originalHeight;
        const base64image = canvas.toDataURL('image/png');
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = pdf.internal.pageSize.getHeight() -50;


        // Add the image to the PDF
        pdf.text(intl.formatMessage({
          id: "app.titles.strategy-implementation-data",
        }), pdf.internal.pageSize.getWidth() / 2, 20, { align: 'center' });
        pdf.addImage(base64image, 'PNG', 10, 100, imgWidth, imgHeight);

        // Save the PDF
        pdf.save(`${intl.formatMessage({
          id: "app.titles.strategy-implementation-data",
        })}.pdf`);
        setStrategyDownloadLoader(false);
        setZoomStrategyChartLevel(currentZoom)

    }).catch(error => {
      setStrategyDownloadLoader(false);
        console.error('Error capturing element:', error);
    });
  }
}

 useEffect(()=>{

  let downloadTimeOut:any;
  if(strategyDownloadLoader){
   
    setZoomStrategyChartLevel(0);
   downloadTimeOut =  setTimeout(() => {

      downloadStrategyChart();
    }, 1000);
  }

  return ()=>{
    clearTimeout(downloadTimeOut);
  }


},[strategyDownloadLoader])

useEffect(()=>{
  let downloadTimeOut:any;

    if(njsciDownloadLoader){
      
      setZoomSurveyChartLevel(0);
   downloadTimeOut =    setTimeout(() => {
        downloadNjsciGraphPdf();
      }, 1000);
    }


return ()=>{
  clearTimeout(downloadTimeOut);
}

  },[njsciDownloadLoader])

    const datasetKeyProvider = (dataset:any) => {
      return dataset.label; // Use label or any other unique property
    };

  return (
    <div>
      <div className="monitoring_header mb-5">
        <div className="m-auto text-center">
        <h3>{intl.formatMessage({ id: "app.titles.nj-sci-survey-data" })}</h3>
        </div>
        <div className="monitoring_header_links">
          <ul>
            {!isChartSurvey ? (
              <li>
                <button
                  className="linksBtn"
                  onClick={() => setIsChartSurvey(true)}
                >
                <Icon iconSize={16} icon="timeline-bar-chart" /> &nbsp;
                  {intl.formatMessage({ id: "app.titles.chart-view" })}
                </button>
              </li>
            ) : (
              <>
              {njsciDownloadLoader?<Spinner intent="primary" size={40}/>:<li>
                <button
                  className="linksBtn"
                  onClick={() => setNjsciDownloadLoader(true)}
                >
                  <Icon iconSize={16} icon="import" /> &nbsp; 
                  {intl.formatMessage({id:"app.title.graph.download"})}
                </button>
              </li>}
              <li>
                <button
                  className="linksBtn"
                  onClick={() => setIsChartSurvey(false)}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                    >
                      <path
                        d="M13.0452 13.2274L10.8351 13.2272V10.2757L13.777 10.2759V12.4956C13.777 12.9334 13.4829 13.2274 13.0452 13.2274ZM2.2757 12.4956V10.2757L5.15838 10.2759V13.2274L3.00749 13.2272C2.56971 13.2274 2.2757 12.9334 2.2757 12.4954M10.8351 6.01829H13.7769V8.85664H10.8351V6.01829ZM5.15838 8.85664L2.2757 8.85649V6.01829L5.15838 6.01813V8.85664ZM9.41599 13.2274L6.57764 13.2272V10.2757L9.41599 10.2759V13.2274ZM6.57764 6.01829H9.41599V8.85664H6.57764V6.01829ZM3.00749 1.72589H13.0452C13.4829 1.72589 13.777 2.02006 13.777 2.45769V4.59904H2.2757V2.45784C2.2757 2.02006 2.56971 1.72589 3.00749 1.72589ZM13.0452 0.306641H3.00749C1.7885 0.306641 0.856445 1.23885 0.856445 2.45769V12.4956C0.856445 13.7144 1.7885 14.6466 3.00749 14.6466L13.0452 14.6465C14.2641 14.6466 15.1961 13.7144 15.1961 12.4954V2.45769C15.1961 1.23885 14.2641 0.306641 13.0452 0.306641Z"
                        fill="#195A94"
                      />
                    </svg>
                  </span>{" "}
                  &nbsp;{intl.formatMessage({ id: "app.titles.table-view" })}
                </button>
              </li>
            </>
            )}
          </ul>
        </div>
      </div>
      {isChartSurvey ? (
        <>
        <div className="m-auto text-center">
          <Button
                intent="none"
                className="mr-5"

                onClick={() => {
                  zoomSurveyChartLevel > 0 &&
                    setZoomSurveyChartLevel(
                      (prev: any) => +(prev - 0.2).toFixed(1)
                    );
                }}
              >
                <Icon iconSize={20} icon="zoom-out" />
              </Button>
              <p className="d-inline text-lg">
               {zoomSurveyChartLevel * 10}X
              </p>
              <Button
                intent="none"
                className="ml-5"
                onClick={() =>
                  zoomSurveyChartLevel < 1 &&
                  setZoomSurveyChartLevel(
                    (prev: any) => +(prev + 0.2).toFixed(1)
                  )
                }
              >
                <Icon iconSize={20} icon="zoom-in" />
              </Button>
              </div>
          {refresh.surveyChart ? (
            <div className="m-auto pb-20 GraphData" id="njsciGraph">
              {isLoading ? (
                <Spinner intent="primary" />
              ) : (
                <div style={{display:"flex",flexDirection:"column"}}>
                  <div
                    style={{
                      height: "500px",
                      overflowY:
                        zoomSurveyChartLevel > 0.1 ? "scroll" : "hidden",
                      // direction: "rtl",
                      position: "relative",
                    }}
                  >
                   
                    {/* <div style={{ zIndex: 1 }}> */}
                      <div
                        className="graphDiv "
                        style={{
                          minHeight: "500px",
                          height:
                            zoomSurveyChartLevel < 0.2
                              ? "500px"
                              : `${
                                  selectedDateRange.length *
                                  (zoomSurveyChartLevel / 4)
                                }rem`,
                          minWidth: "800px",
                          position: "relative",
                          top: 0,
                          left: 0,
                          zIndex:zoomSurveyChartLevel >= 0.2?100:0

                        }}
                      >
                        <Line
                          data={data}
                          options={options}
                          datasetKeyProvider={datasetKeyProvider}
                        />
                      </div>
                    {/* </div> */}
                  </div>
                  <div
                        className="graphDiv mb-5"
                        style={{
                          height: "200px",
                          minWidth: "800px",
                          position: "relative",
                          left: 0,
                          bottom:153
                        }}
                      >
                        <Line
                          data={{labels:dates,datasets:[]}}
                          options={XAxisOptions}
                          datasetKeyProvider={datasetKeyProvider}
                        />
                      </div>
                </div>
              )}
              <div className="indicatorDiv">
                <ul>
                  {!isLoading &&
                    dataSetLegend?.map((ds: any, index: number) => (
                      <li key={index}>
                        {ds.borderDash.includes(3) && !isEmpty(ds.data) ? (
                          <div className="indicatorCircles">
                            <span className="circleTrack">
                              <span className="circlegrp">
                                <div></div>
                                <div></div>
                                <div></div>
                              </span>
                            </span>
                            <span>
                              {" "}
                              {` ${intl.formatMessage({
                                id: "app.goals.target-indicator.target-score",
                              })}: ${Number(
                                ds.data.find((item: any) => item !== null)
                              )?.toFixed(2)}`}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        {!ds.borderDash.includes(3) ? (
                          <>
                            <span
                              className={
                                ds.pointStyle === "triangle"
                                  ? "indicatorTriangle"
                                  : ds.pointStyle === "rect"
                                  ? "indicatorSquare"
                                  : ds.pointStyle === "rectRot"
                                  ? "indicatorDiamond"
                                  : "indicatorCircle"
                              }
                              style={{ backgroundColor: ds.backgroundColor }}
                            >
                              <span
                                className="graphTrack"
                                style={{
                                  borderTop: ds.borderDash.includes(5)
                                    ? `2px dashed ${ds.backgroundColor}`
                                    : `2px solid ${ds.backgroundColor}`,
                                }}
                              ></span>
                            </span>
                            <span style={{color:hoverLabel===ds.label?"black":hoverLabel===null?"black":"darkgray"}}>
                              {ds.label}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </>
      ) : isLoading ? (
        <Spinner intent="primary" />
      ) : refresh.surveyChart ? (
        <SurveyRespondentGroupGraph
          selectedRespondentGroup={selectedRespondentGroup}
          selectedMultiDomains={selectedMultiDomains}
          selectedMultiSurveyItem={selectedMultiSurveyItem}
          AdminLabelPoints={AdminLabelPoints}
          RespondentGraphDomainsData={RespondentGraphDomainsData}
          RespondentGraphItemsData={RespondentGraphItemsData}
          selectedGoal={selectedGoal}
          selectedDateRange={selectedDateRange}
        />
      ) : (
        <Spinner intent="primary" />
      )}

      <div className="monitoring_header mb-5">
        <div className="m-auto text-center ">
          <h3 className="d-flex justify-center">
          {intl.formatMessage({
              id: "app.titles.strategy-implementation-data",
            })}
          </h3>
          {selectedAlignedStrategy.length > 0 ? (
            ""
          ) : (
            <h3 className="d-flex justify-center">
            {intl.formatMessage({ id: "app.titles.no-aligned-strategy" })}
          </h3>
          )}
        </div>

        <div className="monitoring_header_links">
          <ul>
            {!isChartStratery ? (
              <li>
                <button
                  className="linksBtn"
                  onClick={() => setIsChartStrategy(true)}
                >
                <Icon iconSize={16} icon="timeline-bar-chart" /> &nbsp;
                  {intl.formatMessage({ id: "app.titles.chart-view" })}
                </button>
              </li>
            ) : (
              <>
              {strategyDownloadLoader?<Spinner intent="primary" className="linksBtn" size={40}/>: <li>
                <button
                  className="linksBtn"
                  onClick={() => setStrategyDownloadLoader(true)}
                >
                  <Icon iconSize={16} icon="import" /> &nbsp; 
                  {intl.formatMessage({id:"app.title.graph.download"})}
                </button>
              </li>}
              <li>
                <button
                  className="linksBtn"
                  onClick={() => setIsChartStrategy(false)}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                    >
                      <path
                        d="M13.0452 13.2274L10.8351 13.2272V10.2757L13.777 10.2759V12.4956C13.777 12.9334 13.4829 13.2274 13.0452 13.2274ZM2.2757 12.4956V10.2757L5.15838 10.2759V13.2274L3.00749 13.2272C2.56971 13.2274 2.2757 12.9334 2.2757 12.4954M10.8351 6.01829H13.7769V8.85664H10.8351V6.01829ZM5.15838 8.85664L2.2757 8.85649V6.01829L5.15838 6.01813V8.85664ZM9.41599 13.2274L6.57764 13.2272V10.2757L9.41599 10.2759V13.2274ZM6.57764 6.01829H9.41599V8.85664H6.57764V6.01829ZM3.00749 1.72589H13.0452C13.4829 1.72589 13.777 2.02006 13.777 2.45769V4.59904H2.2757V2.45784C2.2757 2.02006 2.56971 1.72589 3.00749 1.72589ZM13.0452 0.306641H3.00749C1.7885 0.306641 0.856445 1.23885 0.856445 2.45769V12.4956C0.856445 13.7144 1.7885 14.6466 3.00749 14.6466L13.0452 14.6465C14.2641 14.6466 15.1961 13.7144 15.1961 12.4954V2.45769C15.1961 1.23885 14.2641 0.306641 13.0452 0.306641Z"
                        fill="#195A94"
                      />
                    </svg>
                  </span>{" "}
                  &nbsp; {intl.formatMessage({ id: "app.titles.table-view" })}
                </button>
              </li>
            </>
            )}
          </ul>
        </div>
      </div>
      {isChartStratery ? (
        <>
        <div className="m-auto text-center">
          <Button
                intent="none"
                className="mr-5"

                onClick={() => {
                  zoomStrategyChartLevel > 0 &&
                  setZoomStrategyChartLevel(
                      (prev: any) => +(prev - 2).toFixed(1)
                    );
                }}
              >
                <Icon iconSize={20} icon="zoom-out" />
              </Button>
              <p className="d-inline text-lg">
               {zoomStrategyChartLevel}X
              </p>
              <Button
                intent="none"
                className="ml-5"
                onClick={() =>
                  zoomStrategyChartLevel < 10 &&
                  setZoomStrategyChartLevel(
                    (prev: any) => +(prev + 2).toFixed(1)
                  )
                }
              >
                <Icon iconSize={20} icon="zoom-in" />
              </Button>
              </div>
          {refresh.strategyChart ? (
            <div
             className="m-auto pb-20 GraphData" 
             id="strategyImplementationChart"
             >

                <div style={{display:"flex",flexDirection:"column"}}>
                <div
                  style={{
                    height: "500px",
                    overflowY:
                      zoomStrategyChartLevel > 1 ? "scroll" : "hidden",
                    // direction: "rtl",
                    position: "relative",
                  }}
                >

                  {/* <div style={{ zIndex: 1 }}> */}
                    <div
                      className="graphDiv "
                      style={{
                        minHeight: "500px",
                        height:
                          zoomStrategyChartLevel < 2
                            ? "500px"
                            : `${
                                zoomStrategyChartLevel *20
                              }rem`,

                        minWidth: "800px",
                        position: "relative",
                        top: 0,
                        left: 0,
                        zIndex:zoomStrategyChartLevel > 1?100:0
                      }}
                    >
                      <Line
                        data={data2}
                        options={options2}
                        datasetKeyProvider={datasetKeyProvider}
                      />
                    </div>
                  {/* </div> */}
                </div>
                <div
                      className="graphDiv mb-5"
                      style={{
                        height: "200px",
                        minWidth: "800px",
                        position: "relative",
                        left: 8,
                        bottom:zoomStrategyChartLevel >1 ? 130:200
                      }}
                    >
                      <Line
                        data={{labels:dates,datasets:[]}}
                        options={XAxisOptions}
                        datasetKeyProvider={datasetKeyProvider}
                      />
                    </div>
            </div>
              <div className="indicatorDiv">
                <ul>
                  {ImplementationDataSetLegend.filter(
                    (item: any) => !isEmpty(item.data)
                  )?.map((ds: any, index: number) => (
                    <li key={index}>
                      <span
                        className="indicatorCircle"
                        style={{ backgroundColor: ds.backgroundColor }}
                      ></span>
                      <span>{ds.label}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </>
      ) : (
        <StrategyRespondentGroupGraph
          selectedAlignedStrategy={selectedAlignedStrategy}
          selectedDateRange={selectedDateRange}
          alignStrategies={alignStrategies}
        />
      )}
    </div>
  );
};

export default RespondentGroupGraph;
