import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";

import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getGoalEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getGoalByGoalId.request)),
    switchMap((action) =>
      from(api.goals.getGoalByGoalId(action.payload)).pipe(
        map(actions.getGoalByGoalId.success),
        catchError((error) => of(actions.getGoalByGoalId.failure(error)))
      )
    )
  );
};

export const getGoalsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getGoalsByPlan.request)),
    switchMap((action) =>
      from(api.goals.getGoalsByPlan(action.payload)).pipe(
        map(actions.getGoalsByPlan.success),
        catchError((error) => of(actions.getGoalsByPlan.failure(error)))
      )
    )
  );
};

export const addGoalEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.createGoal.request)),
    switchMap((action) =>
      from(api.goals.addGoal(action.payload)).pipe(
        map(actions.createGoal.success),
        catchError((error) => of(actions.createGoal.failure(error)))
      )
    )
  );
};

export const updateGoalEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateGoal.request)),
    switchMap((action) =>
      from(api.goals.updateGoal(action.payload)).pipe(
        map(actions.updateGoal.success),
        catchError((error) => of(actions.updateGoal.failure(error)))
      )
    )
  );
};

export const triggerPusherEventEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.triggerPusherEvent.request)),
    switchMap((action) =>
      from(api.goals.triggerPusherEvent(action.payload.plan_id, { event: action.payload.event, channel_name: action.payload.channel_name, data: action.payload.data }))
        .pipe(
          map(actions.triggerPusherEvent.success),
          catchError((error) => of(actions.triggerPusherEvent.failure(error)))
        )
    )
  );
};


export const addDomainItemIndicatorEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addDomainItemIndicator.request)),
    switchMap((action) =>
      from(api.goals.addDomainItemIndicator(action.payload.planId, action.payload.indicator))
        .pipe(
          map(actions.addDomainItemIndicator.success),
          catchError((error) => of(actions.addDomainItemIndicator.failure(error)))
        )
    )
  );
};




export const deleteGoalEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteGoal.request)),
    switchMap((action) =>
      from(api.goals.deleteGoal(action.payload.planId, action.payload.id)).pipe(
        mergeMap(() =>
          from([
            actions.deleteGoal.success(action.payload.id),
          ])
        ),
        catchError((error) => of(actions.deleteGoal.failure(error)))
      )
    )
  );
};

export const updateGoalIndicatorEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateGoalIndicator.request)),
    switchMap((action) =>
      from(api.goals.updateGoalIndicator(action.payload)).pipe(
        map(actions.updateGoalIndicator.success),
        catchError((error) => of(actions.updateGoalIndicator.failure(error)))
      )
    )
  );
};

export const getGoalActivityLogEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getGoalActivityLog.request)),
    switchMap((action) =>
      from(api.goals.getGoalActivityLog(action.payload)).pipe(
        map(actions.getGoalActivityLog.success),
        catchError((error) => of(actions.getGoalActivityLog.failure(error)))
      )
    )
  );
};


export const getGoalActivityAllLogEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getGoalActivityAllLog.request)),
    switchMap((action) =>
      from(api.goals.getGoalActivityAllLog(action.payload)).pipe(
        map(actions.getGoalActivityAllLog.success),
        catchError((error) => of(actions.getGoalActivityAllLog.failure(error)))
      )
    )
  );
};

export const getAllGoalActivityLogsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getAllGoalActivityLogs.request)),
    switchMap((action) =>
      from(api.goals.getAllGoalActivityLogs(action.payload)).pipe(
        map(actions.getAllGoalActivityLogs.success),
        catchError((error) => of(actions.getAllGoalActivityLogs.failure(error)))
      )
    )
  );
};


export const deleteGoalDomainEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteGoalDomain.request)),
    switchMap((action) =>
      from(api.goals.deleteGoalDomain(action.payload)).pipe(
        mergeMap(() =>
          from([
            actions.deleteGoalDomain.success(action.payload),
          ])
        ),
        catchError((error) => of(actions.deleteGoalDomain.failure(error)))
      )
    )
  );
};

export const deleteGoalIndicatorEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteGoalIndicator.request)),
    switchMap((action) =>
      from(api.goals.deleteGoalIndicator(action.payload)).pipe(
        mergeMap(() =>
          from([
            actions.deleteGoalIndicator.success(action.payload),
          ])
        )
        ,
        catchError((error) => of(actions.deleteGoalIndicator.failure(error)))
      )
    )
  );
};

