import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Icon } from "@blueprintjs/core";
import TextField from "@mui/material/TextField";
import { addHttp } from "../../../../../../team-plan/utils";
import {
  AttachedResource,
  AttachedResourceType,
  AttachedToType,
} from "../../../../../../../types";
import {
  hideUpsertResourceDialog,
  showUpsertResourceDialog,
} from "../../../../../../../store/resources/actions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { AppToaster } from "../../../../../../../helpers/toaster";

type OwnProps = {
  onEnterUrlClick?: any;
};
type Props = OwnProps;

const AlertDialog: React.FunctionComponent<Props> = (props) => {
  const { onEnterUrlClick } = props;
  const dispatch = useDispatch();
  const intl = useIntl();

  const [resourceUrl, setResourceUrl] = React.useState<string | undefined>("");
  const [resourceTitle, setResourceTitle] = React.useState<string | undefined>(
    ""
  );

  const {
    show,
    onClose,
    onSave,
    resource,
    isLoadingSelector,
    errorSelector,
  } = useSelector((s) => s.resources.dialogs.upsertResourceDialog);

  React.useEffect(() => {
    if (resource) {
      setResourceTitle(resource?.title);
      setResourceUrl(resource?.url);
    }
  }, [resource]);

  const handleResourceUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setResourceUrl(value);
  };

  const handleResourceUrlUpdate = (e: any) => {
    const value = e.target.value;
    let httpValue = value?addHttp(value):"";
    setResourceUrl(httpValue);
  };

  const handleResourceTitleChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setResourceTitle(value);
  };

  const handleClickOpen = () => {
    setResourceUrl("");
    setResourceTitle("");
    onEnterUrlClick();
  };

  const handleClose = () => {
    dispatch(hideUpsertResourceDialog());
    setResourceUrl("");
    setResourceTitle("");
  };

  function isValidUrl(url: string) {
    // Regular expression for validating URLs
    let urlPattern = /^(?:(?:https?|ftp):\/\/)?(?:[\w-]+\.)+[a-z]{2,}(?:\/[^\s]*)?$/
    return urlPattern.test(url);
  }

  const handleSubmit = () => {
    if (resourceUrl && isValidUrl(resourceUrl)) {
      const data = {
        ...resource,
        title: resourceTitle,
        url: resourceUrl,
      };
      const handleLinkResourceAdd = (resource: any) => {
        onSave && onSave(resource);
      };

      handleLinkResourceAdd(data);
    } else {
      return AppToaster.show({
        intent: "danger",
        message: intl.formatMessage({
          id: "app.sci-writing.create-edit-note.invalid-url.warning",
        }),
      });
    }
  };

  return (
    <React.Fragment>
      <button onClick={handleClickOpen} className="stroke-btn">
        {" "}
        {intl.formatMessage({ id: "app.resources.enter-url" })}{" "}
      </button>
      <Dialog
        className="smallest-modal"
        open={!!show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="modal-title">
          <h3>{intl.formatMessage({ id: "app.resources.enter-url" })}</h3>
          <button onClick={handleClose}>
            <Icon icon="cross" iconSize={18} />
          </button>
        </div>
        <div className="modalBody">
          <div className="mb-3">
            <TextField
              fullWidth
              value={resourceTitle}
              label="Resource Title"
              id="outlined-size-small"
              size="small"
              onChange={handleResourceTitleChange}
            />
          </div>
          <div>
            <TextField
              fullWidth
              label="Resource URL"
              value={resourceUrl}
              id="outlined-size-small"
              size="small"
              placeholder="https://www.example.com"
              onChange={handleResourceUrlChange}
              onBlur={handleResourceUrlUpdate}
            />
          </div>
        </div>
        <div className="modalFooter">
          <button
            onClick={handleSubmit}
            disabled={!resourceTitle || !resourceUrl}
            className="nj-btn"
          >
            {" "}
            {intl.formatMessage({
              id: "app.resources.link.add-resource-url",
            })}{" "}
          </button>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default AlertDialog;
