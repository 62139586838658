import React from "react";
import { Domain } from "../../../../types";
import { Card } from "@blueprintjs/core";
import { generatePath, useHistory } from "react-router";
import useDistrictComparisonParams from "../../../../helpers/hooks/useDistrictComparisonParams";
import NegativeDomainIcon from "../../../surveys/cards/NegativeDomainIcon";
import useHighlightedText from "../../../../helpers/hooks/useHighlightedText";

type OwnProps = {
  domain: Domain;
  searchParam?: string;
};

type Props = OwnProps;

const ReportTableDomainItem: React.FC<Props> = (props: Props) => {
  const { domain, searchParam } = props;

  const history = useHistory();

  const { reportPagePath, routeParams } = useDistrictComparisonParams();

  const handleDomainClick = () => {
    const { tagId, ...params } = routeParams;

    history.push({
      pathname: generatePath(reportPagePath, {
        ...params,
        domainId: domain.id,
        // only for plan
        workspace: "data",
      } as any),
      search: searchParam ? searchParam : "",
    });
  };
  const { highlightedText } = useHighlightedText({
    text: domain?.name,
  });

  function addOpacity(color:string, opacity:any) {
    // coerce values so ti is between 0 and 1.
    let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  return (
    <Card
      className="domain-wrapper__pill ml-5"
      style={{  'borderLeft': '5px solid ' + (domain?.color_hex ?? ''), 'backgroundColor':domain?.color_hex ? addOpacity(domain?.color_hex,0.2):'null' }}
      // className="bg-white flex-grow text-xl text-center border rounded p-4 cursor-pointer relative"
      // style={{ maxWidth: "25rem" }}
      onClick={handleDomainClick}
      data-tour={"domain-tour"}
      interactive
    >
      {domain?.domain_answer.is_negative && (
        <NegativeDomainIcon
          forCard
          isDistricComparison={true}
          targetClassName={"float-right -mt-4 -mr-3"}
        />
      )}
      <strong dangerouslySetInnerHTML={{ __html: highlightedText }} />
    </Card>
  );
};

export default ReportTableDomainItem;
