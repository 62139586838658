import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { Button } from "@blueprintjs/core";
import moment from "moment/moment";
import { Goal, GoalActivityLog, GoalActivityLogAll, GoalAllActivityLogs, GoalIndicatorTarget } from "../../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { getAllGoalActivityLogs } from "../../../../../store/goals/actions";
import { INDICATOR_DOMAIN_LEVEL } from "../../../../../constants/constants";

type OwnProps = {
    goalActivitydata: Goal[];
  };

type Props = OwnProps;

const TERMS_COLUMNS =
  '["Date Goal Created","Date of Last Edit","Time of Last Edit”,"District","School(s)","Goal Short Title", "Domain (Respondent Group)","Item (Respondent Group)"]';


const DownloadGoalHistory: React.FC<Props> = ({ goalActivitydata }: Props) => {

  const intl = useIntl();
  const dispatch = useDispatch();

  const goalAllActivityData= useSelector((s)=>s.goals.goalAllActivityData?.list) as GoalAllActivityLogs[]


  useEffect(() => {
    dispatch(getAllGoalActivityLogs.request({}))
  },[]) 

const handleCSVDownloadClick = useCallback(() => {
    const csvStr = [
    TERMS_COLUMNS,
    ...goalAllActivityData?.map((ua: GoalAllActivityLogs) => {
        const formattedObject = {
        created_at: moment(ua.created_at).format("MM/DD/YYYY"),
        updated_at: moment(ua.updated_at).format("MM/DD/YYYY"),
        time: moment(ua.updated_at).format("HH:mm"),
        district_name: ua.plans?.district?.name,
        school_name: ua.plans?.school?.name,
        statement: ua.statement,
        domain_targets: ua.targets?.filter((ep: GoalIndicatorTarget) => {
          if(ep.type == INDICATOR_DOMAIN_LEVEL){
            return true
          }
        }).map((et: GoalIndicatorTarget) =>{
        return `${et.domain_priority.domain.name} (${intl.formatMessage({
          id: `app.surveys.survey-demographic.${et.demographic}`,
        })})`
        }).join("; "),
        item_targets: ua.targets?.filter((ep: GoalIndicatorTarget) => {
          if(ep.type != INDICATOR_DOMAIN_LEVEL){
            return true
          }
        }).map((et: GoalIndicatorTarget) =>{
        return `${et.item_priority?.item.short_code?et.item_priority?.item.short_code:et.item_priority?.item.text} (${intl.formatMessage({
          id: `app.surveys.survey-demographic.${et.demographic}`,
        })})`
        }).join("; "),
        };
        return JSON.stringify(Object.values(formattedObject));
    }),
    ]
    .join("\n")
    .replace(/(^\[)|(\]$)/gm, "");
    const blob = new Blob([csvStr], { type: "text/csv;charset=utf-8;" });
    const csvUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute(
    "download",
    `Goal History${moment().format("MM/DD/YYYY")}`
    );
    link.href = csvUrl;
    document.body.append(link);
    link.click();
    link.remove();
}, [goalActivitydata]);
return (
    <Button
    text={intl.formatMessage({ id: "app.titles.download-as-CSV" })}
    title={intl.formatMessage({ id: "app.titles.download-as-CSV" })}
    intent="primary"
    onClick={handleCSVDownloadClick}
    />
);
};
  
  export default DownloadGoalHistory;