import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Callout,
  Classes,
  Dialog,
  Icon,
  Popover,
  PopoverInteractionKind,
  Tab,
  Tabs,
  Tag,
} from "@blueprintjs/core";
import Pusher from "pusher-js";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import WorkspaceHeader from "../workspace-header/WorkspaceHeader";
import Tooltip from '@mui/material/Tooltip';
import {
  Activity,
  ActivityScopeEnum,
  ActivityTagEnum,
  DomainPriority,
  SelectorOptionType,
  SurveyDeploymentStateEnum,
  UserNotesType,
  UserModel
} from "../../../types";
import SurveyDeploymentSelectorPanel from "./SurveyDeploymentSelectorPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSurveyDeploymentIdOnNeedsPage,
  realtimeUpdateDomainPriority,
  confirmDomainPriorityStatus,
  realtimeUpdateDomainPriorityDecision,
} from "../../../store/needs/actions";
import {
  UpdateDomainPrioritiesDecisionResponse,
  ConfirmSurveyDeploymentResponse,
} from "../../../api/needs/types";
import ActivityResourcesTab from "../members/tabs/resources-tab/ActiveResourcesTabs";
import { generatePath, useHistory, useParams } from "react-router";
import { AuthRoutes } from "../../../App";
import BeforeBeginningDialog from "./before-beginning-dialog/BeforeBeginningDialog";
import ExploreStrengthsAndNeedsTab from "./explore-strengths-and-needs-tab/ExploreStrengthsAndNeedsTab";
import ItemLevelInfoAndBookmarksDialog from "./item-level-info-and-bookmarks-dialog/ItemLevelInfoAndBookmarksDialog";
import { useGetDomainPriorities } from "../../../helpers/hooks/loading/useGetDomainPriorities";
import useGetDatasetMarksLoading from "../../../helpers/hooks/loading/useGetDatasetMarksLoading";
import DigDeeperTab from "./dig-deeper-tab/DigDeeperTab";
import { getSurveyComparison } from "../../../store/survey-reports/actions";
import { POTENTIAL_PRIORITY_COLOR } from "./item-level-info-and-bookmarks-dialog/useBookmarksLevelInfo";
import { TabId } from "@blueprintjs/core/lib/esm/components/tabs/tab";
import useAvailableTabs from "./useAvailableTabs";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../store/UIState/actions";
import { PUSHER_CLUSTER, PUSHER_KEY } from "../../../api/config";
import { isDistrictPlan } from "../../../store/plans/selectors";
import { getActivities } from "../../../store/activities/actions";
import { showActivityVideoDialog } from "../../../store/activities/actions";
import { ACTIVITY_LINK_CLASSNAME } from '../../../helpers/hooks/useAttachActivities';
import peopleSvgImage from '../../../static/icons/svg/Vector.svg';
import useBeforeBeginningDialog from "./useBeforeBeginningDialog";



type OwnProps = {
};

type bookmarkStatuses = {
  user: UserModel;
  isDistrictPerson: boolean;
  bookmarksStarted: boolean;
};
type districtTeamBookmarkStatuses = {
  team: bookmarkStatuses[];
  district: bookmarkStatuses[];
};

type Props = OwnProps;
const PusherChannel = "event_strength_needs";
const PusherChannel2 = "event_decision";
const PusherChannel3 = "event_confirm_priority";

const NeedsPage: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activePlanId = useSelector((s) => s.plans.activePlan?.id);
  const surveyDeploymentId = useSelector((s) => s.needs.surveyDeploymentId);
  const {
    beforeBeginningDialog: { statuses },
  } = useBeforeBeginningDialog();

  useEffect(() => {
    if (surveyDeploymentId) {
      const pusher = new Pusher(PUSHER_KEY, {
        cluster: PUSHER_CLUSTER,
        channelAuthorization: {
          endpoint: "/pusher/auth",
          transport: "ajax",
          params: {},
          headers: {},
        },
        userAuthentication: {
          endpoint: "/pusher/auth",
          transport: "ajax",
          headers: {
            "X-CSRF-Token": "some_csrf_token",
          },
        },
      });

      pusher.connection.bind("error", function (err: any) {
        if (err?.error?.data?.code === 4004) {
          console.log("Over limit!");
        }
      });

      const channel = pusher.subscribe(
        "private-strength_needs_" + surveyDeploymentId
      );

      channel.bind(
        PusherChannel,
        function (data: { data: DomainPriority; is_new: boolean }) {
          dispatch(realtimeUpdateDomainPriority(data));
        }
      );
      channel.bind(
        PusherChannel2,
        function (data: { data: UpdateDomainPrioritiesDecisionResponse }) {
          dispatch(realtimeUpdateDomainPriorityDecision(data.data));
        }
      );
      channel.bind(
        PusherChannel3,
        function (data: { data: ConfirmSurveyDeploymentResponse }) {
          if (data.data?.survey_deployment_id === surveyDeploymentId) {
            setIsPriorityConfirmed(data.data?.is_confirmed_prioritized_domains);
          }
        }
      );
      return () => {
        pusher.disconnect();
      };
    }
  }, [surveyDeploymentId]);

  const { tabId } = useParams<{ tabId?: string }>();

  const history = useHistory();

  const selectedTabId: UserNotesType = useMemo(() => {
    return tabId &&
      [
        UserNotesType.SNA_EXPLORE_STRENGTHS_NEEDS,
        UserNotesType.SNA_DIGGING_DEEPER,
        UserNotesType.SNA_PRIORITIZE_NEEDS,
        UserNotesType.SNA_RESOURCES,
      ].includes(tabId as UserNotesType)
      ? (tabId as UserNotesType)
      : UserNotesType.SNA_EXPLORE_STRENGTHS_NEEDS;
  }, [tabId]);

  let count:number= 0;
  let data:any=[];
  
  const bookmarkStatuses = useMemo(() => {
    let bookmarkStatuses: districtTeamBookmarkStatuses = {
      team: [],
      district: [],
    };
    bookmarkStatuses.team = statuses.filter((m) => !m.isDistrictPerson);
    bookmarkStatuses.district = statuses.filter(
      (m) => m.bookmarksStarted && m.isDistrictPerson
    );
  
    bookmarkStatuses.team.map((tMs) => {
      data.push(tMs.bookmarksStarted)
      count = data.filter(Boolean).length;
   })
    return count;
  }, [statuses]);


  const surveyDeployments = useSelector(
    (s) => s.surveysDeployment.surveysDeploymentBySchool
  );
  const activities = useSelector((s) => s.activities.activities);
  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const handleShowLearnModuleDialog = () => {
    // dispatch(showLearningModuleDialog(true));
    const searchString = "Overview of Prioritizing Strengths and Needs";
    const activity: any = activities.find((item) => {
      return item.name === searchString;
    });

    dispatch(
      showActivityVideoDialog({
        activity: activity,
        viewable_field: "embed_code",
      })
    );
  };

  const handleShowScreencastTutorialDialog = () => {
    // dispatch(showScreencastTutorialDialog(true));
    const searchString = "Screencast Tutorial of Strengths and Needs Analysis";
    const activity: any = activities.find((item) => {
      return item.name === searchString;
    });

    dispatch(
      showActivityVideoDialog({
        activity: activity,
        viewable_field: "embed_code",
      })
    );
  };

  useEffect(() => {
    if (activePlanId) {
      dispatch(
        getActivities.request({
          type: isDistrictActivePlan
            ? ActivityScopeEnum.DISTRICT
            : ActivityScopeEnum.SCHOOL,
          tag: ActivityTagEnum.NEEDS,
        })
      );
    }
  }, [activePlanId, dispatch, isDistrictActivePlan]);

  // this state will be responsible for the showing of warning and disabling of the functionality for already confirmed priorities
  const [isPriorityConfirmed, setIsPriorityConfirmed] = useState<
    boolean | undefined
  >(false);

  let mostRecentDeployment = surveyDeployments.find((sd) => {
    if (
      sd.state === SurveyDeploymentStateEnum.READY &&
      sd.is_deleted === false
    ) {
      return sd;
    }
  });

  useEffect(() => {
    !!mostRecentDeployment &&
      dispatch(changeSurveyDeploymentIdOnNeedsPage(mostRecentDeployment?.id));
  }, [dispatch, mostRecentDeployment]);

  const setSurveyDeploymentId = (id: number | undefined) => {
    dispatch(changeSurveyDeploymentIdOnNeedsPage(id));
  };

  useGetDomainPriorities();
  useGetDatasetMarksLoading({}); // empty params to prevent extra api call

  const schoolId = useSelector((s) => s.plans.activePlan?.school?.id);

  const reloadSurveyComparison = useCallback(
    (newTabId?: TabId, prevTabId?: TabId) => {
      if (surveyDeploymentId == null || schoolId == null || newTabId == null) {
        return;
      }

      const tadDependsOnSurveyComparison = [
        UserNotesType.SNA_EXPLORE_STRENGTHS_NEEDS,
        UserNotesType.SNA_DIGGING_DEEPER,
      ].includes(newTabId as UserNotesType);

      if (!tadDependsOnSurveyComparison) {
        return;
      }

      const loadedOnPreviousTab =
        prevTabId &&
        [
          UserNotesType.SNA_EXPLORE_STRENGTHS_NEEDS,
          UserNotesType.SNA_DIGGING_DEEPER,
        ].includes(prevTabId as UserNotesType);

      if (loadedOnPreviousTab) {
        return;
      }

      dispatch(
        getSurveyComparison.request({
          survey_deployment_id: [surveyDeploymentId],
          school_id: [schoolId],
          hide_sensitive_reporting_data: true
        })
      );
    },
    [surveyDeploymentId, schoolId, dispatch]
  );

  useEffect(() => {
    reloadSurveyComparison(selectedTabId);
  }, [reloadSurveyComparison, selectedTabId]);

  const availableTabs = useAvailableTabs();
  const [showRedBorder, setShowRedBorder] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleTabChange = (newTabId: TabId, prevTabId: TabId | undefined) => {
    if (availableTabs.includes(newTabId as UserNotesType)) {
      reloadSurveyComparison(newTabId, prevTabId);
      history.push(
        generatePath(AuthRoutes.YourPlanNeeds, {
          workspace: "needs",
          tabId: newTabId,
        } as any)
      );
    } else {
      dispatch(
        showConfirmDialog({
          onConfirm: () => {
            dispatch(hideConfirmDialog());
            if (newTabId === UserNotesType.SNA_DIGGING_DEEPER) {
              setShowRedBorder(true);
              setTimeout(() => {
                setShowRedBorder(false);
              }, 5000);
            }
          },
          show: true,
          intent: "primary",
          text: intl.formatMessage({
            id: `app.confirm-dialog.needs.${newTabId}`,
          }),
          icon: "info-sign",
        })
      );
    }
  };
  // this state write for opening the "Before Starting Strengths and Needs Analysis" modal
  const [openBeforeBeginDialog, setOpenBeforeBeginDialog] = useState<boolean>(
    false
  );
  // this state will be used to display the selected survey deployment in BeforeBeginningDialog
  const [selectedSurveyDeployment, setSelectedSurveyDeployment] = useState<
    SelectorOptionType | undefined
  >();

  // this state will be responsible for the disabling of the continue button in dig deeper
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const infoButton = useCallback((onClick: Function) => {
    return (
      <Button
        icon={<Icon icon={"info-sign"} iconSize={22} color="black" />}
        onClick={() => onClick()}
        minimal
      />
    );
  }, []);

  const infoButtonWithText = useCallback((onClick: Function) => {
    return (
      <button onClick={() => onClick()}>
        <span className="info-icon">i</span> Info
      </button>
    );
  }, []);

  // look for changes in selected survey deployment
  useEffect(() => {
    const sd = surveyDeployments.find(
      (et) => et.id === selectedSurveyDeployment?.value
    );
    setIsPriorityConfirmed(sd?.is_confirmed_prioritized_domains);
  }, [selectedSurveyDeployment, surveyDeployments]);

  const tabInfo = useMemo(() => {
    switch (selectedTabId) {
      case UserNotesType.SNA_EXPLORE_STRENGTHS_NEEDS: {
        return intl.formatMessage(
          {
            id: "app.needs.tabs.explore-strengths-and-needs.description",
          },
          {
            icon: infoButton(() => setOpenBeforeBeginDialog(true)), // set the state for open the "Before Starting Strengths and Needs Analysis" modal
            learningModule: (
              <a
                href="#"
                className="text-link"
                onClick={handleShowLearnModuleDialog}
              >
                {intl.formatMessage({
                  id:
                    "app.team-plan.strengths-and-needs-analysis.instructions.link.text",
                })}
              </a>
            ),
            screencastTutorial: (
              <a
                href="#"
                className="text-link"
                onClick={handleShowScreencastTutorialDialog}
              >
                {intl.formatMessage({
                  id:
                    "app.team-plan.strengths-and-needs-analysis.instructions.link.text.screencast",
                })}
              </a>
            ),
          }
        );
      }

      case UserNotesType.SNA_DIGGING_DEEPER: {
        return intl.formatMessage(
          { id: "app.needs.tabs.dig-deeper.description" },

          {
            learningModule: (
              <a
                href="#"
                className="text-link"
                onClick={handleShowLearnModuleDialog}
              >
                {intl.formatMessage({
                  id:
                    "app.team-plan.strengths-and-needs-analysis.instructions.link.text",
                })}
              </a>
            ),
            screencastTutorial: (
              <a
                href="#"
                className="text-link"
                onClick={handleShowScreencastTutorialDialog}
              >
                {intl.formatMessage({
                  id:
                    "app.team-plan.strengths-and-needs-analysis.instructions.link.text.screencast",
                })}
              </a>
            ),

            gold: (t) => (
              <Tag
                large
                multiline
                round
                className="font-bold text-black"
                style={{
                  background: POTENTIAL_PRIORITY_COLOR,
                  fontSize: "inherit",
                }}
              >
                {t}
              </Tag>
            ),
          }
        );
      }

      case UserNotesType.SNA_PRIORITIZE_NEEDS: {
        return intl.formatMessage({
          id: "app.needs.tabs.prioritize-areas.description",
        });
      }

      default:
        return null;
    }
  }, [selectedTabId, activities]);

  const noDataCallout = useMemo(
    () =>
      !!mostRecentDeployment ? (
        <Callout
          title={intl.formatMessage({
            id: "app.survey-reports.no-survey-deployment.description",
          })}
          intent="warning"
        />
      ) : (
        <Callout
        title={intl.formatMessage({
          id: "app.survey-reports.no-survey-deployment.description",
        })}
        intent="warning"
      />
      ),
    []
  );

  const confirmPriority = () => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(
            confirmDomainPriorityStatus.request({
              schoolId: schoolId,
              surveyDeploymentId: surveyDeploymentId,
              onComplete: () => {
                // mark this survey deployment as confirmed
                setIsPriorityConfirmed(true);
                dispatch(hideConfirmDialog());
              },
            })
          );
        },
        show: true,
        intent: "primary",
        icon: "info-sign",
        text: intl.formatMessage(
          { id: "app.dig-deeper.confirm-priority-dialogs" },
          {
            br: <br />,
            i: (chunks) => <i>{chunks}</i>,
            click: (chunks) => (
              <b
                onClick={() => {
                  // onHandleClick(true);
                  dispatch(hideConfirmDialog());
                }}
              >
                <a>{chunks}</a>
              </b>
            ),
          }
        ),
        confirmButtonText: intl.formatMessage({
          id: "app.dig-deeper.confirm-priority-dialogs.confirm",
        }),
        cancelButtonText: intl.formatMessage({
          id: "app.titles.cancel",
        }),
        onCancel: () => {
          dispatch(hideConfirmDialog());
        },
      })
    );
  };

  const leaningAndScreenCast = (
    <ul>
      <li>
        <button className={`${ACTIVITY_LINK_CLASSNAME} playButton`} onClick={handleShowLearnModuleDialog}>
          <span className="playButtonBg">
            <Icon iconSize={15} icon="play" />
          </span>
        {intl.formatMessage({ id: "app.team-plan.strengths-and-needs-analysis.instructions.link.text" })}
      </button>
    </li>
    <li>
      {infoButtonWithText(() => setOpenBeforeBeginDialog(true))}
    </li>
  </ul>
  );



  const administrationLabelWithookMark = (
    <>
      <div className="flex">
        <h6 style={{marginLeft:"200px"}} className="flex-1 text-center font-bold mt-2 mb-2 ml-30">{(mostRecentDeployment?.survey?.name ? mostRecentDeployment?.survey?.name : '') +" "+ (mostRecentDeployment?.deployment_name ? mostRecentDeployment?.deployment_name : '')}</h6>
       
        <Tooltip title={intl.formatMessage({ id: "app.titles.needs.users-that-bookmarked-info" },
      
                    { count:`${bookmarkStatuses}`}
                    )} arrow>
        <button style={{color:"#155b98"}} className="font-medium mr-10 flex items-center"><img className="mr-1 text-xs" src={peopleSvgImage} />{intl.formatMessage({ id: "app.titles.needs.users-that-bookmarked" })}</button>
         </Tooltip>
      
      </div>
      <hr />
      <p className="text-center mt-2 mb-2">{intl.formatMessage({ id: "app.titles.needs.domain-means-and-bookmarks-across-respondent-groups" })}</p>
    </>
  );
  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "app.titles.needs" })}</title>
      </Helmet>
      <div className="teampage_container strengths_needs">
        <div className="mb-3">
          <WorkspaceHeader
            title={intl.formatMessage({ id: "app.titles.needs" })}
            userNotesType={selectedTabId as UserNotesType} nextElement={leaningAndScreenCast}
          />

          {administrationLabelWithookMark}

          {!!tabInfo && (
            <>
              <div className="pt-0 p-5">{tabInfo}</div>
              <div className="pt-0 p-5 pb-0">
              <SurveyDeploymentSelectorPanel
                selectedSurveyDeployment={selectedSurveyDeployment}
                setSelectedSurveyDeployment={setSelectedSurveyDeployment}
                surveyDeploymentId={surveyDeploymentId}
                setSurveyDeploymentId={setSurveyDeploymentId}
              />
              </div>
            </>
          )}
        </div>
        <div className="teampage_container__tab">
        <Tabs
          id="needs-tab"
          onChange={handleTabChange}
          selectedTabId={selectedTabId}
          renderActiveTabPanelOnly
        >
          <Tab
            id={UserNotesType.SNA_EXPLORE_STRENGTHS_NEEDS}
            title={intl.formatMessage({
              id: "app.needs.tabs.explore-strengths-and-needs",
            })}
            panel={
              surveyDeploymentId ? (
                <ExploreStrengthsAndNeedsTab
                  isPriorityConfirmed={isPriorityConfirmed}
                  surveyDeploymentId={surveyDeploymentId}
                  showRedBorder={showRedBorder}
                />
              ) : (
                noDataCallout
              )
            }
          />

          <Tab
            id={UserNotesType.SNA_DIGGING_DEEPER}
            title={intl.formatMessage({
              id: "app.needs.tabs.dig-deeper",
            })}
            panel={
              surveyDeploymentId ? (
                <DigDeeperTab
                  isPriorityConfirmed={isPriorityConfirmed}
                  isCnfBtnDisabledDisabled={isDisabled}
                  updateCnfBtnDisabledStatus={setIsDisabled}
                  setIsLoading={setIsLoading}
                />
              ) : (
                noDataCallout
              )
            }
          />

          {/* <Tab
            id={UserNotesType.SNA_PRIORITIZE_NEEDS}
            title={intl.formatMessage({
              id: "app.needs.tabs.prioritize-areas",
            })}
            panel={
              surveyDeploymentId ? (
                <PrioritizeAreasTab surveyDeploymentId={surveyDeploymentId} />
              ) : (
                noDataCallout
              )
            }
          /> */}
          <Tab
            id={UserNotesType.SNA_RESOURCES}
            title={intl.formatMessage({ id: "app.titles.resources" })}
            panel={
              <ActivityResourcesTab
                activityTag={ActivityTagEnum.NEEDS}
                userNotesType={UserNotesType.SNA_RESOURCES}
              />
            }
          />
          {selectedTabId === UserNotesType.SNA_DIGGING_DEEPER &&
            !isPriorityConfirmed && (
              <div className="m-w">
                <Popover
                  className=""
                  popoverClassName="w-56"
                  interactionKind={PopoverInteractionKind.HOVER}
                  content={
                    <div className="p-3 text-center">
                      {intl.formatMessage({
                        id: "app.dig-deeper.confirm.disable-popover",
                      })}
                    </div>
                  }
                  disabled={!isDisabled}
                >
                  <Button
                    className=""
                    disabled={isDisabled || isLoading}
                    text={intl.formatMessage({
                      id: "app.titles.confirm-prioritized-domain",
                    })}
                    intent="success"
                    onClick={confirmPriority}
                  ></Button>
                </Popover>
              </div>
            )}
        </Tabs>
        </div>
      </div>

      {!!mostRecentDeployment && (
        <BeforeBeginningDialog
          selectedSurveyDeployment={selectedSurveyDeployment}
          openBeforeBeginDialog={openBeforeBeginDialog} //this for open the  "Before Starting Strengths and Needs Analysis" modal
          OnopenBeforeBeginDialog={() => setOpenBeforeBeginDialog(false)} //this for close the  "Before Starting Strengths and Needs Analysis" modal
        />
      )}

      <ItemLevelInfoAndBookmarksDialog />

      {/* learning Module Dialog */}
      {/* <LearningModuleDialog /> */}

      {/* screen cast tutorial dialog */}
      {/* <ScreencastTutorialDialog /> */}
    </>
  );
};

export default NeedsPage;
