import React, { useMemo, useState } from "react";
import {
  District,
  Survey,
  SurveyDemographic,
  SurveyDeployment,
  SurveyDeploymentStateEnum,
} from "../../../../types";
import {
  Button,
  Divider,
  PopoverInteractionKind,
  Popover,
  Tag,
  PopoverPosition,
} from "@blueprintjs/core";
import SurveyLinkForm from "../../../surveys/forms/SurveyLinkForm";
import { useIntl } from "react-intl";
import useUserRole from "../../../../helpers/hooks/useUserRole";
import moment from "moment";
import { TableCell } from "@mui/material";

type Props = {
  surveyDeployment: SurveyDeployment<District, Survey<number>> & {
    availableDemographics?: SurveyDemographic[];
  };
  forTeamPlanView: undefined | boolean;
  onEditSurveyDeployment: () => void;
  onDeleteSurveyDeployment: (e: React.MouseEvent<HTMLElement>) => void;
  onGenerateReports: (e: React.MouseEvent<HTMLElement>) => void;
  onViewReports: (e: React.MouseEvent<HTMLElement>) => void;
  onDownloadLetters: (e: React.MouseEvent<HTMLElement>) => void;
  isManagedDeletedDataWorkspace?: boolean;
  isAdminOrDistrictActivePlan: boolean;
  isLatestSD:any;
};

export const SurveyDeploymentTableActions: React.FC<Props> = (props) => {
  
  const {
    surveyDeployment,
    onEditSurveyDeployment,
    onDeleteSurveyDeployment,
    onGenerateReports,
    onViewReports,
    onDownloadLetters,
    forTeamPlanView,
    isManagedDeletedDataWorkspace,
    isAdminOrDistrictActivePlan,
    isLatestSD
  } = props;

  const intl = useIntl();
  

  const {
    hasDataCoordinatorAccess,
    hasTeamChampionAccess,
    isTeamMember,
    isSuperintendent,
    isPrincipal,
  } = useUserRole();

  const getCurrentSession = () => {
    const SURVEY_MONTH: number = 6;
    const date = moment(new Date());

    if (parseInt(moment(date).format("M")) > SURVEY_MONTH) {
      return `${moment(date).format("YYYY")}-${moment(date)
        .add(1, "years")
        .format("YYYY")}`;
    } else {
      return `${moment(date).subtract(1, "year").format("YYYY")}-${moment(
        date
      ).format("YYYY")}`;
    }
  };

  const surveyDeploymentYear = surveyDeployment.deployment_name
    ? surveyDeployment.deployment_name.split(" ")[0]
    : "";

  const [isSdExpiredwithData, setIsSdExpiredwithData] = useState(false);
  const [isSdExpiredwithoutData, setIsSdExpiredwithoutData] = useState(false);

  const showGenerateReportsSection = useMemo(() => {
    if (isTeamMember) {
      return false;
    }

    if (parseInt(surveyDeploymentYear.split("-")[0]) < parseInt(getCurrentSession().split("-")[0])
      && surveyDeployment.state === SurveyDeploymentStateEnum.IN_PROGRESS
      && Object.values(surveyDeployment.responses).length
    ) {
      setIsSdExpiredwithData(true);
    }

    if (parseInt(surveyDeploymentYear.split("-")[0]) < parseInt(getCurrentSession().split("-")[0])
      && surveyDeployment.state === SurveyDeploymentStateEnum.IN_PROGRESS
      && !Object.values(surveyDeployment.responses).length
    ) {
      setIsSdExpiredwithoutData(true);
    }

    return (
      hasDataCoordinatorAccess &&
      [SurveyDeploymentStateEnum.IN_PROGRESS].includes(
        surveyDeployment.state!
      ) &&
      moment(surveyDeployment.start_date).isBefore()
    );
  }, [
    hasDataCoordinatorAccess,
    surveyDeployment.start_date,
    surveyDeployment.state,
    isTeamMember,
  ]);

  const hasEditAccess = useMemo(() => {
    return !forTeamPlanView || hasDataCoordinatorAccess;
  }, [forTeamPlanView, hasDataCoordinatorAccess]);

  const status = useMemo(() => {
    switch (surveyDeployment.state) {
      case SurveyDeploymentStateEnum.READY:
      case SurveyDeploymentStateEnum.IN_PROGRESS:
        return null;

      case SurveyDeploymentStateEnum.GENERATING_DATASETS:
      case SurveyDeploymentStateEnum.QUEUED:
        return !isAdminOrDistrictActivePlan ? (
          <TableCell align="left"> 
              <button className="btn btn-primary"> {intl.formatMessage({
              id: "app.surveys.table.actions.view-reports.generating_datasets",
            })}</button> 
            </TableCell>
           
        ) : null;

      case SurveyDeploymentStateEnum.SCHEDULED:
      default:
        return !isAdminOrDistrictActivePlan ? (
          hasDataCoordinatorAccess ? (
            <Popover
              popoverClassName={"w-20rem"}
              interactionKind="hover"
              content={
                <div className="p-2">
                  {!Object.values(surveyDeployment.responses).length
                    ? intl.formatMessage({
                      id:
                        "app.surveys-deployment-table.columns.actions.disable.generate-reports.info",
                    })
                    : intl.formatMessage({
                      id:
                        "app.surveys-deployment-table.columns.actions.generate-reports.info",
                    })}
                </div>
              }
              position={PopoverPosition.LEFT}
            >
            <button
            className="btn btn-secondary"
            disabled={!Object.values(surveyDeployment.responses).length}
            onClick={onGenerateReports}
            
            >{intl.formatMessage({
              id:
                "app.surveys-deployment-table.columns.actions.generate-reports",
            })}</button>
              
            </Popover>
          ) : (
            <Popover
              popoverClassName="w-48"
              interactionKind={PopoverInteractionKind.HOVER}
              content={
                <div className="p-3">
                  {intl.formatMessage({
                    id: "app.survey-deployment.veiw-report.disable-popover",
                  })}
                </div>
              }
            >
              <Tag className="self-center" large>
                {intl.formatMessage({
                  id: "app.titles.view-reports",
                })}
              </Tag>
            </Popover>
          )
        ) : null;
    }
  }, [surveyDeployment.state]);

  const showEditAndLinksButtons = useMemo(() => {
    switch (surveyDeployment.state) {
      case SurveyDeploymentStateEnum.QUEUED:
      case SurveyDeploymentStateEnum.GENERATING_DATASETS:
      case SurveyDeploymentStateEnum.READY:
        return false;
      default:
        return true;
    }
  }, [surveyDeployment.state]);

  const deleteButton = useMemo(
    () => (
      <ul className="table-icon-list"> 
                <li>
                  <button
                   title={intl.formatMessage({
                        id: "app.surveys-table.columns.delete",
                      })}
                  onClick={onDeleteSurveyDeployment}
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19" fill="none">
                  <path d="M1.08701 2.73075C0.731356 2.73075 0.442915 3.01937 0.442915 3.37525C0.442915 3.73131 0.731356 4.01975 1.08701 4.01975H1.096C1.16717 4.01975 1.22701 4.07622 1.2297 4.14923L1.69336 16.6778C1.72733 17.5924 2.4913 18.3278 3.40569 18.3278H11.6501C12.5649 18.3278 13.3285 17.5912 13.3625 16.6778L13.8261 4.14923C13.8288 4.07784 13.887 4.01975 13.9598 4.01975H13.9688C14.3245 4.01975 14.6129 3.73131 14.6129 3.37525C14.6129 3.01937 14.3245 2.73075 13.9688 2.73075H10.8287C10.7579 2.73075 10.6844 2.67447 10.6653 2.60775L10.4554 1.87224C10.2399 1.11767 9.47379 0.539551 8.68932 0.539551H6.36634C5.58188 0.539551 4.81595 1.11753 4.60027 1.87224L4.39037 2.60775C4.37096 2.67573 4.29853 2.73075 4.22701 2.73075H1.08701ZM8.04326 6.34004V15.1052C8.04326 15.3901 7.81269 15.6208 7.52803 15.6208C7.24337 15.6208 7.0128 15.3901 7.0128 15.1052V6.34004C7.0128 6.0552 7.24337 5.82449 7.52803 5.82449C7.81269 5.82449 8.04326 6.05521 8.04326 6.34004ZM10.8772 6.35515L10.6195 15.1203C10.611 15.405 10.3738 15.6291 10.0893 15.6206C9.80486 15.6123 9.58092 15.3748 9.58939 15.0901L9.8471 6.32495C9.85537 6.04028 10.0928 5.81621 10.3772 5.82468C10.6617 5.83295 10.8855 6.0705 10.8772 6.35515ZM5.20914 6.32494L5.46685 15.0901C5.4753 15.3748 5.25138 15.6124 4.9669 15.6206C4.68241 15.6291 4.44518 15.405 4.43676 15.1203L4.17905 6.35514C4.17078 6.07047 4.39452 5.83291 4.679 5.82467C4.96349 5.81622 5.20091 6.04028 5.20914 6.32494ZM9.23061 2.73075H5.82573C5.75276 2.73075 5.71089 2.67608 5.73012 2.60865L5.83921 2.22633C5.89672 2.02511 6.1573 1.82856 6.36668 1.82856H8.68966C8.89903 1.82856 9.15962 2.02529 9.21713 2.22633L9.32621 2.60865C9.34562 2.6768 9.30267 2.73075 9.23061 2.73075Z" fill="#195A94"/>
                  </svg></button>
                </li>
              </ul>
      
    ),
    [intl, onDeleteSurveyDeployment]
  );

  const section = useMemo(() => {
    if (showGenerateReportsSection && !isAdminOrDistrictActivePlan) {
      return (
        <Popover
          popoverClassName={"w-20rem"}
          interactionKind={"hover"}
          content={
            <div className="p-2">
              {!Object.values(surveyDeployment.responses).length
                ? intl.formatMessage({
                  id:
                    "app.surveys-deployment-table.columns.actions.disable.generate-reports.info",
                })
                : intl.formatMessage({
                  id:
                    "app.surveys-deployment-table.columns.actions.generate-reports.info",
                })}
            </div>
          }
          position={PopoverPosition.LEFT}
        >
           <button
             disabled={!Object.values(surveyDeployment.responses).length}
            className="btn btn-secondary"
            onClick={onGenerateReports}
            title={intl.formatMessage({
              id:
                "app.surveys-deployment-table.columns.actions.generate-reports",
            })}
            >{intl.formatMessage({
              id:
                "app.surveys-deployment-table.columns.actions.generate-reports",
            })}</button>
        </Popover>
      );
    }


    if (surveyDeployment.state === SurveyDeploymentStateEnum.READY && Object.values(surveyDeployment?.responses)?.length > 0) {
   
      return (
        <button 
        id={isLatestSD && "latest-report-button"}
        className="btn btn-secondary"
        onClick={onViewReports}
        title={intl.formatMessage({
          id: "app.titles.view-reports",
        })}
        >{intl.formatMessage({
          id: "app.titles.view-reports",
        })}</button>
      
      );
    } else if (
      isAdminOrDistrictActivePlan ||
      isTeamMember ||
      !hasDataCoordinatorAccess
    ) {
      return (
        <Popover
          popoverClassName="w-48"
          interactionKind={PopoverInteractionKind.HOVER}
          content={
            <div className="p-3">
              {intl.formatMessage({
                id: "app.survey-deployment.veiw-report.disable-popover",
              })}
            </div>
          }
        >
          <button 
        id={isLatestSD && "latest-report-button"}
        className="btn btn-secondary opacity-25"
        // onClick={onViewReports}
        disabled
        >{intl.formatMessage({
          id: "app.titles.view-reports",
        })}</button>
        </Popover>
      );
    }
    return null;
  }, [showGenerateReportsSection, surveyDeployment.state]);

  //adding !hasDataCoordinatorAccess to check if the current user is in the school team this flag will contain true otherwise undefined or false
  if ((!isSuperintendent && isTeamMember) || !hasDataCoordinatorAccess) {
    return status ?? section;
  }

  return (
    <div className={"flex flex-col gap-1"}>
       
      <div className="flex gap-1">
       

        {showEditAndLinksButtons &&
          !isAdminOrDistrictActivePlan &&
          !isManagedDeletedDataWorkspace && (
            (isSdExpiredwithoutData || isSdExpiredwithData) ?
              <ul className="table-icon-list">
                <li><button
                 title={intl.formatMessage({
                  id: "app.surveys-deployment-table.columns.actions.link",
                })}
                disabled
                
                ><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path d="M4.80854 7.31447C4.92378 7.1908 4.98653 7.02721 4.98354 6.85819C4.98056 6.68917 4.91209 6.5279 4.79255 6.40836C4.67302 6.28883 4.51175 6.22035 4.34273 6.21737C4.1737 6.21439 4.01012 6.27713 3.88644 6.39237L1.4942 8.78462C1.07262 9.18186 0.735027 9.65965 0.501416 10.1897C0.267805 10.7198 0.142922 11.2913 0.134162 11.8705C0.125402 12.4497 0.232943 13.0247 0.450416 13.5616C0.66789 14.0985 0.990876 14.5863 1.40025 14.9961C1.80962 15.4059 2.29707 15.7294 2.83371 15.9474C3.37036 16.1655 3.9453 16.2736 4.52449 16.2655C5.10369 16.2574 5.67536 16.1331 6.20566 15.9C6.73597 15.667 7.21412 15.3299 7.61181 14.9088L10.0041 12.5165C10.1193 12.3928 10.182 12.2293 10.1791 12.0602C10.1761 11.8912 10.1076 11.7299 9.98806 11.6104C9.86853 11.4909 9.70726 11.4224 9.53824 11.4194C9.36921 11.4164 9.20563 11.4792 9.08195 11.5944L6.68971 13.9867C6.11991 14.5416 5.35447 14.8498 4.55909 14.8446C3.76371 14.8393 3.0024 14.5211 2.43997 13.9586C1.87754 13.3962 1.55925 12.6349 1.55401 11.8395C1.54876 11.0441 1.85699 10.2787 2.41195 9.70889L4.80854 7.31447Z" fill="#195A94"/>
                  <path d="M14.7951 1.60355C13.9825 0.793052 12.8817 0.337891 11.7341 0.337891C10.5864 0.337891 9.48563 0.793052 8.67311 1.60355L6.28087 3.99579C6.21676 4.05552 6.16535 4.12755 6.12969 4.20758C6.09403 4.28761 6.07486 4.374 6.07331 4.4616C6.07177 4.54921 6.08788 4.63622 6.1207 4.71746C6.15351 4.7987 6.20235 4.8725 6.2643 4.93445C6.32626 4.9964 6.40005 5.04524 6.48129 5.07806C6.56253 5.11087 6.64955 5.12699 6.73715 5.12544C6.82475 5.1239 6.91115 5.10472 6.99118 5.06906C7.07121 5.0334 7.14324 4.98199 7.20297 4.91789L9.59521 2.52565C10.165 1.97068 10.9304 1.66246 11.7258 1.66771C12.5212 1.67295 13.2825 1.99124 13.8449 2.55367C14.4074 3.1161 14.7257 3.87741 14.7309 4.67279C14.7362 5.46817 14.4279 6.23361 13.873 6.80341L11.4807 9.19565C11.4166 9.25538 11.3652 9.32741 11.3296 9.40744C11.2939 9.48747 11.2747 9.57386 11.2732 9.66147C11.2716 9.74907 11.2877 9.83608 11.3206 9.91732C11.3534 9.99856 11.4022 10.0724 11.4642 10.1343C11.5261 10.1963 11.5999 10.2451 11.6812 10.2779C11.7624 10.3107 11.8494 10.3268 11.937 10.3253C12.0246 10.3238 12.111 10.3046 12.191 10.2689C12.2711 10.2333 12.3431 10.1819 12.4028 10.1178L14.7951 7.72551C15.6056 6.91298 16.0607 5.81218 16.0607 4.66453C16.0607 3.51688 15.6056 2.41607 14.7951 1.60355Z" fill="#195A94"/>
                  <path d="M6.1982 11.1225L11.0088 6.31191C11.124 6.18823 11.1868 6.02465 11.1838 5.85563C11.1808 5.6866 11.1123 5.52533 10.9928 5.4058C10.8733 5.28626 10.712 5.21779 10.543 5.21481C10.3739 5.21183 10.2104 5.27457 10.0867 5.38981L5.2761 10.2004C5.212 10.2601 5.16059 10.3321 5.12493 10.4122C5.08927 10.4922 5.0701 10.5786 5.06855 10.6662C5.06701 10.7538 5.08312 10.8408 5.11593 10.9221C5.14875 11.0033 5.19759 11.0771 5.25954 11.1391C5.3215 11.201 5.39529 11.2498 5.47653 11.2827C5.55777 11.3155 5.64479 11.3316 5.73239 11.33C5.81999 11.3285 5.90638 11.3093 5.98642 11.2737C6.06645 11.238 6.13848 11.1866 6.1982 11.1225Z" fill="#195A94"/>
                  </svg></button>
                </li></ul>
              :
              <Popover
                popoverClassName={"popover-container-min-width"}
                content={
                  <SurveyLinkForm
                    forTeamPlanView={forTeamPlanView}
                    districts={surveyDeployment.districts}
                    availableDemographics={surveyDeployment.availableDemographics}
                    surveyHashMap={{
                      [SurveyDemographic.ElementaryStudents]:
                        surveyDeployment.elementary_student_hash,
                      [SurveyDemographic.Students]: surveyDeployment.student_hash,
                      [SurveyDemographic.SchoolStaff]:
                        surveyDeployment.school_staff_hash,
                      [SurveyDemographic.Parents]: surveyDeployment.parent_hash,
                    }}
                  />
                }
                target={
                 <ul className="table-icon-list">
                <li><button
                 title={intl.formatMessage({
                  id: "app.surveys-deployment-table.columns.actions.link",
                })}
                ><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path d="M4.80854 7.31447C4.92378 7.1908 4.98653 7.02721 4.98354 6.85819C4.98056 6.68917 4.91209 6.5279 4.79255 6.40836C4.67302 6.28883 4.51175 6.22035 4.34273 6.21737C4.1737 6.21439 4.01012 6.27713 3.88644 6.39237L1.4942 8.78462C1.07262 9.18186 0.735027 9.65965 0.501416 10.1897C0.267805 10.7198 0.142922 11.2913 0.134162 11.8705C0.125402 12.4497 0.232943 13.0247 0.450416 13.5616C0.66789 14.0985 0.990876 14.5863 1.40025 14.9961C1.80962 15.4059 2.29707 15.7294 2.83371 15.9474C3.37036 16.1655 3.9453 16.2736 4.52449 16.2655C5.10369 16.2574 5.67536 16.1331 6.20566 15.9C6.73597 15.667 7.21412 15.3299 7.61181 14.9088L10.0041 12.5165C10.1193 12.3928 10.182 12.2293 10.1791 12.0602C10.1761 11.8912 10.1076 11.7299 9.98806 11.6104C9.86853 11.4909 9.70726 11.4224 9.53824 11.4194C9.36921 11.4164 9.20563 11.4792 9.08195 11.5944L6.68971 13.9867C6.11991 14.5416 5.35447 14.8498 4.55909 14.8446C3.76371 14.8393 3.0024 14.5211 2.43997 13.9586C1.87754 13.3962 1.55925 12.6349 1.55401 11.8395C1.54876 11.0441 1.85699 10.2787 2.41195 9.70889L4.80854 7.31447Z" fill="#195A94"/>
                  <path d="M14.7951 1.60355C13.9825 0.793052 12.8817 0.337891 11.7341 0.337891C10.5864 0.337891 9.48563 0.793052 8.67311 1.60355L6.28087 3.99579C6.21676 4.05552 6.16535 4.12755 6.12969 4.20758C6.09403 4.28761 6.07486 4.374 6.07331 4.4616C6.07177 4.54921 6.08788 4.63622 6.1207 4.71746C6.15351 4.7987 6.20235 4.8725 6.2643 4.93445C6.32626 4.9964 6.40005 5.04524 6.48129 5.07806C6.56253 5.11087 6.64955 5.12699 6.73715 5.12544C6.82475 5.1239 6.91115 5.10472 6.99118 5.06906C7.07121 5.0334 7.14324 4.98199 7.20297 4.91789L9.59521 2.52565C10.165 1.97068 10.9304 1.66246 11.7258 1.66771C12.5212 1.67295 13.2825 1.99124 13.8449 2.55367C14.4074 3.1161 14.7257 3.87741 14.7309 4.67279C14.7362 5.46817 14.4279 6.23361 13.873 6.80341L11.4807 9.19565C11.4166 9.25538 11.3652 9.32741 11.3296 9.40744C11.2939 9.48747 11.2747 9.57386 11.2732 9.66147C11.2716 9.74907 11.2877 9.83608 11.3206 9.91732C11.3534 9.99856 11.4022 10.0724 11.4642 10.1343C11.5261 10.1963 11.5999 10.2451 11.6812 10.2779C11.7624 10.3107 11.8494 10.3268 11.937 10.3253C12.0246 10.3238 12.111 10.3046 12.191 10.2689C12.2711 10.2333 12.3431 10.1819 12.4028 10.1178L14.7951 7.72551C15.6056 6.91298 16.0607 5.81218 16.0607 4.66453C16.0607 3.51688 15.6056 2.41607 14.7951 1.60355Z" fill="#195A94"/>
                  <path d="M6.1982 11.1225L11.0088 6.31191C11.124 6.18823 11.1868 6.02465 11.1838 5.85563C11.1808 5.6866 11.1123 5.52533 10.9928 5.4058C10.8733 5.28626 10.712 5.21779 10.543 5.21481C10.3739 5.21183 10.2104 5.27457 10.0867 5.38981L5.2761 10.2004C5.212 10.2601 5.16059 10.3321 5.12493 10.4122C5.08927 10.4922 5.0701 10.5786 5.06855 10.6662C5.06701 10.7538 5.08312 10.8408 5.11593 10.9221C5.14875 11.0033 5.19759 11.0771 5.25954 11.1391C5.3215 11.201 5.39529 11.2498 5.47653 11.2827C5.55777 11.3155 5.64479 11.3316 5.73239 11.33C5.81999 11.3285 5.90638 11.3093 5.98642 11.2737C6.06645 11.238 6.13848 11.1866 6.1982 11.1225Z" fill="#195A94"/>
                  </svg></button>
                </li></ul>
                }
              />
          )}
          
        {hasEditAccess &&
          !isAdminOrDistrictActivePlan &&
          showEditAndLinksButtons &&
          !isManagedDeletedDataWorkspace && (
            <ul className="table-icon-list">

            <li>
              <button
               title={intl.formatMessage({
                    id: "app.surveys-deployment-table.columns.actions.edit",
                  })}
                   onClick={onEditSurveyDeployment}
              disabled={(isSdExpiredwithoutData || isSdExpiredwithData) ? true : false}  
              ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path d="M0.906494 14.739L4.72242 13.8963L1.74895 10.923L0.906494 14.739Z" fill="#195A94"/>
            <path d="M14.5978 4.61302C15.0616 4.15031 15.3172 3.53459 15.3176 2.87943C15.3179 2.22431 15.063 1.60823 14.5996 1.14498L14.5016 1.04677C14.0388 0.583879 13.4233 0.328949 12.7685 0.328949H12.7673C12.1122 0.329299 11.4964 0.584905 11.0335 1.0487L2.15668 9.9439L5.70243 13.4897L14.5978 4.61302Z" fill="#195A94"/>
            </svg></button>
          </li>
          </ul>
            
          )}

        {forTeamPlanView &&
          showEditAndLinksButtons &&
          !isAdminOrDistrictActivePlan &&
          !isManagedDeletedDataWorkspace && (
            <ul className="table-icon-list">
                 
            <li>
            <button className="download-sample-letter"
              title={intl.formatMessage({
                id:
                  "app.surveys-deployment-table.columns.actions.download-letters",
              })}
              onClick={onDownloadLetters}
              disabled={(isSdExpiredwithoutData || isSdExpiredwithData) ? true : false}
            ><svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                  <path d="M14.9312 8.33221C14.6172 8.01825 14.1342 8.01825 13.8203 8.33221L9.64223 12.5102V1.01462C9.64223 0.579908 9.27998 0.217651 8.84527 0.217651C8.41056 0.217651 8.0483 0.579908 8.0483 1.01462V12.5102L3.89442 8.33221C3.58047 8.01825 3.09746 8.01825 2.7835 8.33221C2.46955 8.64617 2.46955 9.12918 2.7835 9.44313L8.31396 14.9736C8.38641 15.046 8.48301 15.0943 8.57961 15.1426C8.65206 15.1909 8.74867 15.1909 8.84527 15.1909C8.94187 15.1909 9.03847 15.1668 9.13507 15.1185C9.23167 15.0702 9.32828 15.0219 9.40073 14.9494L14.9312 9.41898C15.221 9.15333 15.221 8.64617 14.9312 8.33221Z" fill="#195A94"/>
                  <path d="M16.4045 19.5375H1.28635C0.851637 19.5375 0.48938 19.1753 0.48938 18.7406V13.6207C0.48938 13.186 0.851637 12.8237 1.28635 12.8237C1.72105 12.8237 2.08331 13.186 2.08331 13.6207V17.9678H15.6076V13.6207C15.6076 13.186 15.9698 12.8237 16.4045 12.8237C16.8392 12.8237 17.2015 13.186 17.2015 13.6207V18.7406C17.1773 19.1753 16.8392 19.5375 16.4045 19.5375Z" fill="#195A94"/>
                  </svg>    
                    {intl.formatMessage({id:"app.surveys-deployment-table.columns.actions.download-sample-letters",})}
                  </button>
                  </li>
                </ul>
            )}

       

        {hasEditAccess && (
          <>
            {(isSuperintendent || isPrincipal) && !isAdminOrDistrictActivePlan ? (
              <>
                {forTeamPlanView && !isManagedDeletedDataWorkspace && (
                  <Popover
                    popoverClassName={"w-25"}
                    interactionKind={"hover"}
                    content={
                      <div className={"p-2"}>
                        {intl.formatMessage({
                          id: "app.surveys-table.columns.delete.info",
                        })}
                      </div>
                    }
                    target={deleteButton}
                  />
                )}
              </>
            ) : (
              isManagedDeletedDataWorkspace && deleteButton
            )}

            {section && !isManagedDeletedDataWorkspace && (
              <>
                {((hasEditAccess &&
                  ((isSuperintendent && !isAdminOrDistrictActivePlan) ||
                    showEditAndLinksButtons)) ||
                  showEditAndLinksButtons) &&
                  !isAdminOrDistrictActivePlan }
                {section}
              </>
            )}
           
          </>
        )}
        {status}
      </div>
      {
        (isSdExpiredwithoutData || isSdExpiredwithData) && (
          <p className="expired-msg">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 18 15" fill="none">
                                    <path d="M17.4721 13.3279C17.2256 12.9407 16.9968 12.5534 16.7504 12.1662C14.4446 8.48745 12.1563 4.80869 9.85051 1.12993C9.49848 0.566675 8.77681 0.566675 8.42477 1.12993C6.52379 4.21023 4.6052 7.27293 2.68661 10.3532C2.07055 11.3389 1.45449 12.3246 0.838435 13.2927C0.732824 13.4687 0.662418 13.6623 0.715223 13.8736C0.803231 14.2256 1.10246 14.4368 1.5073 14.4368C4.05955 14.4368 6.59419 14.4368 9.14644 14.4368C11.6811 14.4368 14.2333 14.4368 16.768 14.4368C17.4368 14.4368 17.8065 13.856 17.4721 13.3279ZM8.61839 7.92419C8.61839 7.39614 8.61839 6.88569 8.61839 6.35764C8.61839 5.9528 8.82961 5.67117 9.16404 5.65357C9.42807 5.65357 9.67449 5.9528 9.67449 6.28723C9.67449 6.32244 9.67449 6.35764 9.67449 6.39284C9.67449 7.44894 9.67449 8.48745 9.67449 9.54355C9.67449 10.0364 9.26965 10.3708 8.93522 10.1596C8.724 10.0364 8.61839 9.80757 8.61839 9.49074C8.61839 8.96269 8.61839 8.43464 8.61839 7.92419ZM9.14644 12.1486C8.84721 12.1486 8.61839 11.9198 8.61839 11.6206C8.61839 11.3389 8.84721 11.0925 9.14644 11.0925C9.44567 11.0925 9.67449 11.3213 9.67449 11.6206C9.67449 11.9198 9.44567 12.1486 9.14644 12.1486Z" fill="#F3A345"/>
                                  </svg>
                                  {intl.formatMessage({id:"app.surveys-deployment-table.columns.actions.expire-deployment-warning"})}
                                </p>
        )
      }
    </div>
  );
};