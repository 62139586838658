import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { Button } from "@blueprintjs/core";
import moment from "moment/moment";
import { UserActionAudit } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { getUserAllActionAudit } from "../../../store/users/actions";

type OwnProps = {
  actionAudit: UserActionAudit[];
};

type Props = OwnProps;

const TERMS_COLUMNS =
  '["Date of Action","Time of Action","District", "District/School Role","Action Type", "Target(s)"]';

const DownloadActionAudit: React.FC<Props> = ({ actionAudit }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const actionAuditlogAll = useSelector((s)=>s.users.auditActionDataAll?.list) as UserActionAudit[]
  const actionAuditlogAllLoading = useSelector((s)=>s.users.loading.getUserAllActionAudit)



  const handleCSVDownloadClick = useCallback(() => {
    dispatch(getUserAllActionAudit.request({list:[],totalcount:0}))

   if(!actionAuditlogAllLoading) {
      const csvStr = [
      TERMS_COLUMNS,
      ...actionAuditlogAll?.map((ua: UserActionAudit) => {
        const formattedObject = {
          date: moment(ua.created_at).format("MM/DD/YYYY"),
          time: moment(ua.created_at).format("HH:mm"),
          district: ua.district,
          district_school_role: ua.role,
          action_type: ua.action_type,
          target: ua.targets,
        };
        return JSON.stringify(Object.values(formattedObject));
      }),
    ]
      .join("\n")
      .replace(/(^\[)|(\]$)/gm, "");
    const blob = new Blob([csvStr], { type: "text/csv;charset=utf-8;" });
    const csvUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute(
      "download",
      `Action Audit${moment().format("MM/DD/YYYY")}`
    );
    link.href = csvUrl;
    document.body.append(link);
    link.click();
    link.remove();
  }
  }, [actionAuditlogAllLoading]);

  return (
    <Button
      text={intl.formatMessage({ id: "app.titles.download-as-CSV" })}
      title={intl.formatMessage({ id: "app.titles.download-as-CSV" })}
      intent="primary"
      onClick={handleCSVDownloadClick}
      loading={actionAuditlogAllLoading}
    />
  );
};

export default DownloadActionAudit;
