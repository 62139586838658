import { createAction, createAsyncAction } from "typesafe-actions";
import { DomainPriority, Goal, GoalActivityLog, NewGoalState, Priority, PageSizeNumber, GoalActivityLogAll } from "../../types";
import { AddGoalRequest, UpdateGoalRequest, DeleteGoalRequest, UpdateGoalIndicatorRequest, PusherEvent } from "../../api/goals/types";
import { GoalsState } from "./reducers";

export const resetNewGoalState = createAction(
  "@goals/RESET_NEW_GOAL_STATE"
)<void>();


export const switchActiveGoalTab = createAction("@goals/SWITCH_ACTIVE_GOAL_TAB")<any>();
export const removeDomainFromGoal = createAction("@goals/REMOVE_DOMAIN_TO_GOAL")<any>();

export const addDomainToGoal = createAction("@goals/ADD_DOMAIN_TO_GOAL")<
  any
>();


export const updateNewGoalState = createAction("@goals/UPDATE_NEW_GOAL_STATE")<
  Partial<NewGoalState>
>();

export const updateNewGoalStatementsState = createAction("@goals/UPDATE_NEW_GOAL_STATEMENT_STATE")<
Partial<NewGoalState>
>();

export const updateIndicatorStatus = createAction("@goals/UPDATE_CONF_IND_STATUS")<
  Partial<any[]>
>();

export const updateNewGoalErrorsState = createAction(
  "@goals/UPDATE_NEW_GOAL_STATE_ERROR"
)<Partial<NewGoalState>>();

export const updateSelectedPriorityState = createAction(
  "@goals/UPDATE_SELECTED_PRIORITY_STATE"
)<{ selectedPriority: DomainPriority[] }>();

export const loadIndicators = createAction("@goals/LOAD_INDICATORS")<any[]>();

export const loadFinalIndicators = createAction("@goals/LOAD_FINAL_INDICATORS")<
  any[]
>();

// action to set selected priorities in redux
export const selectedPriorityAreas = createAction(
  "@goals/SELECTED_PRIORITY_AREAS"
)<Priority[]>();

export const getGoalByGoalId = createAsyncAction(
  "@goals/GET_GOAL_REQUEST",
  "@goals/GET_GOAL_SUCCESS",
  "@goals/GET_GOAL_FAILURE"
)<{planId:number; goalId:number;}, Goal, Error>();

export const getGoalsByPlan = createAsyncAction(
  "@goals/GET_GOALS_REQUEST",
  "@goals/GET_GOALS_SUCCESS",
  "@goals/GET_GOALS_FAILURE"
)<number, Goal[], Error>();

export const createGoal = createAsyncAction(
  "@goals/CREATE_GOAL_REQUEST",
  "@goals/CREATE_GOAL_SUCCESS",
  "@goals/CREATE_GOAL_FAILURE"
)<AddGoalRequest, Goal, Error>();

export const updateGoal = createAsyncAction(
  "@goals/UPDATE_GOAL_REQUEST",
  "@goals/UPDATE_GOAL_SUCCESS",
  "@goals/UPDATE_GOAL_FAILURE"
)<UpdateGoalRequest, Goal, Error>();

export const pusherNewGoalEvent = createAction(
  "@goals/PUSHER_NEW_GOAL_EVENT"
)<any>();

export const pusherUpdateGoalEvent = createAction(
  "@goals/PUSHER_UPDATE_GOAL_EVENT"
)<any>();

export const pusherDeleteGoalEvent = createAction(
  "@goals/PUSHER_DELETE_GOAL_EVENT"
)<any>();
// trigger pusher event
export const triggerPusherEvent = createAsyncAction(
  "@goals/TRIGGER_PUSHER_EVENT_REQUEST",
  "@goals/TRIGGER_PUSHER_EVENT_SUCCESS",
  "@goals/TRIGGER_PUSHER_EVENT_FAILURE"
)<PusherEvent, any, Error>(); // need to set any because any type of data can be triggered from this action

//add domain and item indicator
export const addDomainItemIndicator = createAsyncAction(
  "@goals/ADD_GOAL_DOMAIN_ITEM_INDICATOR_REQUEST",
  "@goals/ADD_GOAL_DOMAIN_ITEM_INDICATOR_SUCCESS",
  "@goals/ADD_GOAL_DOMAIN_ITEM_INDICATOR_FAILURE"
)<any, any, Error>();

//add domain item indicator - PUSHER


// goal_target_create

// update goal indicator
export const updateGoalIndicator = createAsyncAction(
  "@goals/UPDATE_GOAL_INDICATOR_REQUEST",
  "@goals/UPDATE_GOAL_INDICATOR_SUCCESS",
  "@goals/UPDATE_GOAL_INDICATOR_FAILURE"
)<any, any, Error>();

// delete goal domain
export const deleteGoalDomain = createAsyncAction(
  "@goals/DELETE_GOAL_DOMAIN_REQUEST",
  "@goals/DELETE_GOAL_DOMAIN_SUCCESS",
  "@goals/DELETE_GOAL_DOMAIN_FAILURE"
)<{ domain_id: number, goal_id: number, plan_id: number|undefined }, any, Error>();

// delete goal indicator
export const deleteGoalIndicator = createAsyncAction(
  "@goals/DELETE_GOAL_INDICATOR_REQUEST",
  "@goals/DELETE_GOAL_INDICATOR_SUCCESS",
  "@goals/DELETE_GOAL_INDICATOR_FAILURE"
)<{ id: number }, any, Error>();

export const deleteGoal = createAsyncAction(
  "@goals/DELETE_GOAL_REQUEST",
  "@goals/DELETE_GOAL_SUCCESS",
  "@goals/DELETE_GOAL_FAILURE"
)<{ id: number; planId: number | undefined }, number, Error>();


export const getGoalActivityLog = createAsyncAction(
  "@goals/GET_GOALSACTIVITY_REQUEST",
  "@goals/GET_GOALSACTIVITY_SUCCESS",
  "@goals/GET_GOALSACTIVITY_FAILURE"
)<number, GoalActivityLog[], Error>();

export const getGoalActivityAllLog = createAsyncAction(
  "@goals/GET_GOALSACTIVITY_ALL_REQUEST",
  "@goals/GET_GOALSACTIVITY_ALL_SUCCESS",
  "@goals/GET_GOALSACTIVITY_ALL_FAILURE"
)<PageSizeNumber, GoalActivityLogAll, Error>();

export const getAllGoalActivityLogs = createAsyncAction(
  "@goals/GET_ALL_GOALSACTIVITY_REQUEST",
  "@goals/GET_ALL_GOALSACTIVITY_SUCCESS",
  "@goals/GET_ALL_GOALSACTIVITY_FAILURE"
)<GoalActivityLogAll, GoalActivityLogAll, Error>();


export const showGoalTemplateLibraryDialog = createAction(
  "@goals/SHOW_GOAL_TEMPLATE_LIBRARY_DIALOG"
)<{ goalStatement: string; desiredOutcome: string; onConfirm?: Function }>();

export const hideGoalTemplateLibraryDialog = createAction(
  "@goals/HIDE_GOAL_TEMPLATE_LIBRARY_DIALOG"
)<void>();
