import React, { FunctionComponent, useCallback, useMemo } from "react";
import {
  Button,
  Icon,
  NonIdealState,
  Popover,
  PopoverInteractionKind,
  PopoverPosition,
  Spinner,
  Tooltip,
} from "@blueprintjs/core";
import { HeaderGroup, Row, TableBodyProps, TableProps } from "react-table";
import { useIntl } from "react-intl";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Checkbox from "@mui/material/Checkbox";
// import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
type Props = {
  getTableProps: () => TableProps;
  getTableBodyProps: () => TableBodyProps;
  onClearSearchString: () => void;
  prepareRow: (row: Row<any>) => void;
  headerGroups: HeaderGroup<any>[];
  page: any;
  interactive?: boolean;
  tableBackgroundColorClassName?: string;
  bordered?: boolean;
  striped?: boolean;
  fixed?: boolean;
  onRowClick?: (entity: any) => any;
  loading?: boolean;
  filtered?: boolean;
  noDataComp?: React.ReactNode;
  getTooltipMessage?: (entity: any) => string | undefined;
  id?:string;
  disableSort?:boolean;
};

const GenericTable: FunctionComponent<Props> = (props) => {
  const {
    getTableProps,
    getTableBodyProps,
    onClearSearchString,
    prepareRow,
    headerGroups,
    page,
    interactive,
    tableBackgroundColorClassName,
    bordered,
    striped,
    onRowClick,
    fixed = true,
    loading,
    filtered,
    noDataComp,
    getTooltipMessage,
    id,
    disableSort
  } = props;

  const intl = useIntl();

  const colspan = useMemo(() => {
    return headerGroups.flatMap((hg) => hg.headers).length;
  }, [headerGroups]);

  const isStickyHeader = useMemo(() => {
    return headerGroups.every((hg) => hg.headers.some((h: any) => h.sticky));
  }, [headerGroups]);

  const renderRow = useCallback(
    (row) => (
      <tr {...row.getRowProps()}>
        {row.cells.map((cell: any) => {
          return (
            <td
              {...cell.getCellProps()}
              onClick={onRowClick ? () => onRowClick(row.original) : undefined}
            >
              {cell.render("Cell")}
            </td>
          );
        })}
      </tr>
    ),
    [onRowClick]
  );

  return (
    <TableContainer className="memberGrid" component={Paper}>
      <Table aria-label="simple table"
      className={`bp3-html-table bp3-html-table-condensed ${
        striped ? "bp3-html-table-striped" : ""
      } ${bordered ? "bp3-html-table-bordered border border-gray-400" : ""} ${
        tableBackgroundColorClassName ? tableBackgroundColorClassName : ""
      } ${fixed ? "table-fixed" : ""} w-full ${
        interactive ? "bp3-interactive" : ""
      }`}
      {...getTableProps()}
      id={id}
    >
    <TableHead
        style={
          isStickyHeader
            ? {
                position: "sticky",
                zIndex: 20,
                top: 0,
                boxShadow: "0px 3px 3px #ccc",
                background: "white",
              }
            : undefined
        }
      >
        {headerGroups.map((headerGroup: any) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
                <TableCell
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  minWidth: column.minWidth,
                  width: column.width,
                }}
              >
                <div
                  className={`flex justify-start items-center ${
                    column.wrap ? "" : "whitespace-no-wrap"
                  }`}
                >
                  {column.render("Header") ===
                  intl.formatMessage({
                    id: "app.titles.role-can-access-school-district-plan",
                  }) &&(
                    <>
                      {" "}
                      <Popover
                        popoverClassName="w-56"
                        position={PopoverPosition.BOTTOM}
                        interactionKind={PopoverInteractionKind.HOVER}
                        content={
                          <div className="p-3 text-center">
                            {intl.formatMessage({
                              id:
                                "app.titles.role-can-access-school-district-plan-hover-message",
                            })}
                          </div>
                        }
                      >
                        {column.render("Header")}
                      </Popover>
                    </>
                  ) || (
                    column.render("Header")
                  )}
                 {(disableSort !== true) && <Icon
                    className={
                      column.canSort && !column.isSorted
                        ? "ml-2 opacity-25"
                        : "ml-2"
                    }
                    icon={
                      column.canSort
                        ? column.isSorted
                          ? column.isSortedDesc
                            ? "sort-desc"
                            : "sort-asc"
                          : "sort"
                        : undefined
                    }
                  />}
                </div>
                </TableCell>
            ))}
            </TableRow>
        ))}
      </TableHead>
      <TableBody  {...getTableBodyProps()}>
        {loading ? (
            <TableRow className="loading">
             <TableCell colSpan={colspan}>
              <Spinner intent="primary" />
            </TableCell>
            </TableRow>
        ) : page.length ? (
          page.map((row: any, i: any) => {
            prepareRow(row);
            const tooltipMessage =
              getTooltipMessage && getTooltipMessage(row.original);
            return (
                <TableRow
                {...row.getRowProps()}
                className={tooltipMessage ? "relative" : undefined}
              >
                {row.cells.map((cell: any) => {
                  return (
                     <TableCell
                      {...cell.getCellProps()}
                      onClick={
                        onRowClick ? () => onRowClick(row.original) : undefined
                      }
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
                {tooltipMessage && (
                  <Tooltip
                    content={tooltipMessage}
                    className="absolute height-100 top-0 left-0 w-full z-50"
                    targetClassName="height-100 w-full"
                    position={PopoverPosition.BOTTOM}
                  >
                    <div />
                  </Tooltip>
                )}
                </TableRow>
            );
          })
        ) : (
            <TableRow className="no-data">
             <TableCell colSpan={colspan}>
              {filtered || !noDataComp ? (
                <NonIdealState
                  icon="search"
                  title={intl.formatMessage({
                    id: "app.tables.no-matching-data",
                  })}
                  description={intl.formatMessage({
                    id: "app.tables.description.change-search-options",
                  })}
                  action={
                    <Button
                      text={intl.formatMessage({
                        id: "app.tables.button-text.cleat-search-string",
                      })}
                      intent="primary"
                      onClick={onClearSearchString}
                    />
                  }
                />
              ) : (
                noDataComp
              )}
            </TableCell>
            </TableRow>
        )}
      </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GenericTable;
