import * as React from 'react';
import 'rsuite/dist/rsuite.min.css';
import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

type OwnProps = {
  onDateChange: (selectedRange: string) => void;
  isReset: boolean;
  setIsReset: (isReset: boolean) => void;
};

type Props = OwnProps;
const MOMENT_FORMAT = "YYYY/MM/DD";

const PublishedNotesFilterDateRange: React.FC<Props> = (props) => {
  const { onDateChange, isReset, setIsReset } = props;
  const intl = useIntl();

  const [selectedDateRange, setSelectedDateRange] = useState(null);

  const handleDateRangeSelect = (dateRange: moment.MomentInput[]) => {
    if (!!dateRange && (!!dateRange[0] || !!dateRange[1])) {
      const firstDate = moment(dateRange[0], MOMENT_FORMAT).locale(intl.locale);
      const endDate = moment(dateRange[1], MOMENT_FORMAT).locale(intl.locale);

      // Call the callback function to send the values to the parent
     // onDateChange(`${firstDate.format("DD/MM/YY")} - ${endDate.format("DD/MM/YY")}`);
      onDateChange(`${firstDate.format("MM/DD/YY")} - ${endDate.format("MM/DD/YY")}`);
      setSelectedDateRange(dateRange as any);
      setIsReset(false);
    }
  }

  useEffect(() => {
    isReset &&
      resetDate();
  }, [isReset]);

  const resetDate = () => {
    setSelectedDateRange(null);
    onDateChange('');
  }

  return (
    <DateRangePicker
      placeholder={intl.formatMessage({ id: "app.titles.date-range" })}
      className="filterRange"
      character=' - '
      editable={true}
     // format='dd/MM/yy'
      format='MM/dd/yyyy'
      onOk={(date) => handleDateRangeSelect(date)}
      value={selectedDateRange}
      onClean={resetDate}
      ranges={[]}
 
    />
  );
}

export default PublishedNotesFilterDateRange;