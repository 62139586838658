import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getStaffEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getUsers.request)),
    switchMap((action) =>
      from(api.users.getUsers(action.payload)).pipe(
        map(actions.getUsers.success),
        catchError((error) => of(actions.getUsers.failure(error)))
      )
    )
  );
};

export const getInviteByCodeEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getInviteByCode.request)),
    switchMap((action) =>
      from(api.users.getInviteByCode(action.payload)).pipe(
        map(actions.getInviteByCode.success),
        catchError((error) => of(actions.getInviteByCode.failure(error)))
      )
    )
  );
};

export const inviteUsersEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.inviteUsers.request)),
    switchMap((action) =>
      from(api.users.inviteUsers(action.payload)).pipe(
        mergeMap((invites) =>
          from(
            [actions.inviteUsers.success(invites)],
            (action.payload.onSuccess &&
              action.payload.onSuccess(invites.success)) as any
          )
        ),
        catchError((error) => of(actions.inviteUsers.failure(error)))
      )
    )
  );
};

export const editUserEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.editUser.request)),
    switchMap((action) =>
      from(api.users.editUser(action.payload.user)).pipe(
        mergeMap((user) =>
          from(
            [actions.editUser.success(user)],
            action.payload.onSuccess && action.payload.onSuccess(user)
          )
        ),
        catchError((error) => of(actions.editUser.failure(error)))
      )
    )
  );
};

export const editInvitedUserEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.editInvitedUser.request)),
    switchMap((action) =>
      from(api.users.editInvitedUser(action.payload.user)).pipe(
        mergeMap((user) =>
          from(
            [actions.editInvitedUser.success(user)],
            action.payload.onSuccess && action.payload.onSuccess()
          )
        ),
        catchError((error) => of(actions.editInvitedUser.failure(error)))
      )
    )
  );
};

export const redispatchInviteEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.redispatchInvite.request)),
    switchMap((action) =>
      from(api.users.redispatchInvite(action.payload)).pipe(
        map(actions.redispatchInvite.success),
        catchError((error) => of(actions.redispatchInvite.failure(error)))
      )
    )
  );
};

export const getUserTermsAgreementEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getUserTermsAgreement.request)),
    switchMap((action) =>
      from(api.users.getUserTermsAgreement()).pipe(
        map(actions.getUserTermsAgreement.success),
        catchError((error) => of(actions.getUserTermsAgreement.failure(error)))
      )
    )
  );
};

export const deleteUserTermsAgreementEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteUserTermsAgreement.request)),
    switchMap((action) =>
      from(api.users.deleteUserTermsAgreement(action.payload)).pipe(
        map(() => actions.deleteUserTermsAgreement.success(action.payload)),
        catchError((error) =>
          of(actions.deleteUserTermsAgreement.failure(error))
        )
      )
    )
  );
};

export const getUserLoginHistoryEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getUserLoginHistory.request)),
    switchMap((action) =>
      from(api.users.getUserLoginHistory(action.payload)).pipe(
        map(actions.getUserLoginHistory.success),
        catchError((error) => of(actions.getUserLoginHistory.failure(error)))
      )
    )
  );
};

export const getUserActionAuditEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getUserActionAudit.request)),
    switchMap((action) =>
      from(api.users.getUserActionAudit(action.payload)).pipe(
        map(actions.getUserActionAudit.success),
        catchError((error) => of(actions.getUserActionAudit.failure(error)))
      )
    )
  );
};

export const getUserAllActionAuditEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getUserAllActionAudit.request)),
    switchMap((action) =>
      from(api.users.getUserAllActionAudit(action.payload)).pipe(
        map(actions.getUserAllActionAudit.success),
        catchError((error) => of(actions.getUserAllActionAudit.failure(error)))
      )
    )
  );
};


export const getDistrictSummryCountsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getAuditCounts.request)),
    switchMap((action) =>
      from(api.users.getAuditCounts()).pipe(
        map(actions.getAuditCounts.success),
        catchError((error) => of(actions.getAuditCounts.failure(error)))
      )
    )
  );
};


export const downloadDistrictSummaryCountsEpic: Epic<RootAction, RootAction, RootAction> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.downloadDistrictSummaryCounts.request)),
    switchMap((action) =>
      from(
        api.users.downloadDistrictSummaryCounts(
        )
      ).pipe(
        map((result) => {
          const blob = new Blob([(result as Blob)], { type: "text/csv" });
          const csvUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.setAttribute("download", 'District Summary Counts' + ".csv");
          link.href = csvUrl;
          document.body.append(link);
          link.click();
          link.remove();
          return actions.downloadDistrictSummaryCounts.success(result);
        }),
        catchError((error) => of(actions.downloadDistrictSummaryCounts.failure(error)))
      )
    )
  );
};


export const downloadSchoolSummaryCountsEpic: Epic<RootAction, RootAction, RootAction> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.downloadSchoolSummaryCounts.request)),
    switchMap((action) =>
      from(
        api.users.downloadSchoolSummaryCounts(
        )
      ).pipe(
        map((result) => {
          const blob = new Blob([(result as Blob)], { type: "text/csv" });
          const csvUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.setAttribute("download", 'School Summary Counts' + ".csv");
          link.href = csvUrl;
          document.body.append(link);
          link.click();
          link.remove();
          return actions.downloadSchoolSummaryCounts.success(result);
        }),
        catchError((error) => of(actions.downloadSchoolSummaryCounts.failure(error)))
      )
    )
  );
};
