import React, { ReactNode, useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
// import { SurveyDemographic, SurveyDeploymentDistrictComparison } from "../../../types";
import { Callout, Icon, Position, Tooltip,Popover, PopoverPosition } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  DomainComparison,
  SurveyDemographic,
  SurveyDeploymentDistrictComparison,
} from "../../../../types";
import ComparisonDistributionItem from "./ComparisonDistributionItem";
import { COMPARISON_COLORS } from "../../../surveys/tab/SurveyComparisonTab";
import _ from "lodash";
// import { Popover } from "@mui/material";
import { getBooleanFromLocalStorage, LocalStorageKeys } from "../../../../constants";
import { selectIsAdmin } from "../../../../store/auth/selectors";
import { useLocation } from "react-router";

type OwnProps = {
  surveyDepsDemographics: SurveyDemographic[][];
  comparisonReportType?: string | null;
  removeSurveyDeployment: (s: SurveyDeploymentDistrictComparison) => void;
  totalMeanTooltipState: boolean;
  handleSchoolColSpan: (sd: SurveyDemographic[]) => void;
  handleHoverTotalMean: (flag: boolean) => void;
  filterDataOnly: (sd: SurveyDemographic) => boolean;
  domainSchoolsDomainComparisons: any;
  filteredCol: any;
  isItemLevelComparison: boolean;
  itemComparisons: any;
  filteredDemographics:SurveyDemographic[];
  meansScoreHeader:DomainComparison[];
};

type Props = OwnProps;

const CustomPrevArrow = (props: {
  className?: string;
  style?: {};
  onClick?: () => void;
}) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "white",
        width: "20px",
        height: "20px",
        transform: "rotate(0deg)", // Rotate to create a triangle
        clipPath: "polygon(100% 0, 0 50%, 100% 100%)", // Triangle shape
        position: "absolute", // Position absolute to control its position
        top: "15px", // Adjust this value to move the arrow up
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
};

const CustomNextArrow = (props: {
  className?: string;
  style?: {};
  onClick?: () => void;
}) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "white",
        width: "20px",
        height: "20px",
        transform: "rotate(0deg)", // Rotate to create a triangle
        clipPath: "polygon(0 0, 100% 50%, 0 100%)",
        position: "absolute", // Position absolute to control its position
        top: "15px", // Adjust this value to move the arrow up
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
};

export let TotalMeanScore: ReactNode;

const ComparisionReportSlider: React.FC<Props> = (props: Props) => {
  const {
    surveyDepsDemographics,
    filterDataOnly,
    comparisonReportType,
    removeSurveyDeployment,
    totalMeanTooltipState,
    handleHoverTotalMean,
    handleSchoolColSpan,
    domainSchoolsDomainComparisons,
    filteredCol,
    isItemLevelComparison,
    itemComparisons,
    filteredDemographics,
    meansScoreHeader
  } = props;

  const intl = useIntl();
  const location = useLocation();


  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow:
      comparisonReportType && surveyDepsDemographics.length >= 2
        ? 2
        : !comparisonReportType && surveyDepsDemographics.length >= 2
        ? 2
        : 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const surveyDeployment:
    | SurveyDeploymentDistrictComparison[]
    | undefined = useSelector(
    (s) => s.surveysDeployment.selectedDistrictComarprisonSurvey
  );
  const surveyComparison = useSelector(
    (s) => s.surveyReports.districtComparison
  );
  

  const localFlagToHideSensitiveData = getBooleanFromLocalStorage(
    LocalStorageKeys.SensitiveReportingDataHidden
  );

  const isAdmin = useSelector(selectIsAdmin);


  const domainTotalMeanScore = (e: SurveyDemographic) => {
    let totalScore: number = 0;
    surveyDeployment.map((da: SurveyDeploymentDistrictComparison) => {
      if (da?.responses) {
        _.mapKeys(da?.responses, function (value?: number, key?: string) {
          if (key === e && value) {
            totalScore = totalScore + value;
          }
        });
      }
    });
    return totalScore;
  };
  const getSchoolDetail = (i: number) => {
    const sd = surveyDeployment?.[i];
    if (sd) {
      return (
        <p className="text-center">
          <h4 className="school-title">
            {sd?.schoolName}
            {/* {!comparisonReportType && (
            <Icon
              icon={"cross"}
              iconSize={24}
              className="cursor-pointer"
              onClick={() => removeSurveyDeployment(sd)}
            />
          )} */}
            <br />
            <span>
              {intl.formatMessage(
                {
                  id: "app.district-comparison-table.school-detail",
                },
                {
                  deployment: sd?.deployment,
                  administration: sd?.administration,
                  month: sd?.month,
                  year: sd?.year,
                }
              )}
            </span>
          </h4>
        </p>
      );
    } else {
      return (
        <Tooltip
          content={
            <div className="p-2">
              {intl.formatMessage({
                id: "app.district-comparison-table.total-mean-score.popover",
              })}
            </div>
          }
          position={Position.TOP}
          className="flex justify-center"
          popoverClassName="max-w-140"
          isOpen={totalMeanTooltipState}
        >
          <p className="text-center gap-2 text-lg font-semibold">
            {intl.formatMessage({
              id: "app.district-comparison-table.total-mean-score.title",
            })}
          </p>
        </Tooltip>
      );
    }
  };
  const MakeChunks = (data: any, chunkSizes: number[]) => {
    let groupedData = [];
    let startIndex = 0;

    for (const size of chunkSizes) {
      const endIndex = startIndex + size;
      groupedData.push(data.slice(startIndex, endIndex));
      startIndex = endIndex;
    }

    return groupedData;
  };

  TotalMeanScore = surveyDepsDemographics
    .slice(surveyDepsDemographics.length - 1)
    .map((surveyDemographics: SurveyDemographic[], surveyDepId: number) => (
      <>
        <div hidden={!surveyDeployment.length} key={surveyDepId}>
          <div
            className={`grid grid-cols-${surveyDemographics.length} break-words`}
          >
            {surveyDemographics.length > 0 ? (
              surveyDemographics.map((d: any, i: any) =>
                filterDataOnly(d) ? (
                  <div
                    key={surveyDepId + "" + i}
                    hidden={!surveyDeployment.length}
                    className={
                      surveyDemographics.length === i + 1 &&
                      surveyDepsDemographics.length > surveyDepId + 1
                        ? "text-black font-bold text-sm text-wrap pr-2"
                        : "text-black font-bold text-sm  pr-2"
                    }
                  >
                    <p className="mt-0 mb-5 pl-2 pr-2 hgt32 text-center">
                      {intl.formatMessage({
                        id: `app.filters.respondent-type.distric-comparison.${d}`,
                      })}
                    </p>
                  </div>
                ) : (
                  // handleSchoolColSpan(surveyDemographics).colSpan === 0 &&
                  i === 0 && (
                    <th className="bg-white" rowSpan={2}>
                      <Callout
                        title={intl.formatMessage({
                          id: "app.tables.no-available-data",
                        })}
                        intent="warning"
                      />
                    </th>
                  )
                )
              )
            ) : (
              <th className="bg-white" rowSpan={2}>
                <Callout
                  title={intl.formatMessage({
                    id: "app.tables.no-available-data",
                  })}
                  intent="warning"
                />
              </th>
            )}
          </div>

          {isItemLevelComparison ? (
            itemComparisons.map((item: any) => {
              const itemComparisonchunks = MakeChunks(
                item.questionResponses.slice(
                  -surveyDepsDemographics.slice(
                    surveyDepsDemographics.length - 1
                  )[0].length
                ),
                surveyDepsDemographics.map((sdd: any) => sdd.length)
              ).map((item) =>
                item.map((itemResponses: any, index: number) => itemResponses)
              );

              return (
                <div
                  hidden={!surveyDeployment.length}
                  key={surveyDepId}
                  className={`grid grid-cols-${itemComparisonchunks[surveyDepId].length} break-words`}
                >
                  {itemComparisonchunks[surveyDepId].map(
                    (questionResponse: any, i: any) => {
                      return questionResponse &&
                        filterDataOnly(questionResponse.demographic) ? (
                        <span key={i} hidden={!surveyDeployment.length}>
                          <ComparisonDistributionItem
                            notEnoughData={questionResponse?.not_enough_data}
                            domainTitle={item?.domainResponse?.common_text}
                            responsesCount={questionResponse?.count}
                            meanScore={questionResponse?.mean_score}
                            itemColor={"#20285A"}
                          />
                        </span>
                      ) : (
                        filteredCol[i] && (
                          <span
                            key={i}
                            hidden={!surveyDeployment.length}
                          ></span>
                        )
                      );
                    }
                  )}
                </div>
              );
            })
          ) : (
            <div className="domain-wrapper">
              {domainSchoolsDomainComparisons.map((item: any) => {
                const domainComparisonchunks = MakeChunks(
                  item.domainComparisons.slice(
                    -surveyDepsDemographics.slice(
                      surveyDepsDemographics.length - 1
                    )[0].length
                  ),
                  surveyDepsDemographics.map((sdd: any) => sdd.length)
                ).map((item) =>
                  item.map(
                    (domainResponses: any, index: number) => domainResponses
                  )
                );

                return (
                  <>
                    <div
                      hidden={!surveyDeployment.length}
                      key={surveyDepId}
                      className={`slic-grid grid grid-cols-${domainComparisonchunks[surveyDepId].length} break-words`}
                    >
                      {domainComparisonchunks[surveyDepId].map(
                        (d: any, i: any) => {
                          return filterDataOnly(d.demographic) ? (
                            <span key={i} hidden={!surveyDeployment.length}>
                              <ComparisonDistributionItem
                                notEnoughData={d?.not_enough_data}
                                domainTitle={item?.domain?.name}
                                meanScore={d?.mean_score}
                                responsesCount={d?.total_respondents}
                                color={
                                  item.domain?.color_hex
                                    ? item.domain?.color_hex
                                    : COMPARISON_COLORS[
                                        (item.domain?.id ?? 0) %
                                          COMPARISON_COLORS.length
                                      ]
                                }
                              />
                            </span>
                          ) : (
                            filteredCol[i] && (
                              <div
                                key={i}
                                hidden={!surveyDeployment.length}
                              ></div>
                            )
                          );
                        }
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          )}
        </div>
      </>
    ));

const noDataforGroupWarning =
<Tooltip content={intl.formatMessage({
    id: "app.tables.no-available-data",
  })}
position={PopoverPosition.BOTTOM} > 
<p className="ellipsis" style={{color:"#bf7326",background:"rgba(191, 115, 38, 0.2)"}}>
                                          
<Icon iconSize={15} icon="warning-sign" style={{color:"#bf7326"}} />
{intl.formatMessage({
    id: "app.tables.no-available-data",
  })}

</p>
</Tooltip>;

  return (
    <>
      <Slider className={`slider-outer ${(comparisonReportType && surveyDepsDemographics.length <= 2) && "slider-comp-report"}`} {...settings}>
        {(comparisonReportType
          ? surveyDepsDemographics.slice(0, surveyDepsDemographics.length - 1)
          : surveyDepsDemographics
        ).map(
          (surveyDemographics: SurveyDemographic[], surveyDepId: number) => (
            <>
              <div
                className="br-right"
                hidden={!surveyDeployment.length}
                key={surveyDepId}
                {...handleSchoolColSpan(surveyDemographics)}
                {...(!surveyDeployment?.[surveyDepId] && {
                  onMouseOver: () => handleHoverTotalMean(true),
                  onMouseOut: () => handleHoverTotalMean(false),
                })}
              >
                {getSchoolDetail(surveyDepId)}

                <div
                  className={`grid grid-cols-${surveyDemographics.length} break-words comp-header`}
                >
                  {surveyDemographics.length > 0 ? (
                    surveyDemographics.map((d: SurveyDemographic, i: number) =>  
                      filterDataOnly(d) ? (
                        <><div
                          key={surveyDepId + "" + i}
                          hidden={!surveyDeployment.length}
                          className={
                            surveyDemographics.length === i + 1 &&
                            surveyDepsDemographics.length > surveyDepId + 1
                              ? "text-black font-bold text-sm text-wrap pr-2 pl-2"
                              : "text-black font-bold text-sm  pr-2 pl-2"
                          }
                        >
                          <h4 className="mt-0 mb-0 pl-2 pr-2  text-center">
                            {intl.formatMessage({
                              id: `app.filters.respondent-type.distric-comparison.${d}`,
                            })}
                          
                          </h4>

                        </div>
</>
                        
                      ) : (
                        i === 0 && (
                          filteredCol[i] && (
                            <span
                              key={i}
                              hidden={!surveyDeployment.length}
                            ></span>)
                          // <th className="bg-white" rowSpan={2}>
                          //   <Callout
                          //     title={intl.formatMessage({
                          //       id: "app.tables.no-available-data",
                          //     })}
                          //     intent="warning"
                          //   />
                          // </th>
                        )
                      )
                    )
                  ) : (
                    <th className="text-sm" rowSpan={2}>
                      <Callout
                        title={intl.formatMessage({
                          id: "app.tables.no-available-data",
                        })}
                        intent="warning"
                      />
                    </th>
                  )}


                </div>

                <div
                  className={`grid grid-cols-${surveyDemographics.length} break-words `}
                >
                  {surveyDemographics.length > 0 ? (
                    surveyDemographics.map((d: SurveyDemographic, i: number) =>  
                      filterDataOnly(d) ? (
                        <><div
                          key={surveyDepId + "" + i}
                          hidden={!surveyDeployment.length}
                          className={
                            surveyDemographics.length === i + 1 &&
                            surveyDepsDemographics.length > surveyDepId + 1
                              ? "text-black font-bold text-sm text-wrap pr-2 pl-2"
                              : "text-black font-bold text-sm  pr-2 pl-2"
                          }
                        >
                          <h4 className="mt-0 mb-0 pl-2 pr-2  text-center">
                           
                            <div className="font-normal">
                         <Popover
                          // popoverClassName={"w-64"}
                          interactionKind="hover"
                          disabled={surveyDeployment[surveyDepId]?.responses?.[d]?false:true}
                          content={
                            <div className="p-2">
                              {isItemLevelComparison
                                ? intl.formatMessage({
                                    id:
                                      "app.district-comparison-table.total-respondent-domain.popover-containt",
                                  })
                                : intl.formatMessage({
                                    id:
                                      "app.district-comparison-table.total-respondent-all-domain.popover-containt",
                                  })}
                            </div>
                          }
                        >
                          
                          <p className={"flex justify-center text-xl"}>
                            {isItemLevelComparison ? (
                              <p>
                                {
                                  meansScoreHeader[surveyDepId]?.respondents?.[d]
                                    ?.total_respondents ?
                                    meansScoreHeader[surveyDepId]?.respondents?.[d]
                                    ?.total_respondents :
                                    noDataforGroupWarning
                                }
                              </p>
                            ) : (
                              <>
                                {" "}
                                {isAdmin ? (
                                  <p className="mt-0 mb-5 pl-2 pr-2  text-center">
                                    
                                    {surveyDeployment[surveyDepId]
                                      ? (surveyDeployment[surveyDepId]?.responses?.[
                                          d
                                        ] as number) > 0 &&
                                        (surveyDeployment[surveyDepId]?.responses?.[
                                          d
                                        ] as number) < 10 &&
                                        localFlagToHideSensitiveData
                                        ? ""
                                        : surveyDeployment[surveyDepId]?.responses?.[d] ? surveyDeployment[surveyDepId]?.responses?.[d]:
                                        noDataforGroupWarning
                                      : domainTotalMeanScore(d)}
                                  </p>
                                ) : (
                                  <p className="mb-1">
                                     
                                    {surveyDeployment[surveyDepId]
                                      ? (surveyDeployment[surveyDepId]?.responses?.[
                                          d
                                        ] as number) > 0 &&
                                        (surveyDeployment[surveyDepId]?.responses?.[
                                          d
                                        ] as number) < 10
                                        ? ""
                                        : surveyDeployment[surveyDepId]?.responses?.[d] ? surveyDeployment[surveyDepId]?.responses?.[d]:
                                        noDataforGroupWarning
                                      : domainTotalMeanScore(d)}
                                  </p>
                                )}
                              </>
                            )}
                          </p>
                        </Popover>
                         </div>
                          </h4>

                        </div>
</>
                        
                      ) : (
                        i === 0 && (
                          filteredCol[i] && (
                            <span
                              key={i}
                              hidden={!surveyDeployment.length}
                            ></span>)
                          // <th className="bg-white" rowSpan={2}>
                          //   <Callout
                          //     title={intl.formatMessage({
                          //       id: "app.tables.no-available-data",
                          //     })}
                          //     intent="warning"
                          //   />
                          // </th>
                        )
                      )
                    )
                  ) : (
                  <></>
                    
                  )}


                </div>

                {isItemLevelComparison
                  ? itemComparisons.map((item: any) => {
                      const itemComparisonchunks = MakeChunks(
                        item.questionResponses,
                        surveyDepsDemographics.map((sdd: any) => sdd.length)
                      ).map((item) =>
                        item.map(
                          (itemResponses: any, index: number) => itemResponses
                        )
                      );

                      return (
                        <div
                          hidden={!surveyDeployment.length}
                          key={surveyDepId}
                          className={`slic-grid grid grid-cols-${itemComparisonchunks[surveyDepId].length} break-words`}
                        >
                          {itemComparisonchunks[surveyDepId].map(
                            (questionResponse: any, i: any) => {
                              return questionResponse &&
                                filterDataOnly(questionResponse.demographic) ? (
                                <span key={i} hidden={!surveyDeployment.length}>
                                  <ComparisonDistributionItem
                                    notEnoughData={
                                      questionResponse?.not_enough_data
                                    }
                                    domainTitle={
                                      item?.domainResponse?.common_text
                                    }
                                    responsesCount={questionResponse?.count}
                                    meanScore={questionResponse?.mean_score}
                                    itemColor={"#20285A"}
                                  />
                                </span>
                              ) : (
                                filteredCol[i] && (
                                  <span
                                    key={i}
                                    hidden={!surveyDeployment.length}
                                  ></span>
                                )
                              );
                            }
                          )}
                        </div>
                      );
                    })
                  : domainSchoolsDomainComparisons.map((item: any) => {
                      const domainComparisonchunks = MakeChunks(
                        item.domainComparisons,
                        surveyDepsDemographics.map((sdd: any) => sdd.length)
                      ).map((item) =>
                        item.map(
                          (domainResponses: any, index: number) =>
                            domainResponses
                        )
                      );

                      return (
                        <>
                          <div
                            hidden={!surveyDeployment.length}
                            key={surveyDepId}
                            className={`slic-grid grid grid-cols-${domainComparisonchunks[surveyDepId].length} break-words`}
                          >
                            {domainComparisonchunks[surveyDepId].map(
                              (d: any, i: any) => {
                                return filterDataOnly(d.demographic) ? (
                                  <span
                                    key={i}
                                    hidden={!surveyDeployment.length}
                                  >
                                    <ComparisonDistributionItem
                                      notEnoughData={d?.not_enough_data}
                                      domainTitle={item?.domain?.name}
                                      meanScore={d?.mean_score}
                                      responsesCount={d?.total_respondents}
                                      color={
                                        item.domain?.color_hex
                                          ? item.domain?.color_hex
                                          : COMPARISON_COLORS[
                                              (item.domain?.id ?? 0) %
                                                COMPARISON_COLORS.length
                                            ]
                                      }
                                    />
                                  </span>
                                ) : (
                                  filteredCol[i] && (
                                    <div
                                      key={i}
                                      hidden={!surveyDeployment.length}
                                    ></div>
                                  )
                                );
                              }
                            )}
                          </div>
                        </>
                      );
                    })}
              </div>
            </>
          )
        )}
      </Slider>
    </>
  );
};

export default ComparisionReportSlider;
