import React, { useMemo } from "react";
import { Position, Tooltip } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { pickTextColorBasedOnBgColor } from "../../../charts/utils";
import { Popover } from "@blueprintjs/core";

type OwnProps = {
  notEnoughData?: boolean;
  meanScore?: number;
  responsesCount?: number;
  color?: string;
  popoverDisable?: boolean;
  domainTitle?: string;
  itemColor?:string;
};

type Props = OwnProps;

const ComparisonDistributionItem: React.FC<Props> = ({
  notEnoughData,
  meanScore,
  responsesCount,
  color,
  popoverDisable,
  domainTitle,
  itemColor,
}: Props) => {
  const intl = useIntl();

  const content = useMemo(
    () => (
      <Popover
        interactionKind="hover"
        disabled={!meanScore || popoverDisable}
        content={
          <div className="p-3 text-center">
            {domainTitle && <h4>{domainTitle}</h4>}
            <b>
              {responsesCount && <span>{responsesCount}</span>}
              {intl.formatMessage({
                id:
                  "app.district-comparison-table.respondents-domain-number.popover-containt",
              })}
            </b>
          </div>
        }
      >
        <div
          className="mean-score__pill font-bold text-xl text-white"
          style={{
            backgroundColor: meanScore ? color : "",
            color:itemColor?itemColor:""
          }}
        >
            {meanScore?.toFixed(2)}
        </div>
      </Popover>
    ),
    [meanScore, notEnoughData]
  );

  if (notEnoughData) {
    return (
      <span className="text-center">
        {intl.formatMessage({
          id: "app.non-ideal-state.survey-reports.not-enough-respondents",
        })}
      </span>
    );
  }

  return (
    <td >{content}</td>
  );
};

export default ComparisonDistributionItem;
