import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getDistrictSurveyComparison } from "../../../../store/survey-reports/actions";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import {
  DomainComparison,
  DomainQuestionsComparison,
  DomainResponses,
  QuestionResponses,
  // ModifiedQuestionResponses,
  DemographicOnly,
  SurveyDemographic,
  SurveyDeploymentDistrictComparison,
  SurveyReportTabEnum,
  DistrictComparisonRouteParams,
  UserNotesType,
} from "../../../../types";
import {
  Popover,
  Spinner,
  Tooltip,
  Position,
  Button,
  Icon,
  Callout,
} from "@blueprintjs/core";
import BriefDescriptionDialog from "../../dialogs/BriefDescriptionDialog";
import { COMPARISON_COLORS } from "../../../surveys/tab/SurveyComparisonTab";
import ReportTableDomainItem from "./ReportTableDomainItem";
import ComparisonDistributionItem from "./ComparisonDistributionItem";
import DistrictComaprisonHeaderFilter from "./DistrictComaprisonHeaderFilter";
import useDistrictComparisonParams from "../../../../helpers/hooks/useDistrictComparisonParams";
import usePrevProps from "../../../../helpers/hooks/usePrevProps";
import {
  getDomainById,
  setSelectedDomain,
} from "../../../../store/domains/actions";
import { isDomainQuestionsComparison } from "../../../surveys/table/utils";
import { GetSurveyComparisonRequest } from "../../../../api/survey-reports/types";
import _, { first } from "lodash";
import { getSurveyDemographicForComparison } from "../../../surveys/tab/useComparison";
import { generatePath, useHistory, useLocation, useParams } from "react-router";
import { REPORT_TYPE_PARAM } from "../surveys-deployment-table/SurveysDeploymentTable";
import { getUrlParam } from "../../../../helpers/url-utils";
import { setSelectedDistrictComarprisonSurvey } from "../../../../store/surveys-deployment/actions";
import {
  getBooleanFromLocalStorage,
  LocalStorageKeys,
} from "../../../../constants";
import { selectIsAdmin } from "../../../../store/auth/selectors";
import { Checkbox, FormControlLabel } from "@mui/material";
import "./comparison-report.scss";
import { AuthRoutes } from "../../../../App";
import ComparisionReportSlider, {
  TotalMeanScore,
} from "./ComparisionReportSlider";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type OwnProps = {
  darkHeader?: boolean;
  isTeamPlanTable?: boolean;
  isManagedDeletedDataWorkspace?: boolean;
  showSurveyDeploymentDialog: (ele: boolean) => void;
};

type Props = OwnProps;

type DialogProps = {
  common_text: string;
  survyObj: (QuestionResponses | DemographicOnly)[];
};

const DistrictComparisonTable: React.FC<Props> = ({
  isTeamPlanTable,
  isManagedDeletedDataWorkspace,
  showSurveyDeploymentDialog,
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const TOTAL_MEAN_TOOLTIP_TIMEOUT = 100;
  const activePlan = useSelector((s) =>
    isTeamPlanTable ? s.plans.activePlan : undefined
  );
  const [totalMeanTooltipState, setTotalMeanTooltipState] = useState(false);
  const [meanScoreHoverTimer, setMeanScoreHoverTimer] = useState(0);

  const selectedDomain = useSelector((s) => s.domains.selectedDomain);
  const routeParams = useParams<DistrictComparisonRouteParams>();

  const [
    isBriefDescriptionDialogOpen,
    setBriefDescriptionDialogOpen,
  ] = useState<boolean>(false);

  const { domainId: selectedDomainId } = useDistrictComparisonParams();
  const location = useLocation();
  const prevProps = usePrevProps({ domainId: selectedDomainId });

  const [filteredDemographics, setFilteredDemographics] = useState<
    SurveyDemographic[]
  >([]);

  useEffect(() => {
    if (prevProps?.domainId !== selectedDomainId) {
      if (selectedDomainId !== undefined) {
        dispatch(getDomainById.request(selectedDomainId));
      } else {
        dispatch(setSelectedDomain(undefined));
      }
    }
  }, [dispatch, prevProps?.domainId, selectedDomainId]);

  const comparisonReportType = useMemo(() => {
    return getUrlParam(REPORT_TYPE_PARAM, location.search);
  }, [location.search]);

  const [dialogProps, setDialogProps] = useState<DialogProps>();

  const loading = useSelector(
    (s) => s.surveyReports.loading.getDistrictComparison
  );
  const error = useSelector(
    (s) => s.surveyReports.errors.getDistrictComparison
  );
  useLoading({ loading, error });

  const surveyDeployment:
    | SurveyDeploymentDistrictComparison[]
    | undefined = useSelector(
    (s) => s.surveysDeployment.selectedDistrictComarprisonSurvey
  );

  useEffect(() => {
    if ((isTeamPlanTable && activePlan?.id) || !isTeamPlanTable) {
      let sd: GetSurveyComparisonRequest[] = [];
      if (surveyDeployment && surveyDeployment.length > 0) {
        sd = surveyDeployment.map<GetSurveyComparisonRequest>(
          (
            e: SurveyDeploymentDistrictComparison
          ): GetSurveyComparisonRequest => {
            const localFlagToHideSensitiveData: boolean = getBooleanFromLocalStorage(
              LocalStorageKeys.SensitiveReportingDataHidden
            );
            return {
              survey_deployment_id: [e.surveyDeploymentId],
              school_id: [e.schoolId],
              domain_id: selectedDomainId,
              hide_sensitive_reporting_data: localFlagToHideSensitiveData,
            };
          }
        );
      } else return;

      dispatch(
        getDistrictSurveyComparison.request({
          surveyComparisonReq: sd,
          isComprisonReport: !!comparisonReportType,
        })
      );
    }
  }, [
    activePlan?.id,
    isTeamPlanTable,
    isManagedDeletedDataWorkspace,
    selectedDomainId,
    surveyDeployment,
  ]);

  const surveyComparison = useSelector(
    (s) => s.surveyReports.districtComparison
  );

  function floorFigure(figure: number, decimals: number = 2): number {
    let d: number = Math.pow(10, decimals);
    let c: any = figure * d;
    return parseFloat((parseInt(c) / d).toFixed(decimals));
  }

  // let surveyComparisonData: DomainQuestionsComparison | DomainComparison[];
  // if (surveyComparison) {
  //   if (surveyComparison && (surveyComparison[0] as DomainComparison[]).length === undefined) {
  //     surveyComparisonData = surveyComparison[0];
  //   }
  //   else if (surveyComparison && (surveyComparison[1] as DomainComparison[]).length === undefined) {
  //     surveyComparisonData = surveyComparison[1];
  //   }
  //   else if (surveyComparison && (surveyComparison[2] as DomainComparison[]).length === undefined) {
  //     surveyComparisonData = surveyComparison[2];
  //   }
  //   else {
  //     surveyComparisonData = surveyComparison[3];
  //   }
  // }
  // else {
  //   surveyComparisonData = [];
  // }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let surveyComparisonData: DomainQuestionsComparison | DomainComparison[] = [];
  if (surveyComparison) {
    for (let i = 0; i < surveyComparison.length; i++) {
      if ((surveyComparison[i] as DomainComparison[]).length === undefined) {
        surveyComparisonData = surveyComparison[i];
        break;
      }
    }
  }

  const firstSurveyData = useMemo(() => surveyComparisonData, [
    surveyComparisonData,
  ]);

  const getHighestRespondent = (
    data: DomainQuestionsComparison | DomainComparison[],
    d: SurveyDemographic
  ) => {
    //Highest number of respondents
    let max: number | undefined = 0;
    if (data) {
      if (!isItemLevelComparison) {
        (data as DomainComparison[]).forEach((d1) => {
          max = d1.respondents?.[d]?.total_respondents
            ? d1.respondents?.[d]?.total_respondents
            : max;
        });
      } else {
        (data as DomainQuestionsComparison).responses.forEach((d1: any) => {
          max = d1.respondents?.[d]?.count ? d1.respondents?.[d]?.count : max;
        });
      }
    }
    return max;
  };

  const isItemLevelComparison = useMemo(
    () => isDomainQuestionsComparison(firstSurveyData),
    [firstSurveyData]
  );

  const {
    surveyDepsDemographics,
    domainSchoolsDomainComparisons = [],
    itemComparisons = [],
  } = useMemo(() => {
    const surveyDepsDemographics = _.chain(surveyComparison)
      .map((domainComparisons) =>
        getSurveyDemographicForComparison(domainComparisons)
      )
      .value();

    if (isItemLevelComparison) {
      const uniqDomainResponsesByCommonText = _.chain(
        surveyComparison as DomainQuestionsComparison[]
      )
        .flatMap((c) => c.responses)
        //.uniqBy((domainResponse) => domainResponse.common_text_id)
        .uniqBy(
          (domainResponse) => domainResponse && domainResponse.common_text_id
        )
        .value();

      const itemComparisons = _.chain(uniqDomainResponsesByCommonText)
        .map((domainResponse) => {
          const questionResponses = _.chain(surveyDepsDemographics)
            .flatMap(
              (
                surveyDemographics: SurveyDemographic[],
                surveyDeploymentIndex: number
              ) => {
                if (!!surveyDemographics.length) {
                  let questionResponseData = surveyDemographics.map(
                    (demographic) => {
                      let responseData = (surveyComparison as DomainQuestionsComparison[])?.[
                        surveyDeploymentIndex
                      ]?.responses.find(
                        (r) =>
                          domainResponse &&
                          r.common_text_id === domainResponse.common_text_id
                      )?.respondents?.[demographic];
                      if (responseData?.is_deleted) responseData = undefined;
                      return { ...responseData, ...{ demographic } };
                    }
                  );
                  return questionResponseData;
                } else return [{ demographic: "" as SurveyDemographic }];
              }
            )
            .value();

          return {
            domainResponse: domainResponse,
            questionResponses: questionResponses,
          };
        })
        .filter((r) => {
          let alldeleted = true;
          for (let i = 0; i < r.questionResponses.length; i++) {
            if (r.questionResponses[i]?.is_deleted === false)
              alldeleted = false;
          }
          if (alldeleted) return false;
          else return true;
        })
        .value();

      let newComparisonData = [...JSON.parse(JSON.stringify(itemComparisons))];
      const lastEleLength = surveyDepsDemographics
        ? surveyDepsDemographics[surveyDepsDemographics.length - 1].length
        : 0;
      if (lastEleLength != 4) {
        surveyDepsDemographics[surveyDepsDemographics.length - 1] = [
          SurveyReportTabEnum.ElementaryStudents as any,
          SurveyReportTabEnum.Students as any,
          SurveyReportTabEnum.SchoolStaff as any,
          SurveyReportTabEnum.Parents as any,
        ] as any;
      }

      newComparisonData = newComparisonData?.map((et: any) => {
        if (comparisonReportType == null) return et;
        const array: any = et
          ? [...JSON.parse(JSON.stringify(et?.questionResponses))]
          : []; //[...et?.questionResponses];

        const midIndex: any = array?.length - lastEleLength;
        let secondPart: any = [...array?.slice(midIndex, array.length)];
        let firstPart: any = [...array.slice(0, midIndex)];

        if (secondPart.length != 4) {
          secondPart = [
            {
              demographic: SurveyReportTabEnum.ElementaryStudents,
            },
            {
              demographic: SurveyReportTabEnum.Students,
            },
            {
              demographic: SurveyReportTabEnum.SchoolStaff,
            },
            {
              demographic: SurveyReportTabEnum.Parents,
            },
          ];
        }
        // CALCULATION OF MEAN SCORE ITEM LEVEL
        for (let i in secondPart) {
          secondPart[i].distribution = [];
          let msC: any = {
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
          };
          let newArr: any = [];
          for (let j in firstPart) {
            if (parseInt(j) == 0) {
              secondPart[i].mean_score = 0;
              secondPart[i].count = 0;
              msC = {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
              };
            }
            if (firstPart[j].demographic === secondPart[i].demographic) {
              secondPart[i].count = secondPart[i].count + (isNaN(firstPart[j].count)? 0 :firstPart[j].count);
              firstPart[j]?.distribution?.map((dt: any) => {
                msC[dt.answer_index?.toString()] =
                  parseInt(msC[dt.answer_index?.toString()]) + dt.count;
              });
            }
            let total_with_count = 0;
            let total = 0;
            Object.keys(msC).map((key, index) => {
              total_with_count =
                total_with_count + msC[key?.toString()] * parseInt(key);
              total = total + msC[key?.toString()];
            });
            let ms = total_with_count / total;
            if (!isNaN(ms)) secondPart[i].mean_score = ms;
            else secondPart[i].mean_score = undefined;
          }
        }
        et.questionResponses = [...firstPart, ...secondPart];
        return et;
      });
      return {
        itemComparisons: newComparisonData as any,
        surveyDepsDemographics: surveyDepsDemographics,
      };
    } else {
      const uniqDomains = _.chain(surveyComparison as DomainComparison[][])
        //.flatMap((c) => c.map((d) => d.domain))
        .flatMap((c) => {
          if (!!c.length) return c.map((d) => d.domain);
          else return [];
        })
        .uniqBy((d) => d?.id)
        .value();

      const domainSchoolsDomainComparison = _.chain(uniqDomains)
        .map((domain) => {
          const domainComparisons = _.chain(surveyDepsDemographics)
            .flatMap(
              (
                surveyDemographics: SurveyDemographic[],
                surveyDeploymentIndex: number
              ) =>
                {
                  return surveyDemographics.map((demographic) => {
                  let responseData = (surveyComparison as DomainComparison[][])?.[
                    surveyDeploymentIndex
                  ]?.find((dC) => dC.domain.id === domain.id)?.respondents?.[
                    demographic
                  ];
                  return { ...responseData, ...{ demographic } };
                })}
            )
            .value();

          return {
            domain: domain,
            domainComparisons: domainComparisons,
          };
        })
        .sort((a, b) => {
          let anum = 0;
          let bnum = 0;
          a.domainComparisons.forEach((data) => {
            if (!!data?.mean_score) anum++;
          });
          b.domainComparisons.forEach((data) => {
            if (!!data?.mean_score) bnum++;
          });
          if (anum === bnum) {
            return a.domain.name < b.domain.name ? -1 : 1;
          }
          return anum < bnum ? 1 : -1;
        })
        .value();

      let newComparisonData = [
        ...JSON.parse(JSON.stringify(domainSchoolsDomainComparison)),
      ];
      const lastEleLength = surveyDepsDemographics
        ? surveyDepsDemographics[surveyDepsDemographics?.length - 1]?.length
        : 0;
      if (lastEleLength != 4) {
        surveyDepsDemographics[surveyDepsDemographics.length - 1] = [
          SurveyReportTabEnum.ElementaryStudents as any,
          SurveyReportTabEnum.Students as any,
          SurveyReportTabEnum.SchoolStaff as any,
          SurveyReportTabEnum.Parents as any,
        ] as any;
      }
      newComparisonData = newComparisonData?.map((et: any) => {
        //DOMAIN LEVEL

        if (comparisonReportType == null) return et;

        const array: any = et
          ? [...JSON.parse(JSON.stringify(et?.domainComparisons))]
          : []; //[...et?.domainComparisons];

        const midIndex: any = array?.length - lastEleLength; //lastEleLength;
        let secondPart: any = [...array?.slice(midIndex, array.length)];
        let firstPart: any = [...array.slice(0, midIndex)];

        if (secondPart.length != 4) {
          secondPart = [
            {
              demographic: SurveyReportTabEnum.ElementaryStudents,
            },
            {
              demographic: SurveyReportTabEnum.Students,
            },
            {
              demographic: SurveyReportTabEnum.SchoolStaff,
            },
            {
              demographic: SurveyReportTabEnum.Parents,
            },
          ];
        }

        // CALCULATION OF MEAN SCORE
        for (let i in secondPart) {
          let msC: any = {
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
          };
          secondPart[i].distribution = [];
          let newArr: any = [];

          for (let j in firstPart) {
            if (parseInt(j) == 0) {
              secondPart[i].mean_score = 0;
              secondPart[i].total_respondents = 0;
              secondPart[i].index = 0;
              secondPart[i].count = 0;
              msC = {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
              };
            }

            // debugger;
            if (firstPart[j].demographic === secondPart[i].demographic) {
              secondPart[i].total_respondents =
                secondPart[i].total_respondents +
                (isNaN(firstPart[j].total_respondents) ? 0 :firstPart[j].total_respondents) ;
              firstPart[j]?.distribution?.map((dt: any) => {
                msC[dt.answer_index?.toString()] =
                  parseInt(msC[dt.answer_index?.toString()]) + dt.count;
              });
            }
          }
          let total_with_count = 0;
          let total = 0;
          Object.keys(msC).map((key, index) => {
            total_with_count =
              total_with_count + msC[key?.toString()] * parseInt(key);
            total = total + msC[key?.toString()];
          });
          let ms = total_with_count / total;
          if (!isNaN(ms)) secondPart[i].mean_score = ms;
          else secondPart[i].mean_score = undefined; //undefined;
        }

        et.domainComparisons = [...firstPart, ...secondPart];
        return et;
      });

      return {
        domainSchoolsDomainComparisons: newComparisonData as any,
        surveyDepsDemographics: surveyDepsDemographics,
      };
    }
  }, [isItemLevelComparison, surveyComparison]);

  const tableHeadingCellsCount = useMemo(() => {
    let count = 2;
    surveyDepsDemographics.forEach((e) => {
      if (e.length) count += e.length;
      else count += 1;
    });
    return count;
  }, [surveyDepsDemographics]);

  const handleCommonTextClick = (
    common_text: string,
    input: (QuestionResponses | DemographicOnly)[]
  ) => {
    if (input) {
      setBriefDescriptionDialogOpen(true);
      setDialogProps({ common_text: common_text, survyObj: input });
    }
  };

  const handleHoverTotalMean = (flag: Boolean) => {
    if (meanScoreHoverTimer) {
      clearTimeout(meanScoreHoverTimer);
      setMeanScoreHoverTimer(0);
    }
    if (!flag) {
      setMeanScoreHoverTimer(
        window.setTimeout(() => {
          setTotalMeanTooltipState(!!flag);
        }, TOTAL_MEAN_TOOLTIP_TIMEOUT)
      );
    } else setTotalMeanTooltipState(!!flag);
  };

  const removeSurveyDeployment = (
    surveyDeploymentDc: SurveyDeploymentDistrictComparison
  ) => {
    dispatch(
      setSelectedDistrictComarprisonSurvey(
        surveyDeployment.filter(
          (data: SurveyDeploymentDistrictComparison) =>
            data.surveyDeploymentId !== surveyDeploymentDc.surveyDeploymentId
        )
      )
    );
  };

  const getSchoolDetail = (i: number) => {
    const sd = surveyDeployment?.[i];
    if (sd) {
      return (
        <p className="text-center">
          <span className="text-lg mr-5 font-bold">{sd?.schoolName}</span>
          {!comparisonReportType && (
            <Icon
              icon={"cross"}
              iconSize={24}
              className="cursor-pointer"
              onClick={() => removeSurveyDeployment(sd)}
            />
          )}
          <br />
          <span className="text-sm italic">
            {intl.formatMessage(
              {
                id: "app.district-comparison-table.school-detail",
              },
              {
                deployment: sd?.deployment,
                administration: sd?.administration,
                month: sd?.month,
                year: sd?.year,
              }
            )}
          </span>
        </p>
      );
    } else {
      return (
        <Tooltip
          content={
            <div className="p-2">
              {intl.formatMessage({
                id: "app.district-comparison-table.total-mean-score.popover",
              })}
            </div>
          }
          position={Position.TOP}
          className="flex justify-center"
          popoverClassName="max-w-140"
          isOpen={totalMeanTooltipState}
        >
          <p className="text-center gap-2 text-lg font-semibold">
            {intl.formatMessage({
              id: "app.district-comparison-table.total-mean-score.title",
            })}
          </p>
        </Tooltip>
      );
    }
  };

  const handleDemographicsFilterChange = (checkedItem: SurveyDemographic) => {
    let checkedItems = filteredDemographics;
    let demographicIndex = checkedItems.indexOf(checkedItem);
    if (demographicIndex === -1) {
      checkedItems.push(checkedItem);
    } else {
      checkedItems.splice(demographicIndex, 1);
    }
    setFilteredDemographics([...checkedItems]);
  };

  const handleSchoolColSpan = (sd: SurveyDemographic[]) => {
    let count: number = 0;
    if (filteredDemographics.length > 0) {
      sd.forEach((ele: SurveyDemographic) => {
        if (filteredDemographics.indexOf(ele) === -1) {
          count++;
        }
      });
    } else count = sd.length;
    return {
      colSpan: count,
    };
  };

  const filterDataOnly = (sd: SurveyDemographic) => {
    // return filteredDemographics.indexOf(sd) === -1;
    return filteredDemographics.includes(sd);
  };

  const borderAt = useMemo(() => {
    let lastLength = 0;
    const result = surveyDepsDemographics.map((et) => {
      if (et.length) {
        return (lastLength = lastLength + et.length);
      } else {
        return (lastLength = lastLength + 1);
      }
    });
    //result.pop(); // not to show border for last block
    return result;
  }, [filteredDemographics, surveyDepsDemographics]);

  const filteredCol = useMemo(() => {
    let respondtDataArr: any[] = [];

    surveyDepsDemographics.forEach((ele) => {
      if (ele.filter((x) => !filteredDemographics.includes(x)).length > 0) {
        ele.forEach((e, i) => {
          respondtDataArr.push(false);
        });
        respondtDataArr.push(false);
      } else {
        respondtDataArr.push(true);
      }
    });

    return respondtDataArr;
  }, [filteredDemographics, surveyDepsDemographics]);

  const [meansScoreHeader, setMeansScoreHeader] = useState<DomainComparison[]>(
    []
  );
  useEffect(() => {
    let i = 1;
    let totalRes: any = {
      elementary_students: 0,
      parents: 0,
      school_staff: 0,
      students: 0,
    };
    if (location.pathname.split("/")[5] && surveyComparison) {
      surveyComparison?.forEach((dataDomain: any) => {
        if (!dataDomain?.responses) {
          dataDomain?.forEach((el: DomainComparison, idx: number) => {
            if (
              el?.respondents &&
              el?.domain?.id === parseInt(location.pathname.split("/")[5])
            ) {
              let dt = { ...el };

              if (i === surveyComparison?.length) {
                // If it's the last element in surveyComparison

                dt = {
                  ...dt,
                  respondents: {
                    ...el.respondents,
                    elementary_students: {
                      ...el.respondents.elementary_students,
                      total_respondents: totalRes.elementary_students
                        ? totalRes.elementary_students
                        : el.respondents?.elementary_students?.total_respondents
                        ? el.respondents?.elementary_students?.total_respondents
                        : 0,
                    } as any,
                    parents: {
                      ...el.respondents.parents,
                      total_respondents: totalRes.parents
                        ? totalRes.parents
                        : el.respondents?.parents?.total_respondents
                        ? el.respondents?.parents?.total_respondents
                        : 0,
                    } as any,
                    school_staff: {
                      ...el.respondents.school_staff,
                      total_respondents: totalRes.school_staff
                        ? totalRes.school_staff
                        : el.respondents?.school_staff?.total_respondents
                        ? el.respondents?.school_staff?.total_respondents
                        : 0,
                    } as any,
                    students: {
                      ...el.respondents.students,
                      total_respondents: totalRes.students
                        ? totalRes.students
                        : el.respondents?.students?.total_respondents
                        ? el.respondents?.students?.total_respondents
                        : 0,
                    } as any,
                  },
                };
              } else {
                if(comparisonReportType === "school-by-same-deployment"){
                  
                  // Accumulate the total respondents for each type
                  Object.keys(el.respondents).forEach((r: any) => {
                    if (!!((el?.respondents as any)[r] as any)?.total_respondents)
                      totalRes[r] =
                        totalRes[r] +
                        ((el?.respondents as any)[r] as any)?.total_respondents;
                  });
                }
              }

              // Set the updated data in the state
              setMeansScoreHeader(
                (state) => [...state, dt] as DomainComparison[]
              );
              i++;
            }
          });
        }
      });
    } else {
      setMeansScoreHeader(() => []);
    }
  }, [location.pathname, surveyComparison,isItemLevelComparison,comparisonReportType]);

  const domainTotalMeanScore = (e: SurveyDemographic) => {
    let totalScore: number = 0;
    surveyDeployment.map((da: SurveyDeploymentDistrictComparison) => {
      if (da?.responses) {
        _.mapKeys(da?.responses, function (value?: number, key?: string) {
          if (key === e && value) {
            totalScore = totalScore + value;
          }
        });
      }
    });
    return totalScore;
  };

  const localFlagToHideSensitiveData = getBooleanFromLocalStorage(
    LocalStorageKeys.SensitiveReportingDataHidden
  );

  const isAdmin = useSelector(selectIsAdmin);

  const isViewSelectedReportButton = useMemo(() => {
    return (
      routeParams.domainId &&
      routeParams?.tabId === UserNotesType.DC_REPORTING_DISTRICT_COMPARISON
    );
  }, [routeParams]);

  const handleNavigate = () => {
    history.push(
      generatePath(AuthRoutes.YourPlan, {
        workspace: "data",
      } as any)
    );
  };

  const MakeChunks = (data: any, chunkSizes: number[]) => {
    let groupedData = [];
    let startIndex = 0;

    for (const size of chunkSizes) {
      const endIndex = startIndex + size;
      groupedData.push(data.slice(startIndex, endIndex));
      startIndex = endIndex;
    }

    return groupedData;
  };

 const TotalMeanScore = useMemo(()=> {
    return surveyDepsDemographics
    .slice(surveyDepsDemographics.length - 1)
    .map((surveyDemographics: SurveyDemographic[], surveyDepId: number) => (
      <>
        <div hidden={!surveyDeployment.length} key={surveyDepId}>
          <div
            className={`grid grid-cols-${surveyDemographics.length} break-words comp-header`}
          >
            {surveyDemographics.length > 0 ? (
              surveyDemographics.map((d: SurveyDemographic, i: any) =>
                filterDataOnly(d) ? (
                  <div
                    key={surveyDepId + "" + i}
                    hidden={!surveyDeployment.length}
                    className={
                      surveyDemographics.length === i + 1 &&
                      surveyDepsDemographics.length > surveyDepId + 1
                        ? "text-black font-bold text-sm text-wrap pr-2"
                        : "text-black font-bold text-sm  pr-2"
                    }
                  >
                    <p className="mt-0 mb-0 pl-2 pr-2 text-center">
                      {intl.formatMessage({
                        id: `app.filters.respondent-type.distric-comparison.${d}`,
                      })}
                    
                    </p>
                  </div>
                ) : (
                  // handleSchoolColSpan(surveyDemographics).colSpan === 0 &&
                  // i === 0 && (
                  //   <th className="bg-white" rowSpan={2}>
                  //     <Callout
                  //       title={intl.formatMessage({
                  //         id: "app.tables.no-available-data",
                  //       })}
                  //       intent="warning"
                  //     />
                  //   </th>
                  // )
                  
                    i === 0 && (
                      filteredCol[i] && (
                        <span
                          key={i}
                          hidden={!surveyDeployment.length}
                        ></span>)
                      )
                )
              )
            ) : (
              <th className="bg-white" >
                <Callout
                  title={intl.formatMessage({
                    id: "app.tables.no-available-data",
                  })}
                  intent="warning"
                />
              </th>
            )}
          </div>

          <div
            className={`grid grid-cols-${surveyDemographics.length} break-words`}
          >
            {surveyDemographics.length > 0 ? (
              surveyDemographics.map((d: SurveyDemographic, i: any) =>
                filterDataOnly(d) ? (
                  <div
                    key={surveyDepId + "" + i}
                    hidden={!surveyDeployment.length}
                    className={
                      surveyDemographics.length === i + 1 &&
                      surveyDepsDemographics.length > surveyDepId + 1
                        ? "text-black font-bold text-sm text-wrap pr-2"
                        : "text-black font-bold text-sm  pr-2"
                    }
                  >
                    <p className="mt-0 mb-0 pl-2 pr-2 text-center">
                    
                    <div className="font-normal">

                    <Popover
                          // popoverClassName={"w-64"}
                          interactionKind="hover"
                          disabled={!surveyDeployment?.[surveyDepId]}
                          content={
                            <div className="p-2">
                              {isItemLevelComparison
                                ? intl.formatMessage({
                                    id:
                                      "app.district-comparison-table.total-respondent-domain.popover-containt",
                                  })
                                : intl.formatMessage({
                                    id:
                                      "app.district-comparison-table.total-respondent-all-domain.popover-containt",
                                  })}
                            </div>
                          }
                        >
                          
                          <p className={"flex justify-center text-xl"}>
                            {isItemLevelComparison ? (
                              <p>
                                {
                                  meansScoreHeader[meansScoreHeader.length-1]?.respondents?.[d]
                                    ?.total_respondents 
                                }
                              </p>
                            ) : (
                              <>
                                {" "}
                                {isAdmin ? (
                                  <p className="mt-0 mb-0 pl-2 pr-2 text-center">
                                    
                                    {
                                   domainTotalMeanScore(d)}
                                  </p>
                                ) : (
                                  <p>
                                     
                                    { domainTotalMeanScore(d)}
                                  </p>
                                )}
                              </>
                            )}
                          </p>
                        </Popover>
                        </div>
                    </p>
                  </div>
                ) : (
                  // handleSchoolColSpan(surveyDemographics).colSpan === 0 &&
                  // i === 0 && (
                  //   <th className="bg-white" rowSpan={2}>
                  //     <Callout
                  //       title={intl.formatMessage({
                  //         id: "app.tables.no-available-data",
                  //       })}
                  //       intent="warning"
                  //     />
                  //   </th>
                  // )
                  
                    i === 0 && (
                      filteredCol[i] && (
                        <span
                          key={i}
                          hidden={!surveyDeployment.length}
                        ></span>)
                      )
                )
              )
            ) : (
              <th className="bg-white" >
                <Callout
                  title={intl.formatMessage({
                    id: "app.tables.no-available-data",
                  })}
                  intent="warning"
                />
              </th>
            )}
          </div>

          {isItemLevelComparison ? (
            itemComparisons.map((item: any) => {
              const itemComparisonchunks = MakeChunks(
                item.questionResponses.slice(
                  -surveyDepsDemographics.slice(
                    surveyDepsDemographics.length - 1
                  )[0].length
                ),
                [surveyDepsDemographics.slice(
                  surveyDepsDemographics.length - 1
                )[0].length]
              ).map((item) =>
                item.map((itemResponses: any, index: number) => itemResponses)
              );

              return (
                <div
                  hidden={!surveyDeployment.length}
                  key={surveyDepId}
                  className={`slic-grid grid grid-cols-${itemComparisonchunks[surveyDepId].length} break-words`}
                >
                  {itemComparisonchunks[surveyDepId].map(
                    (questionResponse: any, i: any) => {
                      return questionResponse &&
                        filterDataOnly(questionResponse.demographic) ? (
                        <span key={i} hidden={!surveyDeployment.length}>
                          <ComparisonDistributionItem
                            notEnoughData={questionResponse?.not_enough_data}
                            domainTitle={item?.domainResponse?.common_text}
                            responsesCount={questionResponse?.count}
                            meanScore={questionResponse?.mean_score}
                            itemColor={"#20285A"}
                          />
                        </span>
                      ) : (
                        filteredCol[i] && (
                          <span
                            key={i}
                            hidden={!surveyDeployment.length}
                          ></span>
                        )
                      );
                    }
                  )}
                </div>
              );
            })
          ) : (
            <div className="domain-wrapper">
              { domainSchoolsDomainComparisons.map((item: any) => {
                      const domainComparisonchunks = MakeChunks(
                        item.domainComparisons.slice(
                          -surveyDepsDemographics.slice(
                            surveyDepsDemographics.length - 1
                          )[0].length
                        ),
                        [surveyDepsDemographics.slice(
                          surveyDepsDemographics.length - 1
                        )[0].length]
                      ).map((item) =>
                        item.map(
                          (domainResponses: any, index: number) =>
                            domainResponses
                        )
                      );

                      return (
                        <>
                          <div
                            hidden={!surveyDeployment.length}
                            key={surveyDepId}
                            className={`slic-grid grid grid-cols-${domainComparisonchunks[surveyDepId].length} break-words`}
                          >
                            {domainComparisonchunks[surveyDepId].map(
                              (d: any, i: any) => {
                                return filterDataOnly(d.demographic) ? (
                                  <span
                                    key={i}
                                    hidden={!surveyDeployment.length}
                                  >
                                    
                                    <ComparisonDistributionItem
                                      notEnoughData={d?.not_enough_data}
                                      domainTitle={item?.domain?.name}
                                      meanScore={d?.mean_score}
                                      responsesCount={d?.total_respondents}
                                      color={
                                        item.domain?.color_hex
                                          ? item.domain?.color_hex
                                          : COMPARISON_COLORS[
                                              (item.domain?.id ?? 0) %
                                                COMPARISON_COLORS.length
                                            ]
                                      }
                                    />
                                  </span>
                                ) : (
                                  filteredCol[i] && (
                                    <div
                                      key={i}
                                      hidden={!surveyDeployment.length}
                                    ></div>
                                  )
                                );
                              }
                            )}
                          </div>
                        </>
                      );
                    })}
            </div>
          )}
        </div>
      </>
    ))
  
  },[filteredDemographics.length,isItemLevelComparison,comparisonReportType]);

  return !loading ? (
    surveyComparison.length > 0 ? (
      <>
        {isViewSelectedReportButton ? (
          ""
        ) : (
          <Icon
            onClick={() => handleNavigate()}
            icon="arrow-left"
            iconSize={30}
            className="ml-5 cursor-pointer"
          />
        )}
        {comparisonReportType ? isItemLevelComparison ? "" : (
          <>
            <span className="text-2xl font-bold  mb-3 ml-5 mr-2">
              {intl.formatMessage({
                id: "app.district-comparison.comparison-report.label",
              })}
            </span>

            <span className="text-xl">
              (
              {intl.formatMessage({
                id: "app.survey-deployment." + comparisonReportType,
              })}
              )
            </span>
          </>
        ) : (
          <span className="text-2xl font-bold  mb-3 ml-5 mr-2">
            {isViewSelectedReportButton
              ? ""
              : intl.formatMessage({
                  id: "app.survey-deployment.view-selected-Reports",
                })}
          </span>
        )}
        <div className="comparision-report h-full pb-5" style={{ display: "" }}>
          <div className="grid grid-cols-1 pt-3 pb-3">
            <div className="filter-wrapper align-middle pl-3">
              <span className="title mr-3">
                <strong>Filter By Respondent Group :</strong>
              </span>

              <DistrictComaprisonHeaderFilter
                handleDemographicsFilterChange={handleDemographicsFilterChange}
                surveyDepsDemographics={surveyDepsDemographics}
                filteredDemographics={filteredDemographics}
              />
            </div>
          </div>

          <div className="flex justify-center mb-3">
            <h3 className="font-bold">
              {" "}
              {intl.formatMessage(
                {
                  id: "app.district-comparison-table.title",
                },
                {
                  page:
                    !isItemLevelComparison || !selectedDomain
                      ? intl.formatMessage({
                          id: "app.district-comparison-table.domain",
                        })
                      : intl.formatMessage({
                          id: "app.district-comparison-table.title.item-level",
                        }),
                }
              )}
            </h3>
          </div>

          <div className="grid grid-cols-12">
            <div className="col-span-2 md:col-span-1 lg:col-span-2 xl:col-span-2">
              <div className="domain-wrapper">
                <h4 className="domain-wrapper__title"></h4>
                <span className={`font-bold ml-5 mt-0 ${!isItemLevelComparison || !selectedDomain? "mb-0":"mb-8"} flex justify-center comp-header border-white bg-white borderL`}>
                  {!isItemLevelComparison || !selectedDomain
                    ? intl.formatMessage({
                        id: "app.district-comparison-table.domain",
                      })
                    : intl.formatMessage({
                        id: "app.district-comparison-table.item-text",
                      })}
                </span>
                {isItemLevelComparison
                  ? itemComparisons.map((item: any) => {
                      return (
                        <tr key={item.domainResponse.common_text_id}>
                          <td className="domain-item-td">
                            <div className="flex justify-center ml-2" style={{
                                  marginBottom: "16px" 
                                }}>
                              <span
                                className="text-sm text-left flex-grow font-normal cursor-default item-desc"
                                style={{
                                  maxWidth: "20rem",                                 
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleCommonTextClick(
                                    item.domainResponse.common_text,
                                    item.questionResponses
                                  )
                                }
                              >
                                {item.domainResponse.common_text?.replaceAll(
                                  /\s/g,
                                  " "
                                )}
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : domainSchoolsDomainComparisons.map((item: any) => {
                      return (
                        <span key={item.domain.id}>
                          <ReportTableDomainItem
                            domain={item.domain}
                            searchParam={
                              comparisonReportType
                                ? REPORT_TYPE_PARAM + "=" + comparisonReportType
                                : ""
                            }
                          />
                        </span>
                      );
                    })}
              </div>
            </div>
            <div className={`col-span-${comparisonReportType ? 7 :10} md:col-span-1 lg:col-span-${comparisonReportType ? 7 :10} xl:col-span-${comparisonReportType ? 7 :10}`}>
              <ComparisionReportSlider
                surveyDepsDemographics={surveyDepsDemographics}
                comparisonReportType={comparisonReportType}
                removeSurveyDeployment={removeSurveyDeployment}
                totalMeanTooltipState={totalMeanTooltipState}
                handleSchoolColSpan={handleSchoolColSpan}
                handleHoverTotalMean={handleHoverTotalMean}
                filterDataOnly={filterDataOnly}
                domainSchoolsDomainComparisons={domainSchoolsDomainComparisons}
                filteredCol={filteredCol}
                isItemLevelComparison={isItemLevelComparison}
                itemComparisons={itemComparisons}
                filteredDemographics={filteredDemographics}
                meansScoreHeader={meansScoreHeader}
              />
            </div>
            <div className="text-right  align-center col-span-3 md:col-span-1 lg:col-span-3 xl:col-span-3">
              {comparisonReportType && (
                <div className="total-score">
                  <h4>
                    <span style={{display:"flex"}}>

                    {surveyDepsDemographics.map(
                      (
                        surveyDemographics: SurveyDemographic[],
                        surveyDepId: number
                      ) =>
                        surveyDepsDemographics.length - 1 === surveyDepId &&
                        getSchoolDetail(surveyDepId)
                      )}
                    
                    <span 
                    className="ml-2 cursor-pointer" 
                    onMouseEnter={() => handleHoverTotalMean(true)}
                    onMouseLeave={() => handleHoverTotalMean(false)}>
                      
                    <InfoOutlinedIcon />
                    </span>
                       </span>
                    
                    <span className="school-title">
                      {surveyDepsDemographics &&
                        surveyDeployment[0].deployment +
                          " Admin#" +
                          surveyDeployment[0].administration}
                    </span>
                  </h4>
                  {TotalMeanScore}
                </div>
              )}
            </div>
          </div>
        </div>

        <BriefDescriptionDialog
          isOpen={isBriefDescriptionDialogOpen}
          setOpen={setBriefDescriptionDialogOpen}
          common_text={dialogProps?.common_text}
          survyObj={dialogProps?.survyObj}
        />
      </>
    ) : (
      <p className="no-data">No Data</p>
    )
  ) : (
    <Spinner intent="primary" />
  );
};
export default DistrictComparisonTable;

// <table
//         cellPadding={0}
//         cellSpacing={0}
//         className="district-comparison-report-table"
//       >
//         <thead>
//           <tr>
//             <th colSpan={tableHeadingCellsCount}>
//               <DistrictComaprisonHeaderFilter
//                 handleDemographicsFilterChange={
//                   handleDemographicsFilterChange
//                 }
//                 surveyDepsDemographics={surveyDepsDemographics}
//                 filteredDemographics={filteredDemographics}
//               />
//               <p className="text-xl font-semibold inline-block ml-12 pl-12">
//                 {intl.formatMessage(
//                   {
//                     id: "app.district-comparison-table.title",
//                   },
//                   {
//                     page:
//                       !isItemLevelComparison || !selectedDomain
//                         ? intl.formatMessage({
//                             id: "app.district-comparison-table.domain",
//                           })
//                         : intl.formatMessage({
//                             id:
//                               "app.district-comparison-table.title.item-level",
//                           }),
//                   }
//                 )}
//               </p>
//               {!comparisonReportType && (
//                 <div className="float-right inline-block">
//                   <Popover
//                     popoverClassName={"w-56"}
//                     interactionKind="hover"
//                     content={
//                       <div className="p-2">
//                         {intl.formatMessage({
//                           id:
//                             "app.district-comparison.plus-icon.popover-content",
//                         })}
//                       </div>
//                     }
//                   >
//                     <Icon
//                       icon={"plus"}
//                       iconSize={50}
//                       color={"#02bf03"}
//                       className="cursor-pointer"
//                       onClick={() => showSurveyDeploymentDialog(true)}
//                     />
//                   </Popover>
//                 </div>
//               )}
//             </th>
//           </tr>
//           <tr>
//             <th />
//             {surveyDepsDemographics.map(
//               (
//                 surveyDemographics: SurveyDemographic[],
//                 surveyDepId: number
//               ) => (
//                 <th
//                   hidden={!surveyDeployment.length}
//                   key={surveyDepId}
//                   className="thick-right-border"
//                   {...handleSchoolColSpan(surveyDemographics)}
//                   {...(!surveyDeployment?.[surveyDepId] && {
//                     onMouseOver: () => handleHoverTotalMean(true),
//                     onMouseOut: () => handleHoverTotalMean(false),
//                     className: "cSliver-dark",
//                   })}
//                 >
//                   {getSchoolDetail(surveyDepId)}
//                 </th>
//               )
//             )}
//           </tr>
//           <tr>
//             <th className="thin-right-border" rowSpan={2}>
//               {!isItemLevelComparison || !selectedDomain
//                 ? intl.formatMessage({
//                     id: "app.district-comparison-table.domain",
//                   })
//                 : intl.formatMessage({
//                     id: "app.district-comparison-table.item-text",
//                   })}
//             </th>
//             {surveyDepsDemographics.map(
//               (item: SurveyDemographic[], index: any) =>
//                 item.length > 0 ? (
//                   item.map((d: any, i: any) =>
//                     filterDataOnly(d) ? (
//                       <th
//                         key={index + "" + i}
//                         hidden={!surveyDeployment.length}
//                         className={
//                           item.length === i + 1 &&
//                           surveyDepsDemographics.length > index + 1
//                             ? "text-white font-light text-lg thick-right-border whitespace-no-wrap pr-2"
//                             : "text-white font-light text-lg whitespace-no-wrap pr-2"
//                         }
//                         {...(!surveyDeployment?.[index] && {
//                           onMouseOver: () => handleHoverTotalMean(true),
//                           onMouseOut: () => handleHoverTotalMean(false),
//                           className:
//                             "cSliver-light text-white font-light text-lg whitespace-no-wrap pr-2",
//                         })}
//                       >
//                         {intl.formatMessage({
//                           id: `app.filters.respondent-type.distric-comparison.${d}`,
//                         })}
//                       </th>
//                     ) : (
//                       handleSchoolColSpan(item).colSpan === 0 &&
//                       i === 0 && (
//                         <th
//                           className="thick-right-border bg-white"
//                           rowSpan={2}
//                         >
//                           <Callout
//                             title={intl.formatMessage({
//                               id: "app.tables.no-available-data",
//                             })}
//                             intent="warning"
//                           />
//                         </th>
//                       )
//                     )
//                   )
//                 ) : (
//                   <th className="thick-right-border bg-white" rowSpan={2}>
//                     <Callout
//                       title={intl.formatMessage({
//                         id: "app.tables.no-available-data",
//                       })}
//                       intent="warning"
//                     />
//                   </th>
//                 )
//             )}
//           </tr>
//           <tr>
//             {surveyDepsDemographics.map(
//               (item: SurveyDemographic[], di: number) =>
//                 item.map(
//                   (d: SurveyDemographic, i: number) =>
//                     filterDataOnly(d) && (
//                       <th
//                         hidden={!surveyDeployment.length}
//                         key={di + "" + i}
//                         className={
//                           item.length === i + 1 &&
//                           surveyDepsDemographics.length > di + 1
//                             ? "thick-right-border text-lg"
//                             : ""
//                         }
//                         {...(!surveyDeployment?.[di] && {
//                           onMouseOver: () => handleHoverTotalMean(true),
//                           onMouseOut: () => handleHoverTotalMean(false),
//                           className: "cSliver-light text-lg",
//                         })}
//                       >
//                         <Popover
//                           popoverClassName={"w-64"}
//                           interactionKind="hover"
//                           disabled={!surveyDeployment?.[di]}
//                           content={
//                             <div className="p-2">
//                               {isItemLevelComparison
//                                 ? intl.formatMessage({
//                                     id:
//                                       "app.district-comparison-table.total-respondent-domain.popover-containt",
//                                   })
//                                 : intl.formatMessage({
//                                     id:
//                                       "app.district-comparison-table.total-respondent-all-domain.popover-containt",
//                                   })}
//                             </div>
//                           }
//                         >
//                           <p className={"flex justify-center text-lg"}>
//                             {isItemLevelComparison ? (
//                               <p>
//                                 {
//                                   meansScoreHeader[di]?.respondents?.[d]
//                                     ?.total_respondents
//                                 }
//                               </p>
//                             ) : (
//                               <>
//                                 {" "}
//                                 {isAdmin ? (
//                                   <p>
//                                     {surveyDeployment[di]
//                                       ? (surveyDeployment[di]?.responses?.[
//                                           d
//                                         ] as number) > 0 &&
//                                         (surveyDeployment[di]?.responses?.[
//                                           d
//                                         ] as number) < 10 &&
//                                         localFlagToHideSensitiveData
//                                         ? ""
//                                         : surveyDeployment[di]?.responses?.[d]
//                                       : domainTotalMeanScore(d)}
//                                   </p>
//                                 ) : (
//                                   <p>
//                                     {surveyDeployment[di]
//                                       ? (surveyDeployment[di]?.responses?.[
//                                           d
//                                         ] as number) > 0 &&
//                                         (surveyDeployment[di]?.responses?.[
//                                           d
//                                         ] as number) < 10
//                                         ? ""
//                                         : surveyDeployment[di]?.responses?.[d]
//                                       : domainTotalMeanScore(d)}
//                                   </p>
//                                 )}
//                               </>
//                             )}
//                           </p>
//                         </Popover>
//                       </th>
//                     )
//                 )
//             )}
//           </tr>
//         </thead>
//         <tbody>
//           {isItemLevelComparison
//             ? itemComparisons.map((item: any) => {
//                 return (
//                   <tr key={item.domainResponse.common_text_id}>
//                     <td className={"bg-gray-200 p-2"}>
//                       <div className="flex justify-center">
//                         <p
//                           className="text-xl text-center flex-grow font-bold cursor-default"
//                           style={{
//                             maxWidth: "20rem",
//                             margin: "2%",
//                             cursor: "pointer",
//                           }}
//                           onClick={() =>
//                             handleCommonTextClick(
//                               item.domainResponse.common_text,
//                               item.questionResponses
//                             )
//                           }
//                         >
//                           {item.domainResponse.common_text?.replaceAll(
//                             /\s/g,
//                             " "
//                           )}
//                         </p>
//                       </div>
//                     </td>

//                     {item.questionResponses.map(
//                       (questionResponse: any, index: number) =>
//                         questionResponse &&
//                         filterDataOnly(questionResponse.demographic) ? (
//                           <td
//                             className={
//                               borderAt.includes(index + 1)
//                                 ? "bg-gray-200 thick-right-border"
//                                 : "bg-gray-200 border-r-2"
//                             }
//                             key={index}
//                             hidden={!surveyDeployment.length}
//                           >
//                             <div className="flex justify-center px-2">
//                               <ComparisonDistributionItem
//                                 notEnoughData={
//                                   questionResponse?.not_enough_data
//                                 }
//                                 domainTitle={
//                                   item?.domainResponse?.common_text
//                                 }
//                                 responsesCount={questionResponse?.count}
//                                 meanScore={questionResponse?.mean_score}
//                               />
//                             </div>
//                           </td>
//                         ) : (
//                           filteredCol[index] && (
//                             <td
//                               className={
//                                 borderAt.includes(index + 1)
//                                   ? "bg-gray-200 border-r-2"
//                                   : "bg-gray-200 border-r-2"
//                               }
//                               key={index}
//                               hidden={!surveyDeployment.length}
//                             ></td>
//                           )
//                         )
//                     )}
//                   </tr>
//                 );
//               })
//             : domainSchoolsDomainComparisons.map((item: any) => (
//                 <tr key={item.domain.id}>
//                   <td className={"bg-gray-200"}>
//                     <div className="flex justify-center px-2">
//                       <ReportTableDomainItem
//                         domain={item.domain}
//                         searchParam={
//                           comparisonReportType
//                             ? REPORT_TYPE_PARAM + "=" + comparisonReportType
//                             : ""
//                         }
//                       />
//                     </div>
//                   </td>
//                   {item.domainComparisons.flatMap(
//                     (domainResponses: any, index: number) => {
//                       return filterDataOnly(domainResponses.demographic) ? (
//                         <td
//                           key={index}
//                           className={
//                             borderAt.includes(index + 1)
//                               ? "bg-gray-200 thick-right-border"
//                               : "bg-gray-200"
//                           }
//                           hidden={!surveyDeployment.length}
//                         >
//                           <div className="flex justify-center px-2">
//                             <ComparisonDistributionItem
//                               notEnoughData={domainResponses?.not_enough_data}
//                               domainTitle={item?.domain?.name}
//                               meanScore={domainResponses?.mean_score}
//                               responsesCount={
//                                 domainResponses?.total_respondents
//                               }
//                               color={
//                                 item.domain?.color_hex
//                                   ? item.domain?.color_hex
//                                   : COMPARISON_COLORS[
//                                       (item.domain?.id ?? 0) %
//                                         COMPARISON_COLORS.length
//                                     ]
//                               }
//                             />
//                           </div>
//                         </td>
//                       ) : (
//                         filteredCol[index] && (
//                           <td
//                             className={
//                               borderAt.includes(index + 1)
//                                 ? "bg-gray-200 border-r-2"
//                                 : "bg-gray-200 border-r-2"
//                             }
//                             key={index}
//                             hidden={!surveyDeployment.length}
//                           ></td>
//                         )
//                       );
//                     }
//                   )}
//                 </tr>
//               ))}
//         </tbody>
//       </table>
